.container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  width: 100%;
  max-width: 944px;
}

.back-btn {
  max-width: fit-content;
  margin-top: 24px;
}
