.poll-answer-placeholder {
  display: flex;
  justify-content: flex-start;
  box-shadow: 0 0 0 1px var(--grey-5);
  color: var(--grey-3);
  border-radius: 4px;
  padding: 1px 16px 0;

  width: 100%;
  height: 48px;

  /* Set the letter spacing to match the Poll answer input. */
  p {
    letter-spacing: 0.00938em;
  }
}
