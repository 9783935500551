.bookmark-icon {
  &--active {
    & & path,
    & path {
      stroke: var(--positive-2);
      fill: var(--positive-2);
    }
  }

  &--outline {
    fill: none;
  }
}

.bookmark {
  color: var(--black);

  &--with-tooltip:hover {
    background-color: var(--grey-4);
  }
}
