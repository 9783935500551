@use "/scss/mixins";

.top-matches {
  position: absolute;
  top: 52px;
  left: 49%;
  transform: translateX(-50%);
  max-height: 362px;
  max-width: 291px;
}

.interview {
  position: absolute;
  top: 60px;
  left: 49%;
  transform: translateX(-50%);
  max-height: 247px;

  @include mixins.breakpoint(360px) {
    max-height: 282px;
  }
}

.job {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.job-icon {
  width: 73px;
  height: 73px;
  color: var(--yellow);
  z-index: 1;
}

.hand-icon {
  position: absolute;
  top: 14.5px;
  left: 16.5px;
}
