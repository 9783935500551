@use "/scss/mixins";

.content-wrapper {
  width: min(815px, 100vw - 2rem);
  padding: 16px;
  display: flex;
  flex-direction: column;

  @include mixins.breakpoint(md) {
    padding: 32px;
  }
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: wrap;

  &__error-message {
    margin-left: auto;
    order: 1;

    @include mixins.breakpoint(649px) {
      order: 0;
    }

    &--step-two {
      @include mixins.breakpoint(570px) {
        order: 0;
      }
    }
  }

  &__loader {
    margin-left: 8px;
  }
}

.close-button {
  width: 32px;
  height: 32px;
  background: var(--grey-5);

  @include mixins.breakpoint(md) {
    width: 42px;
    height: 42px;
  }
}

.create-or-edit-post-content-wrapper {
  display: flex;

  // to keep close button visible on small devices
  @media (max-width: 350px) {
    min-height: 585px;
  }
}
