@use "/scss/mixins";

.prompt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  position: absolute;

  width: 100%;
  text-align: center;
  bottom: 0;

  background: linear-gradient(
    360deg,
    #ffffff 77.03%,
    rgba(255, 255, 255, 0) 117.89%
  );

  z-index: 1;

  @include mixins.breakpoint(sm) {
    flex-direction: row;
    gap: 2rem;
    text-align: left;

    padding-bottom: 60px;
  }
}
