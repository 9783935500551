@use "/scss/mixins";

.wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  bottom: 100px;
  width: 100%;
  z-index: 100;

  @include mixins.breakpoint(md) {
    margin-left: 240px;
    width: calc(100% - 240px);
  }
}

.container {
  position: relative;
  max-width: 944px;
  width: 100%;
  margin: 0 30px;
}

.button {
  position: absolute;
  right: -24px;
  z-index: 10;
  padding: 12px;
}

.button--mobile {
  right: -16px;
  width: 48px;
}
