@use "/scss/mixins";

.modal {
  width: min(100vw - 2rem, 50rem);
}

.close-button {
  svg {
    padding: 0;
  }
}

.hand-emoji {
  --size: 2rem;
  display: grid;
  place-items: center;

  width: var(--size);
  height: var(--size);

  background-color: var(--medium-green);

  border-radius: 50%;
}

.top-content-wrapper {
  background-color: var(--soft-green);

  padding-top: 1.5rem;
}

.top-content {
  position: relative;

  isolation: isolate;
}

.video-container {
  width: 100%;
  aspect-ratio: 452 / 255;
  overflow: hidden;

  margin-inline: auto;

  @include mixins.breakpoint(sm) {
    width: min(452px, 80%);
  }
}

.bg-icon {
  --size: max(40vw, 10.5rem);
  position: absolute;
  display: grid;

  place-items: center;

  top: 4rem;
  left: 50%;
  width: var(--size);
  height: var(--size);

  z-index: -1;

  transform: translateX(-50%);

  & > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  @include mixins.breakpoint(xs) {
    --size: 40vw;
    top: 2rem;
  }

  @include mixins.breakpoint(sm) {
    top: 0;
    left: 0;
    transform: translateX(-11%);
    --size: 16.125rem;
  }
}

.flower-icon {
  width: 100%;
  height: 100%;

  color: var(--medium-green);
}

.bulb-icon {
  font-size: 3.375rem;

  transform: rotate(-10deg) translate(-2%, -25%);
  filter: drop-shadow(2px 6px 25px rgb(20 20 20 / 0.3));

  @include mixins.breakpoint(xs) {
    font-size: 3.75rem;
  }

  @include mixins.breakpoint(sm) {
    font-size: 4rem;
    transform: rotate(-10deg) translateX(-2%);
  }
}

.bottom-text {
  margin-inline: auto;
  text-align: center;
}
