@use "/scss/mixins";

div.modal {
  width: min(100% - 2rem, 33rem);
  border-radius: 16px;

  @include mixins.breakpoint(sm) {
    border-radius: 24px;
  }
}

button.close-button {
  top: 16px;
  right: 16px;

  @include mixins.breakpoint(sm) {
    top: 20px;
    right: 20px;
  }
}
