.checkbox {
  &:global(.MuiFormControl-root) {
    width: 100%;
    max-width: 484px;
    padding: 0;

    & :global(.MuiFormControlLabel-label) {
      width: 100%;
      max-width: 484px;
      margin-left: 8px;
    }

    & :global(.MuiFormControlLabel-root) {
      padding: 16px;
      margin: 0;
      max-width: 100%;
      border-radius: 6px;
      box-shadow:
        0 2px 4px rgba(20, 20, 20, 0.04),
        0 4px 16px rgba(20, 20, 20, 0.04);

      & :global(.MuiOutlinedInput-root) {
        max-width: 400px;
      }
    }

    &:nth-of-type(2) {
      margin-top: 16px;
    }
  }
}
