.boost-button-job {
  color: #4e86cf !important;
  border: 10px #4e86cf !important;
  box-shadow: inset 0 0 0 1.5px #4e86cf !important;
  background-color: #f5f8ff !important;
  &:hover {
    background-color: #4e86cf !important; /* Change to the desired hover background color */
    color: #f5f8ff !important; /* Change to the desired hover text color */
  }

  &.MuiButton-disabled {
    opacity: 0.5 !important;
  }
}
