.header--mobile {
  max-height: 100vh;

  display: flex;
  flex-direction: column;
}

.header--short {
  box-shadow: var(--elevation-3);
}

.page-offset {
  height: 67px;
}

.nav-button-container {
  align-items: start;
  justify-content: space-between;

  width: 100%;
  height: 38px;

  padding-inline: 15px;
}

button.menu-button {
  margin-right: -5px;
  padding: 0 4px 4px 0;

  svg {
    height: 2.1rem;
    width: 2.1rem;
    max-width: revert;
    max-height: revert;
  }
}

.nav-container {
  margin: 0;

  height: 100%;

  margin-top: 14px;
  padding-inline: 15px;

  list-style: none;
  overflow: auto;
}

.nav-links {
  padding: 0;
  margin: 0;

  list-style: none;

  width: 218px;

  padding: 0 0 10px 6px;
}

.nav-container--medium {
  .nav-links {
    width: 287px;
  }
}

.nav-link-container {
  position: relative;
  cursor: pointer;
}

.nav-link.nav-link {
  position: relative;
  text-decoration: none !important;

  font-size: 20px;
  font-weight: 600;
  padding-block: 11px;
  line-height: 1;

  flex-grow: 1;

  &::after {
    content: "";
    position: absolute;

    inset: auto 0 -2px 0;

    height: 2px;

    background-color: var(--black);
    opacity: 0;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    &::after {
      opacity: 1;
    }
  }
}

.nav-sub-links {
  padding-left: 10px;
  list-style: none;

  max-width: 250px;
}

.nav-sub-link.nav-sub-link {
  white-space: nowrap;
  height: 100%;

  display: flex;
  align-items: center;

  font-size: 18px;
  padding: 10px 0 10px 30px;

  &::after {
    inset: auto 0 0 0;
  }

  &::before {
    content: "";
    position: absolute;
    background: var(--black);
    inset: 18px auto auto 0;

    width: 20px;
    height: 2px;
  }
}

.arrow-icon {
  color: var(--black);
  font-size: 1.875rem;

  margin-bottom: -5px;

  transition: transform 350ms ease;
}

.arrow-icon--up {
  transform: rotate(180deg);
}

.cta-link {
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  display: flex;
  align-items: center;

  gap: 18px;
  padding-block: 20px;
  text-decoration: none !important;
}

.sign-up-wrapper {
  position: relative;
  width: 95%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;

    background-color: var(--black);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.sign-up-wrapper--medium {
  .sign-up {
    padding-block: 15px;
  }
}

.sub-link--small {
  font-size: 18px !important;
  font-weight: 400 !important;
}
