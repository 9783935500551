.add-link-button {
  flex-shrink: 0;
}

.close-button {
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;
  border: 2px solid var(--white);
  background-color: var(--white);
  border-radius: 16px;
}
