@use "/scss/mixins";

.hashtags-carousel {
  overflow: hidden;

  background-color: var(--medium-green);
  border-radius: 16px;
  padding: 16px;
  margin-inline: 16px;

  @include mixins.breakpoint(sm) {
    margin-inline: 0;
    padding: 24px;
  }

  &__swiper {
    position: relative;
    max-width: 100%;
    margin-top: 16px;

    &--with-previous-fades {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -3px;
        width: 109px;
        height: 100%;
        background: linear-gradient(
          90deg,
          #{var(--medium-green)} 0%,
          transparent 100%
        );
        z-index: 20;

        @include mixins.breakpoint(md) {
          width: 54px;
          left: 0px;
        }
      }
    }

    &--with-next-fades {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -3px;
        width: 109px;
        height: 100%;
        background: linear-gradient(
          270deg,
          #{var(--medium-green)} 0%,
          transparent 100%
        );
        z-index: 20;

        @include mixins.breakpoint(md) {
          right: 0px;
        }
      }
    }

    & :global(.swiper-slide) {
      width: auto !important;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .hashtags-carousel__nav-btn--icon-button {
    padding: 8px;
  }

  .hashtags-carousel__nav-btn > .disabled-button {
    background-color: transparent;
  }
}

.trending-hashtags-button {
  height: 64px;
  padding: 16px;
  display: flex;
  background-color: var(--white);
  border-radius: 16px;

  svg {
    font-size: 32px;
  }

  &__label {
    padding-left: 8px;
  }

  @include mixins.breakpoint(sm) {
    height: 80px;

    svg {
      font-size: 48px;
    }

    &__label {
      padding-left: 16px;
    }
  }
}
