.profile-completion-nav-card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 0px;
  min-width: 100%;
  padding: 16px 12px;

  background-color: var(--grey-1);

  border-radius: 8px;

  z-index: 10;
}
