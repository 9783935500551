.icon {
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
}

.avatar {
  height: 22px;
  width: 22px;
  background-color: var(--grey-5);
  color: var(--dark-violet) !important;
  font-weight: 500 !important;

  :global(.MuiAvatar-img) {
    z-index: initial;
  }
}
