@use "/scss/mixins";

.offer-status-wrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  min-width: 0;

  @include mixins.breakpoint(800px) {
    justify-content: center;
  }

  &__job-title {
    display: flex;
    align-items: center;
    gap: 14px;
    min-width: 0;
  }
}
