.avatar {
  background-color: var(--off-white);
  border: solid 1px var(--medium-beige);
  overflow: hidden;
  flex-shrink: 0;

  &.x-small {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  &.small {
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }

  &.medium {
    width: 72px;
    height: 72px;
    border-radius: 12px;
  }

  &.large {
    width: 96px;
    height: 96px;
    border-radius: 16px;
  }
}

.img {
  object-fit: cover;
}
