@use "/scss/mixins";

.offers-layout {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    position: sticky;
    top: 0;
    z-index: 1001;
  }

  &__top-stripe-socket {
    display: flex;
    justify-content: center;
    background-color: black;
    color: white;
  }

  &__top-stripe {
    max-width: 1440px;
  }

  &__navigation-socket {
    display: flex;
    justify-content: center;
    background-color: white;
    min-height: 89px;
  }

  &__navigation {
    max-width: 1332px;
    padding: 20px 16px;
    width: 100%;

    @include mixins.breakpoint(lg) {
      padding: 20px 32px;
    }
  }

  &__talent-info-socket {
    display: flex;
    justify-content: center;
    background-color: var(--medium-green);

    padding-inline: 32px;

    @include mixins.breakpoint(sm) {
      padding-inline: 60px;
    }
  }

  &__talent-info {
    padding: 20px 0;

    width: 100%;
  }

  &__content {
    background-color: var(--soft-green);
    flex-grow: 1;
    padding: 48px 32px;

    @include mixins.breakpoint(sm) {
      padding: 48px 60px;
    }

    & > * {
      margin: 0 auto;
    }
  }
}
