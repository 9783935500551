.avatar {
  border: 6px solid var(--white);

  img {
    border-radius: 50%;
  }

  // SHADOW
  &.no-shadow {
    box-shadow: none;
    border: none;
  }

  // without border around avatar image
  &.no-border {
    border: none;
  }

  // SIZE
  &.xs {
    border-width: 1px;
  }

  &.sm {
    border-width: 1px;
  }

  &.md {
    border-width: 3px;
  }

  &.lg {
    border-width: 4px;
  }
}

.badge {
  z-index: 6;

  &__root {
    width: 100%;
    height: 100%;
  }

  &__icon {
    height: 25px;
    border-radius: 25px;
    box-shadow: 0 0 5px #{rgba(var(--black-rgb), 0.09)};

    &.md {
      height: 20px;
    }

    &.vxl {
      height: 43px;
      width: 43px;
    }
  }
}
