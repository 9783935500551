.bookmark-icon {
  color: var(--black);

  &--highlight {
    color: var(--positive-2);

    & > path {
      fill: var(--positive-2);
    }
  }

  &--outline {
    fill: none;
  }
}

.bookmark-nav-link {
  &--highlight {
    z-index: 1800 !important;
  }
}
