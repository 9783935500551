@use "/scss/mixins";

.modal {
  max-width: 495px;
  width: 100%;

  &__header {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    margin: 0;
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
  }
  &__wrapper {
    background-color: var(--soft-blue);
    border-radius: 16px;
    text-align: center;
    padding: 24px;
    margin-bottom: 24px;
  }
  &__list {
    columns: 2;
    padding-left: 0;
    margin: 0 0 16px;
    list-style: none;
    text-align: center;
    font-weight: 500;

    @include mixins.breakpoint(sm) {
      columns: 4;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-top: 24px;
  }
}
