@use "/scss/mixins";

.invoice-head {
  background-color: var(--grey-5);
  padding: 25px 6% 40px;

  @include mixins.breakpoint(md) {
    padding: 50px 6% 80px;
  }

  &__employers-address {
    white-space: pre-wrap;
  }
}
