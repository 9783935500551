.title-container {
  &:hover {
    .icon {
      display: block;
    }
  }

  .icon {
    display: none;
    margin: 8px 4px;
  }
}
