@use "/scss/mixins";

.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @include mixins.breakpoint(1200px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 32px;
  }
}
