@use "/scss/mixins";

.alert {
  margin-top: 37px;
  border-radius: 4px;
}

.jobs-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 32px;
}

.top-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 65px 40px 42px 40px;

  text-align: center;

  @include mixins.breakpoint(sm) {
    padding: 65px 87px 42px 87px;
  }
}

.cta-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.bottom-section {
  background-color: var(--off-white);
  padding: 36px 48px;
  position: relative;

  text-align: center;
}
