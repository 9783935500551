.wrapper {
  display: inline-block;
  margin-bottom: 16px;
}

.button {
  display: flex;
  align-items: center;
  gap: 12px;

  line-height: 1;
}
