.review-report-badge {
  padding: 16px;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--grey-5);

  &__icon-wrapper {
    background-color: var(--soft-red);
    padding: 4px;
    align-self: flex-start;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }

  &__flag-icon {
    width: 16px;
    height: 16px;
    color: var(--negative-1);
  }
}
