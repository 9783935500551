.work-availability {
  background-color: var(--white);
  border-radius: 8px;
  max-width: 147px;
  padding: 0px 10px;
  gap: 8px;
  box-shadow:
    0px 8px 16px rgba(20, 20, 20, 0.04),
    0px 12px 32px rgba(20, 20, 20, 0.04);
  cursor: pointer;
}
