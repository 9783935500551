@use "/scss/mixins";

.payment-method-inline {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.payment-method-inline__vendor-image {
  max-height: 18px;
  width: auto;

  @include mixins.breakpoint(sm) {
    font-size: 25px;
  }
}

.payment-method-inline__default-indicator {
  margin-left: auto;
  padding: 0;
  font-size: 13px;
  color: var(--grey-3);
  font-weight: 500;

  white-space: nowrap;

  @include mixins.breakpoint(sm) {
    padding: 4px 16px;
    background-color: var(--grey-5);
    border-radius: 100px;
  }
}
