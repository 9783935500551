@use "/scss/mixins";

.modal {
  max-width: min(755px, 100% - 2rem);
  border-radius: 8px !important;
  padding: 32px !important;
}

.close-button {
  height: 42px;
  width: 42px;
  top: 23px;
  right: 23px;

  @include mixins.breakpoint(sm) {
    top: 31px;
    right: 31px;
  }
}
