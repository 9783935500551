@use "/scss/mixins";

.post-text {
  white-space: pre-line;
  overflow-wrap: anywhere;
  font-size: 16px;
  margin-top: 4px;

  a {
    color: var(--link);
  }

  p {
    margin: 0;
  }

  @include mixins.breakpoint(sm) {
    font-size: 20px;
    line-height: 32px;
  }
}

a.post-hashtag {
  font-size: inherit;
  cursor: pointer;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
