.listing-page-content {
  position: relative;
  width: 100%;
}

.listing-page {
  width: 100%;
  position: relative;
  max-width: 1120px;
}
