.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-size: 13px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  & g {
    stroke-width: 3;
  }
}

.icon {
  font-size: 16px;
}
