.data-wrapper {
  position: fixed;
  bottom: 0;
  right: 5px;
  z-index: 999999;
  cursor: pointer;
  background: rgb(255 255 255 / 50%);
  border-top-left-radius: 10px;
  padding: 0 10px;
  color: #000;
}

.copy-button {
  box-shadow: none !important;
  padding: 0 !important;
}

.widget {
  font-size: 11px !important;
}

.icon {
  font-size: 12px !important;
}
