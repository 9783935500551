@use "/scss/mixins";

.badge-container {
  grid-area: badge;
  align-self: start;
  max-width: 100%;
  justify-self: end;

  @include mixins.breakpoint(sm) {
    max-width: 250px;
  }
}

.icon {
  width: 16px;
  height: 16px;
  &--people {
    path {
      stroke-width: 2;
    }
  }
}

.card {
  border-radius: 16px;
  border: 1px solid var(--soft-grey);
  padding: 24px;
  background-color: var(--white);
  gap: 0;
  overflow: hidden;

  display: grid;
  grid-template-areas:
    "img name badge"
    "img info info"
    "img desc desc"
    "img cta cta";
  grid-template-columns: 96px 1fr 1fr;
  grid-template-rows: repeat(auto, 4);
  row-gap: 0;
  column-gap: 24px;

  &__container {
    container: space-container / inline-size;
  }

  &--no-badge {
    grid-template-areas:
      "img name name"
      "img info info"
      "img desc desc"
      "img cta cta";
  }

  &__name {
    grid-area: name;
  }

  &__badge {
    background-color: var(--off-white);
    padding: 0 8px;
    border-radius: 16px;
  }

  &__details {
    grid-area: info;
    flex-direction: row;
    gap: 16px;
  }

  &__description {
    margin-top: 16px;
    grid-area: desc;
  }

  &__join-button {
    margin-top: 16px;
    grid-area: cta;
    justify-self: start;
  }

  &__image {
    grid-area: img;
  }

  @media screen and (max-width: 600px) {
    grid-template-areas:
      "badge badge"
      "img info"
      "name name"
      "desc desc"
      "cta cta";
    padding: 16px;
    column-gap: 16px;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(auto, 5);

    &__badge {
      justify-self: start;
      margin-bottom: 16px;
    }

    &__name {
      margin-top: 16px;
    }

    &__details {
      font-size: 13px;
      flex-direction: column;
      gap: 4px;
    }

    &__description {
      margin-top: 0;
    }

    &__join-button {
      margin-top: 16px;
      grid-area: cta;
      justify-self: stretch;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 760px) {
    grid-template-areas:
      "img badge badge"
      "img name name"
      "img info info"
      "img desc desc"
      "img cta cta";

    &__badge {
      justify-self: start;
    }
  }

  @container space-container (width <= 450px) {
    grid-template-areas:
      "badge badge"
      "img info"
      "name name"
      "desc desc"
      "cta cta";
    padding: 16px;
    column-gap: 16px;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(auto, 5);

    &__badge {
      justify-self: start;
      margin-bottom: 16px;
    }

    &__name {
      margin-top: 16px;
    }

    &__details {
      flex-direction: column;
      gap: 4px;
    }

    &__description {
      margin-top: 0;
    }

    &__join-button {
      margin-top: 16px;
      grid-area: cta;
      justify-self: stretch;
    }
  }
}
