.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__bookmarks {
    position: relative;
    left: 3px;
  }

  &__saved-jobs.active svg {
    fill: var(--black) !important;
  }
}

.menu {
  &--with-icon {
    display: flex;
    justify-content: space-between;
  }
}

.btn-guest {
  padding: 6px 20px !important;
  white-space: nowrap;
}
