.simply-user-thumbnail-avatars {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (min-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
}

.simply-user-group-and-thumbnail-wrapper {
  display: flex;
  align-items: center;

  &__user-group {
    align-items: center;
  }
}

.simply-users-thumbnails-avatars-group {
  &--verified {
    display: flex;
    align-items: center;

    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid var(--grey-4);

    @media (max-width: 600px) {
      margin-right: 12px;
      padding-right: 12px;
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    font-size: 16px;
    border: 2px solid var(--white);

    @media (max-width: 600px) {
      width: 24px;
      height: 24px;
    }
  }
}

.simply-users-count-badge {
  position: relative;
  padding: 2px 8px;
  line-height: 24px;
  background-color: transparent;
  color: var(--black);
  left: 0px;
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
