.ai-banner {
  background: linear-gradient(
    90deg,
    #d4e4f9 0.14%,
    #e5d7fb 48.5%,
    #fff5e3 76.06%,
    #fffdbd 99.97%
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 13px 20px;
  margin-bottom: 24px;
  border-radius: 8px;
}
