.list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  :global(.sortable-container) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;

  padding: 8px 32px;

  background-color: var(--white);

  border: 1px solid var(--grey-5);
  border-radius: 10px;

  &--drag {
    cursor: grab;
  }

  &--superpower {
    background-color: var(--off-white);
  }

  :global(.icon-button) {
    z-index: initial;
  }
}

:global(.sortable-element--is-dragging) .item {
  border: 1px solid var(--black);
  z-index: 1;
}
