.snackbar {
  gap: 8px;
  margin: auto;
  max-width: 208px;
  border-radius: 4px;
  padding: 4px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--white);
  background-color: var(--positive-1);

  & svg {
    color: var(--white);
  }
}
