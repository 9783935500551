.interview-header {
  padding: 0px;
}

.interview-loading {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
