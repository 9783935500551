.avatar-container {
  flex-shrink: 0;

  width: 56px;
  height: 56px;

  :global(.circular-progress-decorator) {
    width: 100%;
    height: 100%;
    padding: 5px;
  }
}
