.activity-container {
  padding: 32px;
  background-color: var(--white);
  border-radius: 16px;
  border: 1px solid var(--grey-4);
}

.activity-icon-wrapper {
  position: relative;
  display: flex;
  max-width: 48px;
}

.activity-icon-wrapper__icon {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }
}

.activity-item {
  display: flex;
  align-items: center;
  gap: 12px;
}
