@use "/scss/mixins";

.wrapper {
  grid-area: view-btn;
  align-self: start;

  display: flex;
  flex-direction: column;

  justify-content: end;
  gap: 8px;

  white-space: nowrap;

  margin-inline: 8px;

  @include mixins.job-list-breakpoint(md) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.invites-tab {
  flex-direction: column-reverse;
}
