@use "/scss/mixins";

.employer-profile {
  &__info {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "col-2"
      "col-1";

    @include mixins.breakpoint(md) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: "col-1 col-1 col-2";
    }
  }

  &__col-1 {
    grid-area: col-1;
  }

  &__col-2 {
    grid-area: col-2;
    display: grid;
    gap: 24px;
    grid-template-areas:
      "meta"
      "total-jobs"
      "hired-talent";

    @include mixins.breakpoint(sm) {
      grid-template-areas:
        "meta meta"
        "total-jobs hired-talent";
    }
  }

  &__overview {
    grid-area: overview;
    height: 100%;
    min-height: 240px;
  }
  &__meta {
    grid-area: meta;
  }
  &__total-jobs {
    grid-area: total-jobs;
  }
  &__hired-talent {
    grid-area: hired-talent;
  }
}
