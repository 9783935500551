.service-card {
  background-color: var(--white);
  display: flex;
  border-bottom: 1px solid var(--grey-4);
  padding: 8px 0 15px 0;
  justify-content: space-between;
  align-items: center;

  &__category-icon {
    position: relative;
    display: flex;
  }

  &__category-icon-emoji-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__category-icon-emoji {
    line-height: 2;
  }
}
