.editor-inside-wrapper {
  cursor: text;
  --line-height: 26px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .editor-input--post {
    padding-bottom: 24px;
  }

  &--no-attachments {
    .editor-input--post {
      padding-bottom: 56px;
    }
  }

  &--with-max-height {
    max-height: 60vh;
    overflow-y: auto;
  }
}
