@use "/scss/mixins";

.refer-modal {
  max-width: 600px;
  width: 98%;
  position: realtive;
  background-color: var(--soft-yellow) !important;

  &::after {
    content: "";
    position: absolute;
    background-image: url("/static/referral/refer-job-bubble-arc.svg");
    bottom: -3px;
    left: 0;
    height: 34px;
    width: 100%;
  }

  &__img {
    width: 100%;
    max-width: 245px;
  }

  &__img-container {
    text-align: center;
    order: -1;

    @include mixins.breakpoint(sm) {
      order: 0;
    }
  }

  &__btn {
    flex-shrink: 0;
    background-color: var(--white);
    width: auto;
    margin: 4px 0 0 4px !important;

    @include mixins.breakpoint(sm) {
      margin: 0 0 0 8px !important;
    }

    &:first-child {
      margin-left: 0 !important;
    }

    &__socials {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      @include mixins.breakpoint(sm) {
        gap: 16px 8px;
      }

      a {
        box-shadow: inset 0 0 0 1px var(--black);
      }
    }
  }

  &__fine-print a {
    font-weight: 500;
  }
}
