.modal {
  background: linear-gradient(
    119.92deg,
    #d4e4f9 1.7%,
    #e5d7fb 44.69%,
    #fff5e3 80.85%,
    #fffdbd 102.16%
  );
  max-width: 660px;
  padding: 50px;

  &__label {
    text-align: left;
    font-weight: 400;
    font-size: 26px;
  }

  @media (max-width: 660px) {
    width: 90%;

    &__label {
      font-size: 20px;
    }
  }
}
