@use "/scss/mixins";

.wrapper {
  grid-area: budget;
  display: flex;
  align-self: end;
  align-items: center;
  justify-self: start;
  word-break: break-all;

  @include mixins.job-list-breakpoint(sm) {
    margin-left: 0;
    justify-self: end;
  }
}
