.avatar {
  display: block;
  text-align: center;
  position: relative;
  max-width: 128px;

  &.avatar--has-value,
  &.avatar--loading {
    .avatar-placeholder {
      opacity: 0;
    }
  }

  &:hover {
    .avatar-placeholder {
      z-index: 1;
      background-color: rgba(var(--black-rgb), 0.8);
      color: var(--white);
      opacity: 1;
    }
  }

  &.avatar--squared {
    .avatar-placeholder,
    .avatar-image {
      border-radius: initial;
    }
  }
  &.avatar--white {
    color: var(--white);
  }
}

.file-input {
  display: none;
}

.avatar-image,
.avatar-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.avatar-image {
  z-index: 0;
}

.avatar-placeholder {
  transition: opacity 0.1s;
}

.placeholder-icon {
  font-size: 36px !important;
}

.avatar-container {
  position: relative;
  text-align: center;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: var(--white);
  border-radius: 50%;

  :global(.loader-container) {
    position: absolute;
    inset: 0;

    display: grid;
    place-items: center;
  }
}

.error {
  position: absolute;
  text-align: center !important;
  margin-top: 15px !important;
}
