.list {
  padding: 0;
  margin: 8px 0 0;

  li {
    list-style: none;
    line-height: 1;
  }

  ul {
    padding-left: 10px;
  }
}
