.container {
  display: grid;
  place-items: center;

  height: 100%;

  :global(.circular-progress-decorator) {
    width: 100%;
    height: 100%;

    overflow: hidden;
  }
}
