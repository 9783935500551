.profile-card {
  max-width: 458px;
  height: fit-content;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: var(--elevation-5);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    padding: 0 24px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;

    &-cover {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-avatar {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      border: 2px solid var(--white);
      position: relative;
      top: 50%;
    }

    &-view-btn {
      white-space: nowrap;
    }

    &-message-btn {
      white-space: nowrap;
      margin-right: 10px;
      margin-left: 10px;
    }

    &-btn-wrapper {
      display: flex;
      gap: 8px;
    }
  }

  &__content {
    padding: 60px 24px 24px;
  }

  &__name {
    display: flex;
    align-items: center;

    &-verified {
      position: relative;
      z-index: 10;
      margin-left: 8px;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 8px;
  }

  &__location {
    display: flex;
    align-items: center;
    margin-left: -5px;
  }

  &__divider {
    margin: 16px 0;
  }

  &__role {
    display: flex;
    gap: 4px;
    align-items: center;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--blue);
  }

  &__skill-chip {
    padding: 8px 16px;
  }
}

.profile-card-services-with-rating {
  display: flex;
  align-items: center;
  gap: 1;

  &:hover {
    text-decoration: none;
  }
}
