@mixin status-color($bgcolor, $dotColor) {
  background-color: $bgcolor;

  .offer-status__dot {
    background-color: $dotColor;
  }
}

.offer-status {
  padding: 11px 18px;
  color: var(--black);
  border-radius: 100px;
  white-space: nowrap;

  &__dot {
    display: inline-flex;
    padding: 6px;
    border-radius: 100%;
    margin-right: 8px;
  }

  &.green {
    @include status-color(var(--soft-green), var(--positive-2));
  }

  &.blue {
    @include status-color(var(--soft-blue), var(--info-2));
  }

  &.red {
    @include status-color(var(--soft-red), var(--negative-2));
  }

  &.grey {
    @include status-color(var(--soft-grey), var(--dark-grey));
  }
}
