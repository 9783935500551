.rich-textarea-wrapper {
  position: relative;

  textarea {
    position: absolute;
    visibility: hidden;
    z-index: -100;
    display: block !important;
  }
}

.text-length-indicator {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  color: var(--grey-2);
}
