.site-notifications {
  width: 359px;
  max-width: calc(100vw - 36px);

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    top: -10px;
    right: var(--arrowX, 10px);
    border-width: 0 21px 16px 16px;
    border-color: transparent transparent var(--white) transparent;
  }

  &__content {
    overflow: auto;
    max-height: 500px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow:
      0px 2px 8px 0px #1414140a,
      0px 1px 2px 0px #14141405;
  }

  &__icons {
    padding-right: 5px;
  }

  &__icon {
    cursor: pointer;
  }

  &--bigger {
    cursor: pointer;
    path {
      scale: 1.3;
      transform-origin: center;
    }
  }
}
