.details-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.detail {
  display: flex;
  align-items: center;
  gap: 6px;

  line-height: 1 !important;
}
