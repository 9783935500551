@use "/scss/mixins";

.post-wrapper {
  container-name: post-wrapper;
  container-type: inline-size;
  border-radius: 16px 16px 0 0;
  border-color: var(--grey-5);
  border-style: solid;
  border-width: 1px;
  overflow: hidden;

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__avatar {
    display: block;
    padding: 0;
    min-width: 0;
    flex-shrink: 0;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -32px;
    position: relative;
  }

  &__footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    padding-top: 8px;

    & span {
      z-index: 1;
    }

    @container post-wrapper (inline-size >= 400px) {
      padding-top: 24px;
    }

    @include mixins.breakpoint(1050px) {
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }

  &__reply-form {
    background-color: var(--white);
    padding: 0 16px 16px 16px;
    position: relative;
  }
}
