@use "/scss/mixins";

.modal-wrapper {
  width: 600px;
  padding: 32px !important;
  margin: auto 16px !important;
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 16px;

    @include mixins.breakpoint(sm) {
      flex-flow: row wrap;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    @include mixins.breakpoint(sm) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.header {
  &__close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
