@use "/scss/mixins";

.wrapper {
  display: grid;
  margin-top: 24px;
  max-width: 1120px;
  grid-template-columns: 1fr;
  grid-template-areas: "warning-badge" "description" "details" "card" "created";

  @include mixins.job-list-breakpoint(sm) {
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "warning-badge warning-badge"
      "details description"
      "created card";
  }

  @include mixins.job-list-breakpoint(lg) {
    grid-template-columns: auto minmax(280px, 1fr) auto;
    grid-template-areas:
      "warning-badge warning-badge warning-badge"
      "details description card"
      "created description card";
  }
}

.description {
  grid-area: description;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  max-width: 100%;
  padding: 0 16px;

  overflow-wrap: anywhere;
  overflow: hidden;

  gap: 16px;

  @include mixins.job-list-breakpoint(sm) {
    margin-bottom: 16px;
  }

  @include mixins.job-list-breakpoint(lg) {
    margin-bottom: 0;
  }

  &__btns {
    white-space: nowrap;
    margin-bottom: 0;
    margin-top: 16px;

    @include mixins.job-list-breakpoint(lg) {
      margin-top: 0;
    }
  }
}

.details {
  display: flex;
  grid-area: details;
  flex-direction: column;
  gap: 12px;
  width: fit-content;
  max-width: 268px;
  margin: 16px 32px 16px 16px;

  @include mixins.job-list-breakpoint(sm) {
    margin: 0px 32px 0px 16px;
  }
}

.warning-badge {
  grid-area: warning-badge;
  & > div {
    margin-bottom: 24px;
  }
}

.created-at {
  grid-area: created;
  margin-left: 16px;
  display: flex;
  align-items: flex-end;
  margin-top: 16px;
}

.refer-job-card {
  grid-area: card;
  @include mixins.job-list-breakpoint(lg) {
    justify-self: end;

    max-width: 305px;
    margin-left: 16px;
  }
}

.fade {
  position: absolute;
  content: "";
  height: 100%;
  max-height: 150px;
  width: 100%;
  background: linear-gradient(to bottom, transparent 65%, white);
  pointer-events: none;
}
