@use "/scss/mixins";

.textbox {
  display: grid;
  gap: 12px;
  margin-top: auto;
  flex-shrink: 1;
  max-height: 25vh;

  .avatar {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 3px;
    height: 40px;
    width: 40px;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--grey-4);
  padding: 8px;
  overflow-y: auto;

  @include mixins.breakpoint(sm) {
    padding: 16px;
  }
}

.textbox-input {
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }

  :global(.MuiInputBase-input) {
    &::placeholder {
      opacity: 1;
      color: var(--grey-1);
    }
  }

  :global(.MuiOutlinedInput-root) {
    padding: 0 0 0 48px;

    &:global(.Mui-focused) fieldset {
      box-shadow: none !important;
    }
  }
}

.actions-container {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  @include mixins.breakpoint(md) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.secondary-actions {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.actions {
  display: flex;
  gap: 8px;
  align-items: center;

  @include mixins.breakpoint(md) {
    justify-content: space-between;
    width: 100%;
  }
}

.submit-button {
  margin-left: auto;
}

.offer-btn {
  white-space: nowrap;
  margin-left: 8px;
}

button.icon {
  padding: 8px;
  color: var(--grey-1);
  max-height: 40px;

  &:first-child {
    margin-left: -8px;
  }
}
