@use "/scss/mixins";

.suggest-category-modal {
  &__header {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 32px;
  }

  &__chip {
    .icon--start {
      align-self: baseline;
      margin-top: 3px;

      @include mixins.breakpoint(sm) {
        align-self: center;
        margin-top: 0;
      }
    }

    &__emoji {
      vertical-align: text-bottom;
    }
  }

  @include mixins.breakpoint(sm) {
    &__footer {
      flex-direction: row;
    }
  }
}
