.checkbox-cont {
  &:global(.MuiFormControl-root) {
    padding: 0;
  }

  :global(.MuiFormControlLabel-root) {
    padding: 16px;
    margin: 0;
    box-shadow:
      0 2px 4px rgba(20, 20, 20, 0.04),
      0 4px 16px rgba(20, 20, 20, 0.04);
  }

  :global(.MuiFormControlLabel-label) {
    margin-left: 8px;
  }
}
