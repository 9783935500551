@use "/scss/mixins";

.wrapper {
  position: relative;
}

.container {
  padding: 24px;
  border-radius: 16px;
  background-color: var(--medium-green);
  overflow: hidden;

  @include mixins.breakpoint(sm) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 32px;
  }

  &__left {
    @include mixins.breakpoint(sm) {
      max-width: calc(100% - 170px);
      display: flex;
      align-items: flex-start;
      gap: 24px;
      width: 100%;
    }

    & > * {
      z-index: 1;
    }
  }

  &__right {
    display: flex;
    gap: 8px;
    align-items: center;
    float: right;
  }

  &__title {
    margin: 16px 0 4px 0;

    @include mixins.breakpoint(sm) {
      margin: 0;
    }
  }

  &__details-wrapper {
    @include mixins.breakpoint(sm) {
      max-width: calc(100% - 120px);
      width: 100%;
    }
  }
}

.watermark {
  position: absolute;
  left: 50%;

  color: #ffffff;
}
