@use "/scss/mixins";

.container {
  background-color: var(--white);
  border: 1px solid var(--grey-4);
  border-radius: 16px;
  padding: 24px;
  overflow-wrap: anywhere;

  @include mixins.breakpoint(sm) {
    padding: 32px;
  }
}

.svg {
  margin-right: 8px;
}

.read-more-wrapper {
  display: flex;
  width: auto;
  margin-bottom: 16px;
  justify-content: flex-start;

  &__btn {
    gap: 8px;
  }

  &:hover {
    cursor: pointer;
  }
}
