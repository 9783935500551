.saved-search-item {
  max-width: 490px;
  width: 100%;
  padding-block: 1rem;
  padding-inline: 2rem;
  box-shadow: inset 0 -2px 0 -1px var(--grey-4);
  border-radius: 0;
  margin-top: 4px;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &--squared {
    border-radius: 8px;
  }

  &__labels {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-overflow: ellipsis;
    text-align: left;
    word-break: break-word;
  }

  &__remove-button {
    align-self: center;
    padding: 8px 16px;

    &:hover {
      background-color: rgba(var(--black-rgb), 0.1);
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: default;
  }
}
