.bio-field-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.external-profiles__label {
  display: flex;
  gap: 8px;
  align-items: center;
}
