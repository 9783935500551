@use "/scss/mixins";

.header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  gap: 8px;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.title {
  margin-bottom: 0;

  @media (min-width: 640px) {
    margin-bottom: 8px;
  }
}

.logo {
  border-radius: 8px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  line-height: 0;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 28%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.6;
  }
}

.carousel {
  margin: 0 -24px;
  width: auto;

  @include mixins.breakpoint(sm) {
    margin: 0 -32px;
  }

  :global(.swiper-slide) {
    width: fit-content;
  }
}

.cta {
  padding: 8px 32px;
}
