.notification-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 8px;
  color: var(--white);
  background-color: var(--negative-2);
  right: -6px;
  top: -6px;
}

.nav-avatar {
  position: relative;
}

.spaces-sub-nav-label {
  width: 100%;
  max-width: 140px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.spaces-sub-nav-label__name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
