.modal {
  background-color: var(--medium-green);
  width: 500px;
  padding: 50px;

  @media (max-width: 599px) {
    width: 90%;
  }
}

.loader {
  color: var(--positive-2);
}
