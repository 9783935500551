@use "/scss/mixins";

.page {
  --pt: 16px;
  padding-top: var(--pt) !important;

  @include mixins.breakpoint(md) {
    --pt: 32px;
  }
}
