@use "/scss/mixins";

.bio-field-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.location {
  grid-column: span 2;

  @include mixins.breakpoint(sm) {
    grid-column: unset;
  }
}
