.location-warning {
  display: flex;
  cursor: pointer;
  color: var(--negative-2);

  &:hover {
    color: var(--negative-1);
  }
}

.location-warning-btn {
  padding: 2px !important;
  align-self: center;
}
