.container {
  position: relative;
  display: grid;

  border: 1px solid var(--grey-5);
  border-radius: 4px;

  width: 100%;
  min-height: 100px;

  overflow: hidden;
  isolation: isolate;

  outline: 0px solid var(--black);
  outline-offset: 2px;

  aspect-ratio: 2 / 1;

  & > *,
  &::after {
    // stack content on each other
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  &.has-preview,
  &.loading {
    .placeholder {
      opacity: 0;
    }
  }

  &:hover,
  &.active,
  &.focus:not(.has-preview) {
    .placeholder {
      opacity: 1;
      color: var(--white);

      &::after {
        opacity: 0.8;
      }
    }
  }

  &.focus {
    outline-width: 2px;

    transition: outline 100ms ease-out;
  }
}

.placeholder {
  position: relative;
  cursor: pointer;

  color: var(--grey-2);

  z-index: 2;

  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 0 8px;

  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    inset: 0;

    background-color: var(--black);
    opacity: 0;
    transition: all 200ms ease-out;

    z-index: -1;
  }
}

.image {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.image-container {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
