@use "/scss/mixins";

.intro-video {
  &-dropzone {
    border-radius: 8px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='99.5%25' fill='none' rx='8' ry='8' stroke='%23E9D7FFFF' stroke-width='3' stroke-dasharray='7%2c 10' stroke-dashoffset='38' stroke-linecap='square'/%3e%3c/svg%3e");
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;
    padding: 15px 10px;
  }

  &-dropzone-active {
    background-color: var(--soft-violet);
  }

  &-upload-icon {
    border: 1.5px solid var(--black);
    border-radius: 100%;
    font-size: 20px;
    padding: 2px;

    & > path {
      stroke: var(--black);
    }
  }
}

.video-preview-modal {
  box-shadow: none;
  background-color: transparent;
  width: 100%;

  @include mixins.breakpoint(930px) {
    max-width: 990px;
  }
}

.video-preview {
  border-radius: 12px;
  width: 100%;
  height: auto;

  & * * video-js,
  & * * video-js video {
    border-radius: 12px !important;
  }
}

.video-preview-container {
  display: flex;
  height: 100%;
  padding: 50px 0px;

  @include mixins.breakpoint(850px) {
    padding: 45px 50px;
  }
}

.remove-video-modal {
  max-width: 558px !important;
  width: 95%;
}
