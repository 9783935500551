@use "/scss/mixins";

.option:global(.MuiAutocomplete-option) {
  padding: 8px;
  border-radius: 8px;
}

.autocomplete-placeholder-padding input {
  padding: 12px 16px 12px 4px !important;
  font-size: 13px;

  @include mixins.breakpoint(sm) {
    padding-left: 8px !important;
    font-size: 16px;
  }
}

.person-add-icon {
  color: var(--grey-3);
  width: 16px;
  height: 16px;

  @include mixins.breakpoint(sm) {
    width: 24px;
    height: 24px;
  }
}

.invited-option {
  opacity: 1 !important;
}
