@use "/scss/mixins";

.sortable-container {
  margin-left: -25px;
  width: calc(100% + 25px);
  padding: 0;
  overflow: hidden;
}

.sortable-element {
  padding-left: 25px;
  position: relative;
  list-style: none;

  .drag-handle {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    cursor: grab;

    &__arrow {
      width: 20px;
      height: 20px;
    }
  }

  &:hover .drag-handle,
  &.sortable-helper .drag-handle {
    left: 0 !important;
  }
}

.motion-sortable-list {
  &__item {
    position: relative;
    overflow: hidden;
  }

  &__item-control {
    position: absolute;
    top: calc(50% - 16px);
    cursor: grab;
    user-select: none !important;

    & + * {
      padding-left: 30px !important;

      @include mixins.breakpoint(sm) {
        padding-left: 40px !important;
      }
    }
  }
}
