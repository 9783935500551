@use "/scss/mixins";

.item-wrapper {
  display: block;
  width: 100%;
}

.item-grid-container {
  --badge-size: 24px;
  display: grid;
  grid: var(--badge-size) / var(--badge-size) minmax(10px, 1fr) auto;
  column-gap: 4px;
  row-gap: 8px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 8px;

  @include mixins.breakpoint(sm) {
    --badge-size: 56px;
    column-gap: 16px;
  }
}
