@use "/scss/mixins";

.interacts-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
  min-height: 40px;

  &__action-buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    width: 100%;

    @supports not (container-type: inline-size) {
      @include mixins.breakpoint(sm) {
        width: auto;
      }
    }

    @container post-wrapper (inline-size >= 400px) {
      width: auto;
    }

    & > button {
      padding-block: 6px;
      line-height: initial;
      width: 100%;

      @supports not (container-type: inline-size) {
        @include mixins.breakpoint(sm) {
          width: auto;
          min-width: 58px;
        }
      }

      @container post-wrapper (inline-size >= 400px) {
        width: auto;
        min-width: 58px;
      }
    }
  }
}
