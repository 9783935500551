.system-message {
  position: relative;
  text-align: center;
  background-color: var(--off-white);
  border-radius: 16px;
}

.system-message-content {
  padding: 24px;
  padding-top: 16px;
  overflow-wrap: anywhere;
}

.additional-message {
  margin-top: 8px;
}

.additional-message-content {
  display: flex;
  justify-content: center;
  max-height: 52px;
  margin-top: 8px;
  font-style: italic;
  color: var(--grey-1);
  font-size: inherit;
  overflow-wrap: anywhere;
}

.read-more-container {
  display: -webkit-box;
  margin-top: 2px;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--link);
}

.read-more {
  color: initial;
}

.additional-message__link {
  color: var(--link);
}

.action-menu-button {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-20%, 20%);
}

.system-message--inactive {
  color: var(--grey-2);

  .additional-message-content,
  .read-more {
    color: var(--grey-2);
  }
}

.emoji {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.emoji--reported {
  font-style: normal;
  vertical-align: text-bottom;
}

.link {
  color: var(--link);
  font-size: inherit !important;
  word-break: break-all;
}
