@use "/scss/mixins";

.vertical-separator {
  display: none;

  @include mixins.breakpoint(410px) {
    display: block;
  }
}

.post-date {
  width: 100%;
  margin-top: 16px;

  @include mixins.breakpoint(410px) {
    width: revert;
    margin-top: revert;
  }
}

.title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  margin: 16px 0;
}

.save-button {
  margin-right: 12px;
}
