.effect-container {
  position: relative;
  pointer-events: none;

  & button {
    pointer-events: initial;
  }
}

.sparkle-container {
  pointer-events: none;
  position: absolute;
  display: block;
  z-index: 1;
  inset: 0px;
}

.sparkle {
  opacity: 0;
  position: absolute;
  display: block;
}
