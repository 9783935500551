.container {
  overflow: auto;
  position: relative;
}

.anchor {
  position: absolute;

  inset: 0;
  pointer-events: none;
}

.anchor--bottom-fade::after,
.anchor--top-fade::before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 2rem;

  animation: appear 300ms ease forwards;
}

.anchor--bottom-fade::after {
  bottom: 0;

  background-image: linear-gradient(
    to top,
    rgba(228, 228, 228, 0.21) 0px,
    rgba(255, 255, 255, 0) 100%
  );
}

.anchor--top-fade::before {
  top: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(228, 228, 228, 0.21) 0px,
    rgba(255, 255, 255, 0) 100%
  );
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
