.section-panel {
  position: absolute;
  right: 0;
  margin: 8px;
}

.boost-color {
  background-color: var(--soft-blue) !important;
}

.field {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  margin-bottom: 0px;
  border: none;

  & > * {
    margin: 0;
  }
}
