.wrapper {
  position: relative;
  max-width: 800px;
}

.top-section {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 26px 32px;
  background-color: var(--medium-violet);
}

.bottom-section {
  padding: 32px;
  background-color: var(--off-white);
}

.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
}

.browse-job-btn {
  max-width: 132px;
}
