@use "/scss/mixins";

$layout-padding: 20px;

.layout {
  position: relative;
  width: 100%;
  padding: $layout-padding;
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: calc(640px + 2 * $layout-padding);

  @include mixins.breakpoint(sm) {
    padding-top: 80px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: flex-start;
  width: 100%;

  @include mixins.breakpoint(sm) {
    gap: 1.5rem;
    align-self: center;
    align-items: center;
    width: auto;
  }
}

.stepper {
  align-self: flex-start;
  position: static;
  padding: 0;
  width: 100%;

  @include mixins.breakpoint(md) {
    position: absolute;
    padding: 0 20px 0 20px;
    left: 0;
    bottom: 0;
    margin: 70px 0 20px 0;
  }
}

.content {
  align-self: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 44px;

  &--no-margin {
    margin-top: 0;
  }
}

.navigation-buttons {
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
}

.left-section {
  display: flex;
  flex: 3;
  justify-content: center;

  :global(.core-layout) {
    min-height: initial;
  }

  @include mixins.breakpoint(md) {
    :global(.core-layout) {
      min-height: 100vh;
    }
  }
}
