.listing-page {
  width: 100%;
  position: relative;
  max-width: 1120px;

  &__header {
    position: relative;
    width: 100%;
    color: var(--black);
  }

  &__top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
  }

  &__content {
    position: relative;
    min-height: 220px;
  }
}
