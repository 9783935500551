@use "/scss/mixins";

.job {
  &__details {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-areas:
      "basic-details"
      "status"
      "top-skills"
      "seniority"
      "rate"
      "explainer-carousel";

    @include mixins.breakpoint(sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 24px;
      grid-template-areas:
        "basic-details basic-details"
        "status top-skills"
        "seniority rate"
        "explainer-carousel explainer-carousel";

      & > * {
        margin-top: 24px;
      }

      & > :global(.job-overwrite-basic-details) {
        margin-top: 0;
      }

      & > :global(.job-overwrite-rate) {
        margin-top: 24px;
      }
    }

    @include mixins.breakpoint(960px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-areas:
        "basic-details basic-details basic-details basic-details"
        "top-skills seniority rate status"
        "explainer-carousel explainer-carousel explainer-carousel explainer-carousel";
    }
  }

  &__explainer-carousel {
    grid-area: explainer-carousel;
    width: 100%;
  }

  &__status-box {
    grid-area: status;
  }

  &__content {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;

    @include mixins.breakpoint(md) {
      margin-top: 32px;
      grid-template-columns: 5fr 3fr;
    }
  }

  &__similar-jobs {
    margin-top: 49px;
  }
}
