@use "/scss/mixins";

div.invite-to-space-modal {
  height: min(100% - 2rem, 657px);
  width: min(100% - 2rem, 750px);
  padding: 24px;

  border-radius: 8px;

  @include mixins.breakpoint(sm) {
    padding: 30px 30px 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.modal-footer {
  margin-top: 24px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 16px;
}
