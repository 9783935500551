@use "/scss/mixins";

.work-history {
  padding: 32px;
  grid-area: work-history;
  background-color: var(--white);
  border-radius: 16px;
  height: fit-content;
}

.work-history-card {
  padding: 0 0 16px;
  border-bottom: 1px solid var(--grey-4);
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: repeat(3, min-content);
  align-items: center;
  gap: 8px;
  grid-template-areas:
    "work-history-card-icon work-history-card-title work-history-card-expand-btn"
    "work-history-card-date work-history-card-date work-history-card-date"
    "work-history-card-description work-history-card-description work-history-card-description";

  @include mixins.breakpoint(sm) {
    gap: 0 24px;
    grid-template-areas:
      "work-history-card-icon work-history-card-title work-history-card-expand-btn"
      "work-history-card-icon work-history-card-date work-history-card-expand-btn"
      ". work-history-card-description work-history-card-description";
  }

  &__icon {
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 10px;
    background-color: var(--soft-violet);
    grid-area: work-history-card-icon;

    :global(svg) {
      color: var(--dark-violet);
    }

    &--small {
      @include mixins.breakpoint(sm) {
        padding: 16px;
      }

      :global(svg) {
        height: 32px;
        width: 32px;
      }
    }

    &--large {
      @include mixins.breakpoint(sm) {
        padding: 20px;
      }

      :global(svg) {
        width: 24px;
        height: 24px;

        @include mixins.breakpoint(sm) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &__title {
    grid-area: work-history-card-title;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__name-date {
    display: flex;
    align-items: center;
    margin: 0 auto;
    grid-area: work-history-card-date;

    @include mixins.breakpoint(sm) {
      margin: 0;
    }
  }

  &__date {
    display: flex;
    gap: 7px;
    align-items: center;
    white-space: nowrap;

    :global(svg) {
      width: 14px;
      height: 14px;
    }
  }

  &__expand-btn {
    grid-area: work-history-card-expand-btn;
  }

  &__expand-btn--expanded {
    transform: rotateX(180deg);
  }

  &__description {
    margin-top: 20px;
    grid-area: work-history-card-description;
  }

  &:last-child {
    border-bottom: none;
  }
}

.empty-state {
  /* Native CSS properties don't support the customization of border-style
     * The SVG features give us the ability to change the distance between dashed lines
     * @source https://kovart.github.io/dashed-border-generator/
    */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
}

.edit-btn {
  float: right;
}
