#snackbars {
  z-index: 9999;
  position: absolute;
}

:global(.snackbar__link) {
  cursor: pointer;
  text-decoration: underline;
  text-transform: uppercase;
  font-family: var(--primaryFontFamily);
  font-weight: 500;
  line-height: normal;
  padding: 2px 10px 0 0;
  letter-spacing: -0.3px;
}

:global(.snackbar__link--warning) {
  color: #fff;
}

.snackbar {
  word-break: break-word;
  flex-wrap: nowrap;

  & > :global(.notistack-MuiContent-success) {
    background-color: var(--positive-1);
    font-size: 16px;
  }

  & > div:first-of-type {
    flex-wrap: nowrap;
  }
}

.snackbar-container {
  z-index: 2000 !important; // higher than mui backdrop
}
