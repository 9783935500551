.wrapper {
  height: 746px;
  display: flex;
  flex-direction: column;
}

.actions {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
