@use "/scss/mixins";

.rating-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 9px 14px;
  gap: 8px;
  border-radius: 10px;
  width: auto;

  @include mixins.breakpoint(460px) {
    width: fit-content;
  }

  @include mixins.breakpoint(680px) {
    max-width: 480px;
  }

  &--short {
    flex-direction: column;
  }

  &--white {
    background-color: var(--white);
  }

  &--off-white {
    background-color: var(--off-white);
  }

  &--soft-beige {
    background-color: var(--soft-beige);
  }
}
