@use "/scss/mixins";

.feed-hashtag-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;

  gap: 16px;

  @include mixins.breakpoint(sm) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
