@use "/scss/mixins";
@use "/scss/variables";

.review-modal {
  width: 96%;
  max-width: 900px;

  &__head {
    background-color: var(--black);
    padding: 20px;
    color: #fff;

    @include mixins.breakpoint(sm) {
      padding: 30px;
    }
  }

  &__title {
    color: inherit;
    margin: 0 0 26px;
  }

  &__summary-value {
    font-size: 80px;
    color: var(--dark-orange);
    line-height: 1.4;
    margin-bottom: 16px;
  }

  &__info {
    color: inherit;

    &:not(:last-child) {
      margin: 0 0 30px;
    }
  }

  &__rate {
    color: var(--white);
    vertical-align: text-bottom;
  }

  &__stars-box {
    svg {
      font-size: 25px !important;
    }
  }

  :global(.progress-bar-with-label) {
    width: 170px;
    display: inline-block;
    margin: 0 10px 0 5px;
    vertical-align: super;
  }

  &__body {
    padding: 20px;
    position: relative;

    @include mixins.breakpoint(sm) {
      padding: 30px;
    }
  }

  &__progress-bar {
    background-color: var(--dark-orange) !important;
  }
}

.write-review-button {
  margin-top: 5px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
