.application-status-pill {
  padding: 0 12px;
  border-radius: 500px;
  text-align: center;
}

.tooltip {
  width: fit-content;
  min-width: 0;
}
