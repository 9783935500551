.rule-title-field {
  margin-bottom: 24px;
}

.rule-title-label-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.rule-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.divider {
  margin-top: 24px;
  width: 100%;
}
