.error-icon {
  position: absolute;
  top: 14px;
  left: 12px;
}

.close-btn {
  position: absolute;
  top: 2px;
  right: 4px;
}
