.job-description-upload-status-tag {
  &__wrapper {
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    margin-bottom: 24px;
    width: fit-content;
    margin-top: -24px;

    &--uploading {
      background-color: var(--soft-blue);
    }

    &--failed {
      background-color: var(--soft-red);
    }

    &--processed {
      background-color: var(--medium-orange);
    }
  }

  &__loader {
    border-radius: 100%;
    background-color: var(--medium-blue);
    padding: 4px;
  }

  &__info-icon {
    color: var(--negative-2);
  }
}
