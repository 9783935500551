@use "/scss/mixins";

.modal {
  display: flex;
  width: 100%;
  max-width: 1240px;
  margin: initial !important;
  padding: 0;
  padding-top: 10px;

  @include mixins.breakpoint(sm) {
    padding: 30px;
  }
}

button.close-button {
  right: -5px;
  top: -5px;

  @include mixins.breakpoint(sm) {
    top: 5px;
    right: 8px;
  }
}

.messenger--no-user {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  padding: 50px 30px;

  text-align: center;
}

.btn-guest {
  padding: 6px 20px !important;
  white-space: nowrap;
}
