@use "/scss/mixins";

.payment-method-failed-top-bar {
  background-color: var(--negative-2);
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  gap: 0.5rem;
  padding-block: 5px;
  padding-inline: 16px;
  min-height: 38px;

  &__copy {
    @include mixins.breakpoint(sm) {
      text-align: center;
      flex: 1;
      padding: 0;
    }
  }

  &__dismiss-btn {
    height: 32px;
    width: 32px;
    padding: 0;
  }
}
