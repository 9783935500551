@use "/scss/mixins";

.section-header {
  background: linear-gradient(
    90deg,
    #d4e4f9 0.14%,
    #e5d7fb 48.5%,
    #fff5e3 76.06%,
    #fffdbd 99.97%
  );
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px 22px 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  @include mixins.breakpoint(sm) {
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
}

.switch-label-icon {
  filter: brightness(0) saturate(100%) invert(1%) sepia(13%) saturate(3803%)
    hue-rotate(224deg) brightness(96%) contrast(87%);
}
