@use "/scss/mixins";

.success-snackbar {
  position: relative;

  display: flex;
  gap: 8px;

  padding: 16px;

  background-color: var(--medium-green);

  border-radius: 8px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    height: 3px;
    width: 65%;

    background-color: var(--dark-green);
    opacity: 50%;
  }
}
