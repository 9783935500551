.image-wrapper {
  position: relative;

  margin-bottom: 16px;

  width: 240px;
  height: 109px;
}

.image {
  border-radius: 6px;
}

.linkedin-icon {
  position: absolute;

  right: -1rem;
  top: -1rem;

  font-size: 42px;
  color: #0a66c2;
}

.loader-container {
  display: grid;
  place-items: center;

  position: absolute;
  inset: 0;

  z-index: 1;
}

.loader span {
  color: var(--dark-violet);
}
