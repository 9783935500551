@use "/scss/mixins";

.reviewer {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;

  @include mixins.breakpoint(sm) {
    max-width: 160px;
    margin-right: 32px;
    display: block;
  }

  &__name {
    word-break: break-word;

    @include mixins.breakpoint(sm) {
      margin-top: 8px;
    }
  }
}
