@use "/scss/mixins";

.add-invoice-item {
  &__total {
    @include mixins.breakpoint(md) {
      word-break: break-all;
    }
  }

  &__remove {
    @include mixins.breakpoint(xl) {
      opacity: 0;
    }
  }

  &:hover &__remove,
  &__remove:focus {
    opacity: 1;
  }

  &__last-row {
    border: 0 !important;
    padding: 40px 0 0 !important;
  }

  td {
    padding: 12px;

    @include mixins.breakpoint(md) {
      padding: 13px 15px;
    }

    :global(.text-right input) {
      text-align: right;
    }
  }
}
