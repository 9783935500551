@use "/scss/mixins";

.message {
  margin-bottom: 8px;
  width: calc(100% - 8px);
}

.message--right .content__inner,
.message--left + .message--right .content__inner {
  border-top-right-radius: 0;
}

.message--left .content__inner,
.message--right + .message--left .content__inner {
  border-top-left-radius: 0;
}

.message--right {
  .message__timestamp {
    text-align: right;
  }

  .content {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.message__timestamp {
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: left;

  @include mixins.breakpoint(sm) {
    margin-top: 32px;
  }
}

.content {
  overflow-wrap: anywhere;

  @include mixins.breakpoint(md) {
    max-width: 90%;
  }

  a:not(:global(.MuiButtonBase-root)) {
    font-size: inherit;
    color: inherit;
  }

  @include mixins.breakpoint(sm) {
    width: 100%;
  }
}

.content__more {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20%, -20%);
}

.content__inner {
  text-align: left;
  display: inline-flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 15px 14px;
  position: relative;
  white-space: pre-line;
  word-break: break-word;
}

.text-message {
  color: var(--black);

  &--soft-grey {
    background-color: var(--soft-grey);
  }

  &--teal {
    background-color: var(--dark-teal);
    color: var(--white);
  }

  &--medium-red {
    background-color: var(--medium-red);
  }

  &--soft-red {
    background-color: var(--soft-red);
  }
}
