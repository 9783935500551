@use "/scss/mixins";

.wrapper {
  grid-area: actions-or-status;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -4px;

  @include mixins.breakpoint(sm) {
    flex-wrap: wrap;
  }

  @include mixins.breakpoint(md) {
    flex-wrap: nowrap;
  }
}
