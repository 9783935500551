.form {
  width: 100%;
  height: 48px;
  position: relative;

  @media screen and (max-width: 600px) {
    position: inherit;
  }
}

.button {
  width: 48px;
  height: 48px;
}

.search {
  position: absolute;
  width: 100%;
  max-width: 417px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--grey-4);
  border-radius: 80px;

  @media screen and (max-width: 600px) {
    border: none;
    background-color: transparent;
  }

  &--open {
    max-width: 557px;
    width: 557px;
    background-color: var(--white);
    border: 1.5px solid var(--medium-violet);
    border-radius: 16px;
    box-shadow: var(--elevation-6);

    @media screen and (max-width: 600px) {
      width: calc(100vw - 16px);
      top: 8px;
      left: 8px;
    }

    @media screen and (min-width: 600px) and (max-width: 650px) {
      width: calc(100vw - 72px);
    }
  }
}

.content {
  max-height: min(680px, 90vh);
  overflow: hidden;
}

.results {
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: hidden;

  min-height: 4rem;
}
