@use "/scss/mixins";

.autocomplete {
  fieldset {
    border-radius: 24px;
  }

  &:hover,
  &:focus-within {
    fieldset {
      border-color: var(--medium-violet) !important;
    }
  }

  &:focus-within fieldset {
    border-width: 2px;
  }

  &:global(.Mui-expanded) fieldset {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-width: 2px;
    border-color: var(--medium-violet) !important;
  }
}

.list-box {
  border: 2px solid var(--medium-violet);
  border-top: none;
  border-radius: 0 0 16px 16px;
  margin: 0;

  &:before {
    content: "";
    width: calc(100% - 32px);
    height: 1px;
    display: block;
    background-color: var(--grey-5);
    margin: 0 auto;
  }

  :global(.MuiAutocomplete-noOptions) {
    padding: 16px;
  }
}

.autocomplete-input input {
  padding: 12px 16px !important;

  &::placeholder {
    color: var(--grey-1);
    opacity: 1; /* Firefox */
  }

  &::input-placeholder {
    /* Edge 12 -18 */
    color: var(--grey-1);
  }
}
