@use "/scss/mixins";

.member-wrapper {
  display: block;
  width: 100%;
}

.member {
  --badge-size: 3rem;
  display: grid;
  grid: var(--badge-size) / var(--badge-size) minmax(10px, 1fr);
  column-gap: 16px;
  row-gap: 8px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 8px;

  @include mixins.breakpoint(sm) {
    grid: var(--badge-size) / var(--badge-size) minmax(10px, 1fr) 200px;
  }

  &__badge {
    background-color: var(--off-white);
    color: var(--grey-1);
    padding: 0 8px;
    border-radius: 16px;
    line-height: 1;
    width: fit-content;
    margin-bottom: 8px;

    @include mixins.breakpoint(sm) {
      margin-bottom: 0;
    }
  }

  &__add-member {
    background-color: var(--medium-blue);
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 22px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &__invite-tag {
    display: inline-block;
    background-color: var(--medium-grey);
    padding: 4px;
    border-radius: 4px;
    font-size: 10px !important;
    line-height: normal !important;
  }
}
