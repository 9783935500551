@use "/scss/mixins";

.unsubscribe-all-notifications {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;

  @include mixins.breakpoint(md) {
    margin-right: 42px;
  }
}
