// increase specificity by adding button
button.submit-btn :global(.icon--end) {
  margin-right: 0;
}

.error-message {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  margin: 32px 0;
  border-radius: 4px;
  gap: 12px;
  background-color: var(--soft-red);
  padding: 0 16px;
}
