@use "/scss/mixins";

.button {
  overflow-y: hidden;

  &__icon {
    width: 18px;
    height: 18px;

    &--active {
      svg * {
        stroke: var(--dark-violet);
      }
    }

    &--open {
      svg * {
        stroke: var(--black);
      }
    }

    svg {
      font-size: 18px;
    }
  }
}

.popover {
  min-width: 320px;
  max-height: 400px;
  padding: 16px;
  @include mixins.breakpoint(smallHeight) {
    max-height: 544px;
  }

  @include mixins.breakpoint(xs) {
    min-width: 368px;
  }

  @include mixins.breakpoint(sm) {
    min-width: 470px;
    padding: 24px;
  }

  &__paper {
    border-radius: 8px;
    border: 1px solid var(--medium-violet);
    box-shadow:
      0px 8px 16px rgba(20, 20, 20, 0.04),
      0px 12px 32px rgba(20, 20, 20, 0.04);
  }
}

.popover-content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 16px;
  &--no-bottom-padding {
    padding-bottom: 0px;
  }

  &--restricted-height {
    max-height: 220px;
    @include mixins.breakpoint(xs) {
      max-height: 315px;
    }
    @include mixins.breakpoint(smallHeight) {
      max-height: 416px;
    }
  }

  &--shown-overflow {
    overflow: visible;
  }
}
