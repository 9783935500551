.icon {
  color: var(--grey-1);

  path {
    stroke: currentColor;
  }
}

.search {
  z-index: 1;

  input {
    padding: 8px;

    &:-webkit-autofill {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  label {
    top: 50%;
    left: 56px;

    line-height: 1;
    font-size: 13px;
    color: var(--grey-3) !important;

    transform: translateY(-50%);
  }

  :global(.MuiInputBase-root) {
    background-color: var(--white);
    border-radius: 5rem;
  }
}
