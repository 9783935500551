@use "/scss/mixins";

.error-section {
  max-width: 510px;
}

.job-bid-form {
  @include mixins.breakpoint(md) {
    display: grid;
    grid-template-columns: 2fr auto;
    column-gap: 32px;
  }

  @include mixins.breakpoint(lg) {
    grid-template-columns: 2fr 3fr;
  }
}
