.notification {
  margin-top: 0 !important;
  border-bottom: 1px solid #edeff2;
}

.content {
  display: block;
  padding: 16px 52px 16px 16px;
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  color: black;
  text-decoration: none !important;

  &__title {
    font-size: 13px;
    line-height: 24px;
  }
}

.badge-dot {
  top: 8px;
  right: -34px;
}
