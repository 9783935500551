.comment-text-field {
  &__wrapper {
    display: flex;
    flex-grow: 1;
    gap: 8px;
  }
}

.save-comment-button {
  padding: 0px 16px;
}

.expanding-input {
  position: relative;

  &__root {
    padding: 8px 14px;
    border-radius: 20px;

    & textarea,
    *::placeholder {
      font-size: 13px !important;
    }

    & textarea {
      padding-block: 0 !important;
      resize: none !important;
    }
  }

  &__input {
    max-width: 100%;
  }

  &__content {
    visibility: hidden;
    min-width: max-content;
    font-size: 16px;
    position: absolute;
    white-space: pre-line;
    padding: 48px;
    font-family: var(--primaryFontFamily);
  }
}
