.user-profile {
  display: flex;
  text-align: left;

  &__thumbnail {
    margin: 3px 20px 3px 0;
  }

  &__content {
    overflow: hidden;
    width: 100%;
    padding-right: 25px;
  }
}
