.category-containter {
  display: grid;
  place-items: center;

  height: 100%;

  background-color: var(--medium-green);

  border-radius: 8px;
}
