@use "/scss/mixins";

.public-space-page-signup-banner {
  &__avatar {
    border: 2px solid var(--white);
    border-radius: 100%;
  }

  &__badge {
    border: 2px solid var(--white);
    background-color: var(--soft-green);
    color: var(--dark-green);
    border-radius: 35px;
    padding: 0 10px;
  }

  &__copy {
    display: none;

    @include mixins.breakpoint(sm) {
      display: block;
    }
  }
}
