.container {
  background-color: var(--soft-red);
  padding: 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: normal;

  &--red {
    background-color: var(--medium-red);
  }
}

.logo {
  display: inline-block;
  padding: 2px;
  background-color: var(--white);
  border-radius: 100%;
  display: inline-flex;
}

.header {
  display: flex;
  align-items: center;
  gap: 6px;
}

.show-message-button {
  display: flex;
  align-self: baseline;
}

.report-not-spam-button {
  display: flex;
  align-self: baseline;
  margin-top: 20px;
}

.link {
  text-decoration: underline;
}
