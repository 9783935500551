.location-warning-badge {
  border-radius: 11px;
  background-color: var(--medium-orange);
  padding: 20px;
}

.location-warning-badge__message {
  text-decoration: underline;
  cursor: pointer;
  vertical-align: baseline;
  font-size: 16px;
}
