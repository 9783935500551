@use "/scss/mixins";

.availability-modal__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 511px;
  position: relative;
}

.availability-modal__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
}

.availability-modal__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.availability-modal__close-icon {
  position: absolute;
  top: -18px;
  right: -18px;
}

.availability-modal__open-options {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 24px;
}

.availability-modal__open-options-title {
  margin-top: 16px;
  margin-bottom: 8px;
}

.availability-modal__error-section {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 24px;
  flex-direction: column;

  @include mixins.breakpoint(sm) {
    flex-direction: row;
    align-items: center;
  }
}
