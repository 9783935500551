.available-for-work-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--medium-violet);
  cursor: pointer;

  &--short {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 1;
    right: -5px;
    bottom: -5px;
  }

  &--regular {
    border-radius: 8px;
    max-width: 160px;
    padding: 0px 10px;
    gap: 8px;
  }
}
