@use "/scss/mixins";

.container {
  display: grid;
  grid-template-columns: max-content minmax(10px, 1fr);

  column-gap: 16px;

  align-items: center;
}

.avatar {
  align-self: start;
  margin-block: 1px;
}
