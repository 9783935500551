/*
  !deprecated
  Colors below are defined due to compatibility reasons
  Please use design-colors above.
  Old colors are defined also in braintrust-ui-components
*/
$grayColor: #73739c;
$iconColor: $grayColor;

$inputBorderColor: #e4e5eb;
$inputHoverBorderColor: #6b7194;
$inputLabelColor: #656d8d;

$wrapperMinWidth: 360px;
$wrapperWidth: 1440px;
