@use "/scss/mixins";

.auto-invite-container {
  display: flex;
  width: 100%;
  align-self: flex-start;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  background: var(--medium-blue);

  .content {
    margin: 0;
    padding-top: 8px;
    color: #3b3b3b;
    letter-spacing: -0.08px;
  }
}

.status-wrapper {
  display: flex;
  gap: 15px;
  flex-direction: column-reverse;

  @include mixins.breakpoint(lg) {
    flex-direction: row;
  }
}
