ul.container {
  display: grid;
  grid-template-columns: repeat(3, minmax(10px, 1fr));

  gap: 8px;

  position: relative;

  width: 100%;
  margin: 0;

  li {
    list-style: none;
    cursor: pointer;
  }

  &.loading li {
    cursor: default;
  }
}
