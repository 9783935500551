@use "/scss/mixins";

.welcome-modal {
  padding: 16px;
  max-width: 1160px;
  width: calc(100vw - 32px);
  border-radius: 34px;

  @media (max-width: 430px) {
    width: 100%;
  }
}

.content {
  padding: 40px 30px;
  background-color: var(--soft-green);
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  border-radius: 24px;

  @media (max-width: 600px) {
    padding: 30px 16px 40px;
  }
}

.mobile-wrapper {
  position: fixed;
  width: 100%;
  background-color: var(--white);
  padding: 20px 26px;
  bottom: 0;
  left: 0;

  @media (max-width: 375px) {
    padding: 20px 16px;
  }
}
