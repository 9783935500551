@use "../placeholders.module.scss";
@use "/scss/mixins";

$messages-page-top-padding: 1.5rem;
$messages-page-bottom-padding: 1.5rem;

.app-layout {
  display: flex;
  flex-direction: column;
}

.app-layout-content {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  min-height: min-content;
  padding: 40px 16px;
  flex-grow: 1;

  @include mixins.breakpoint(sm) {
    padding: 60px 30px;
  }

  @include mixins.breakpoint(xl) {
    padding: 60px 4rem;
  }

  &:global(.messages-page) {
    padding-top: $messages-page-top-padding;
    padding-bottom: $messages-page-bottom-padding;

    @include mixins.breakpoint(md) {
      padding-left: 16px;
    }

    @include mixins.breakpoint(lg) {
      padding-left: 30px;
    }
  }
}

body {
  background-color: var(--page-bg, transparent);
}
