@use "/scss/mixins";

.container {
  padding: 1rem;
  margin-inline: 26px;

  text-align: center;

  background-color: var(--soft-green);

  border-radius: 16px;
  border: 2px dashed var(--medium-green);

  @include mixins.breakpoint(sm) {
    padding: 2.625rem;
  }
}

.icon-container {
  --size: 64px;

  display: grid;
  place-items: center;

  width: var(--size);
  height: var(--size);

  margin-bottom: 10px;

  background-color: var(--medium-green);

  border-radius: 50%;

  & > * {
    line-height: 1 !important;
  }
}
