@use "/scss/mixins";

.refer-client-program {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  justify-items: center;
  background-color: var(--soft-violet);
  padding: 16px;
  border-radius: 8px;

  min-height: 422px;

  @include mixins.breakpoint(1090px) {
    grid-template-columns: 1fr minmax(0, 400px);
    align-items: center;
    gap: 48px;
    padding: 40px;
  }
}

.text-content {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &--opted-in {
    gap: 8px;
  }
}
