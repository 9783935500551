@use "/scss/mixins";

.logo {
  color: #3b71a6;
  text-decoration: none;
  font-weight: bold;
  font-size: 0;
  line-height: 1;
  display: block;
  text-align: center;
  align-self: flex-start;

  @include mixins.breakpoint(sm) {
    display: initial;
    text-align: initial;
    align-self: initial;
  }

  &--black {
    filter: invert(100%);
  }
}
