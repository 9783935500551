@use "/scss/mixins";

$modalPadding: 30px;
$mobileModalPadding: 18px;

div.modal-content {
  position: relative;
  margin: auto;
  word-break: break-word;

  &__container-scrollable {
    justify-content: center;
    overflow-x: auto;
    overflow-y: auto;
    height: 100%;
    outline: 0;
    display: flex;
    padding: $mobileModalPadding 0;

    @include mixins.breakpoint(sm) {
      padding: $modalPadding 0;
    }
  }

  &--no-padding {
    @media (max-width: 959px) {
      padding: 0;
    }
  }

  &.with-padding {
    padding: $mobileModalPadding;

    @include mixins.breakpoint(sm) {
      padding: $modalPadding;
    }
  }
}

button.modal-close-button {
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 999;

  @include mixins.breakpoint(sm) {
    top: 20px;
    right: 24px;
  }
}

.modal-backdrop {
  &--black {
    background-color: var(--black) !important;
    opacity: 0.8 !important;
  }
}
