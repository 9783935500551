@use "/scss/mixins";

.payment-method-option {
  --pl: 10px;
  padding-left: var(--pl) !important;

  @include mixins.breakpoint(sm) {
    --pl: 20px;
  }
}
