@use "/scss/mixins";

.comment-list {
  display: grid;
  gap: 10px;
  margin-top: 20px;
}

.comment {
  --content-width: minmax(0, 1fr);

  display: grid;
  grid-template-columns: max-content var(--content-width) max-content;
  grid-template-rows: max-content 1fr;
  gap: 0 1rem;
  overflow: hidden;

  @media (min-width: 700px) {
    --content-width: 60%;
  }

  @include mixins.breakpoint(md) {
    --content-width: 455px;
  }

  &__details {
    display: flex;
    grid-column: 2;
    gap: 0 10px;
  }

  &__content {
    grid-column: 2;
    white-space: pre-line;
    word-break: break-word;
  }

  &__avatar {
    grid-row: span 2;
    margin-top: 8px;
  }

  &__user-avatar {
    width: 40px;
    height: 40px;
  }
}
