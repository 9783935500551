@use "/scss/mixins";

.container {
  height: 100%;

  gap: 24px;
  padding-block: 32px;

  overflow: hidden;

  & > * {
    padding-inline: 18px;
  }

  @include mixins.breakpoint(md) {
    gap: 32px;
    padding-block: 40px;

    & > * {
      padding-inline: 40px;
    }
  }
}
