.public-name {
  width: 100%;
  margin: 0;
  font-size: inherit;
  font-weight: 500;

  &-text {
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    line-height: 1.3;
    word-break: break-word;

    &-with-img {
      max-width: calc(100% - 25px);

      &:after {
        content: url("/static/jobs/icon-Approved.svg");
        margin-left: 5px;
      }
    }

    &-single-line {
      white-space: nowrap;
      position: relative;
      max-width: 100%;
      padding-right: 25px;

      &:after {
        position: absolute;
        right: 0;
      }
    }
  }
}
