@use "/scss/variables";
@use "../placeholders.module.scss";

.full-page-layout {
  background-color: var(--white);

  &-header {
    @extend %wrapper;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &-content {
    max-width: variables.$wrapperWidth;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: calc(100% - 100px);
    width: 94%;
  }

  &.narrow &-content {
    max-width: 750px;
  }

  &.centered &-content {
    text-align: center;
    justify-content: center;
  }
}
