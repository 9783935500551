@use "/scss/mixins";

.logo {
  @media (max-width: 600px) {
    align-self: center;

    img {
      height: 24px;
      width: fit-content;
    }
  }
}

.wrapper {
  display: flex;
  padding: 16px;
  max-height: 225px;
  border-radius: 16px;
  border: 1px solid var(--soft-beige);
  box-shadow: var(--elevation-5);
  width: 100%;
  z-index: 100;
  background-color: var(--white);

  @include mixins.breakpoint(sm) {
    width: 300px;
  }

  @include mixins.breakpoint(xl) {
    position: fixed;
    top: 10px;
    left: 10px;
  }
}

.logo-wrapper {
  max-width: 72px;
  min-width: 72px;
  min-height: 72px;
  border-radius: 12px;
  background-color: var(--medium-green);
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-details {
  margin-left: 16px;
  min-width: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.badge {
  position: relative;
  left: -5px;
  padding: 2px 8px;
  background-color: var(--positive-3);
  color: var(--positive-1);
  border: 2px solid var(--white);
  font-size: 13px;
  line-height: 24px;
  border-radius: 35px;
}
