.title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.arrow {
  display: none;
}

.title:hover .arrow {
  display: block;
}
