.input {
  margin-top: 8px;
  max-width: 100px;
}

.button {
  :global(.typography) {
    font-weight: 500;
  }
}
