.select {
  color: var(--grey-1);

  & :global(.MuiMenuItem-root) {
    padding: 4px;

    &:focus:not(:focus-visible) {
      background-color: var(--white);
    }

    &:hover {
      background-color: var(--grey-5);
    }
  }

  & :global(.Mui-selected) {
    background-color: var(--white);
  }
}

.item-label {
  display: grid;
  grid-template-columns: 16px max-content;
  align-items: center;
  align-content: center;
  gap: 8px;

  &__icon {
    display: flex;
    align-items: center;
  }
}
