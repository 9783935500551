@use "/scss/mixins";

.sort-button {
  padding-right: 0;

  @include mixins.breakpoint(sm) {
    padding-right: 16px;
  }

  &__menu {
    padding: 8px;
  }

  &__arrow {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: rotate(0deg);

    &--expanded {
      transform: rotate(180deg);
    }
  }
}
