.coachmark__close-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.available-for-work-content {
  padding: 1px;
}

.coach-mark-button-cta {
  text-decoration: underline;
  font-family: var(--primaryFontFamily);
  font-size: 13px;
  vertical-align: baseline;
  font-weight: 500;
}
