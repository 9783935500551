.underline {
  background-image: linear-gradient(0, var(--yellow), var(--yellow));
  background-repeat: no-repeat;
  background-size: 100% 20%;
  background-position: 0 87%;

  &--blue {
    background-image: linear-gradient(
      0,
      #{rgba(var(--info-2-rgb), 0.2)},
      #{rgba(var(--info-2-rgb), 0.2)}
    );
  }

  &--yellow {
    // duplicate due to Typography api
    background-image: linear-gradient(0, var(--yellow), var(--yellow));
  }

  &--white {
    // duplicate due to Typography api
    padding-bottom: 0.5em;
    background-image: linear-gradient(0, var(--white), var(--white));
  }

  &--title-medium {
    background-size: 100% 30%;
    background-position: 0 100%;
  }
}

.text-field {
  width: 100%;

  &.number-enhanced input {
    text-align: center;
    font-size: 28px;
    letter-spacing: 20px;
  }
}
