.air-ai-bar {
  background-color: var(--black);
  display: flex;
  justify-content: space-between;

  &__content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    text-align: center;
    gap: 8px;
    padding: 8px 16px;
  }

  &__icon_content {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 8px;
    align-self: center;
  }

  &__pill {
    background: linear-gradient(
      309.05deg,
      #d4e4f9 9.33%,
      #e9d7ff 33.49%,
      #f3dbd9 63.44%,
      #ffe0a9 95.62%,
      #fffdbd 120.29%
    );
    color: var(--black);
    border: 2px solid var(--medium-orange);
    border-radius: 35px;
    padding: 2px 12px;
    white-space: nowrap;
  }

  &__copy {
    color: var(--white);
    align-self: center;

    button {
      .icon--end {
        margin-left: 4px;
      }

      span > svg > path {
        stroke-width: 2.5;
      }
    }
  }
}

@media (max-width: 1000px) {
  .air-ai-bar {
    &__content {
      flex-direction: column;
    }

    &__icon_content {
      align-self: start;
    }

    &__copy {
      text-align: start;
      align-self: start;
    }
  }
}
