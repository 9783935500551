.item {
  --badge-size: 3.5rem;

  display: grid;
  grid: var(--badge-size) / var(--badge-size) minmax(10px, 1fr);
  column-gap: 16px;

  align-items: center;

  padding: 8px;

  white-space: nowrap;

  overflow: hidden;

  border-radius: 8px;
}
