@use "sass:math";
@use "sass:meta";
@use "sass:list";

@mixin transition() {
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@mixin rangeBreakpoint($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin breakpoint($point) {
  @if $point == xl {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: 1280px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 960px) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $point == xs {
    @media (min-width: 400px) {
      @content;
    }
  } @else if $point == smallHeight {
    @media (min-height: 750px) {
      @content;
    }
  } @else if $point == mediumHeight {
    @media (min-height: 850px) {
      @content;
    }
  } @else {
    @media (min-width: $point) {
      @content;
    }
  }
}

@mixin job-list-breakpoint($point) {
  @if $point == lg {
    @supports not (container-type: inline-size) {
      @include breakpoint(1150px) {
        @content;
      }
    }
    @container jobs-container (inline-size >= 828px) {
      @content;
    }
  } @else if $point == md {
    @supports not (container-type: inline-size) {
      @include breakpoint(md) {
        @content;
      }
    }
    @container jobs-container (inline-size >= 742px) {
      @content;
    }
  } @else if $point == sm {
    @supports not (container-type: inline-size) {
      @include breakpoint(sm) {
        @content;
      }
    }
    @container jobs-container (inline-size >= 550px) {
      @content;
    }
  } @else {
    @media (min-width: $point) {
      @content;
    }
  }
}
