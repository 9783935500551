@use "/scss/variables";

.total-invoice {
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  &__amount {
    word-break: break-all;
  }

  &__total {
    padding: 30px 0 0 24px;
    border-top: 2px solid variables.$inputBorderColor;
    margin-top: 60px;
    padding-top: 30px;
  }
}
