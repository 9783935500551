.job-overview-textarea {
  & > div > {
    div::before,
    div::after {
      content: none;
    }
  }

  & :global(.textarea__helper-text-wrapper) > span {
    text-align: start;
  }

  :global(#job-overview),
  :global(#job-overview-helper-text) {
    padding: 12px 16px;
  }
}
