.recommendation-badge {
  gap: 5px;
  border-radius: 20px;
  padding: 0px 8px;
  align-items: center;
  margin-top: auto;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;

  &.pass {
    background: linear-gradient(
      119.92deg,
      #d4e4f9 1.7%,
      #e5d7fb 44.69%,
      #fff5e3 80.85%,
      #fffdbd 102.16%
    );
    color: var(--dark-violet);
  }

  &.mixed {
    background: var(--soft-orange);
    color: var(--dark-orange);

    img {
      filter: brightness(0) saturate(100%) invert(38%) sepia(24%)
        saturate(3863%) hue-rotate(9deg) brightness(105%) contrast(92%);
    }
  }

  &.fail {
    background: var(--soft-red);
    color: var(--dark-red);

    img {
      filter: brightness(0) saturate(100%) invert(23%) sepia(55%)
        saturate(2364%) hue-rotate(343deg) brightness(99%) contrast(100%);
    }
  }
}
