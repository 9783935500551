@use "/scss/mixins";

.notifications-section {
  display: flex;
  flex-direction: column;
  background-color: var(--off-white);
  padding: 12px 8px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: relative;
  gap: 24px;

  &--single {
    @include mixins.breakpoint(1024px) {
      align-items: center;
    }

    & .notifications-section__row {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  @include mixins.breakpoint(md) {
    flex-direction: row;
    padding: 36px 32px;
    border-radius: 16px;
    justify-content: space-between;
    gap: 0;
  }

  &__title {
    max-width: 210px;
    text-transform: capitalize;

    @include mixins.breakpoint(sm) {
      margin-bottom: 0;
      width: 33%;
    }
  }

  &__list {
    flex: 2;
  }

  &__row {
    display: grid;
    grid-template-columns: [notification-name] 1fr [toggle] 145px;
    column-gap: 16px;
    align-items: center;
    margin-bottom: 8px;
    padding-bottom: 8px;

    &:only-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    &__toggle {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;

      @include mixins.breakpoint(sm) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        &:first-of-type {
          margin-top: 10px;
        }
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--grey-4);
    }

    @include mixins.breakpoint(sm) {
      padding-block: 20px;

      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  &__input--end {
    place-content: end;
    display: grid;

    & > label {
      margin: 0;
    }
  }

  &__column-labels {
    display: grid;
    grid-template-columns: [notification-name] 1fr [toggle] 80px;
    gap: 16px;
    margin-bottom: 24px;

    &-header {
      text-align: center;
    }
  }

  &__column-labels + &__row {
    padding-top: 0;
  }
}

.with-additional-toggle {
  grid-template-columns: [notification-name] 1fr [toggle] 95px [additional-toggle] 95px;
  gap: 8px;

  @include mixins.breakpoint(lg) {
    gap: 64px;
  }

  @include mixins.breakpoint(xs) {
    grid-template-columns: [notification-name] 1fr [toggle] 80px [additional-toggle] 145px;
    gap: 16px;
  }
}
