.sanitized-content {
  overflow: hidden;

  &.no-p-margin {
    p {
      margin: 0 !important;
    }
  }

  &.white-space {
    &--pre-line {
      white-space: pre-line !important;
    }
    &--normal {
      white-space: normal !important;
    }
  }
}
