.refer-to-job-button {
  &--link {
    vertical-align: -1px;
    cursor: pointer;
  }
}

.limit-width {
  max-width: 600px;
  width: 98%;
}
