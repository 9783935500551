@use "/scss/mixins";

.room-list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  flex-shrink: 0;

  padding-block: 16px;
  border-radius: 16px;

  background-color: var(--white);

  width: 287px;
  height: 100%;

  @include mixins.breakpoint(sm) {
    padding-block: 24px;
  }

  @include mixins.breakpoint(lg) {
    width: 357px;
  }

  &__unread-count {
    border-radius: 50px;
    background-color: var(--info-2);
    color: var(--white);
    padding: 0px 9px;
    text-align: center;
    white-space: nowrap;
    align-self: flex-start;
  }
}

.search {
  position: relative;
  z-index: 0;
  margin-bottom: 16px;
  padding: 0 1.5rem;
}

.list-item {
  border-radius: 16px;
  padding: 16px;
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;

  &--active,
  &:hover {
    background: var(--soft-green);
    cursor: pointer;
    text-decoration: none;
  }
}

.list-item__head {
  display: flex;
  min-width: 0;
  width: 100%;
}

.list-item__info {
  min-width: 0;
  width: 100%;
}

.list-item__avatar {
  margin-right: 16px;
  min-width: 48px;
}

.list-item__note {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.list-item__unread-count {
  border-radius: 50px;
  background-color: var(--info-2);
  color: var(--white);
  padding: 0px 9px;
  text-align: center;
  white-space: nowrap;
  align-self: flex-start;
}

.list--no-items {
  color: #6b7194;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  padding: 20px;
}

.list {
  display: flex;
  flex-direction: column;

  gap: 8px;

  padding: 0px 0.5rem;

  overflow: auto;
}
