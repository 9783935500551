@use "/scss/mixins";

.messenger {
  // dictated by the HEADER_HEIGHT variable
  --navbar-height: 72px;
  --padding-block: 1.5rem;

  display: flex;
  max-width: 1312px;

  width: 100%;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;

  height: calc(100vh - var(--navbar-height) - 2 * var(--padding-block));

  @include mixins.breakpoint(md) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
