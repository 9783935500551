@use "/scss/mixins";

.job-messenger {
  position: relative;
  display: flex;

  justify-content: flex-start;

  min-height: 352px;
  max-height: calc(100vh - 200px);

  @include mixins.breakpoint(sm) {
    max-height: calc(100vh - 360px);
  }
}
