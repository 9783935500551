.fee-field {
  &-checkbox {
    width: 70% !important;
    display: inline-block;
  }

  &-value {
    width: 30% !important;
    vertical-align: 4px !important;
  }
}
