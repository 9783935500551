@use "/scss/mixins";

.payment-methods-list {
  &-item {
    --itemPadding: 8px;

    margin-bottom: 30px;
    background-color: var(--white) !important;
    border: 1px solid var(--soft-grey);
    border-radius: 0 !important;
    padding: var(--itemPadding);
    position: relative;

    display: flex;
    flex-direction: column;
    min-width: 0;

    @include mixins.breakpoint("sm") {
      --itemPadding: 30px;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-vendor-logo {
      max-height: 50px;
    }

    &-details {
      margin: 30px 0;
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 16px;

      @include mixins.breakpoint("sm") {
        flex-wrap: nowrap;
      }

      &-item {
        &-warning {
          color: var(--negative-1);
          text-align: center;
        }

        &-name {
          margin-bottom: 10px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-default-button {
      align-self: flex-end;
      padding: 4px 15px !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
