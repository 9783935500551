.modal-slider-content {
  &-prev,
  &-next {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    font-size: 36px !important;
    color: #fff;
    padding: 7px;
  }

  &-prev {
    left: -57px;
  }

  &-next {
    right: -57px;
  }
}
