@use "/scss/mixins";

.job-management-page {
  :global(.overwrite-layout-content) {
    display: flex;
    justify-content: center;
    padding: 0 16px;
    padding-top: 14px;
    padding-bottom: 35px;

    @include mixins.breakpoint(sm) {
      padding: 0 40px;
      padding-top: 24px;
      padding-bottom: 80px;
    }
  }

  &__content {
    width: 100%;
    max-width: 1120px;
  }
}
