.boxmodal {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  min-height: 20vh;

  @media (min-width: 1100px) {
    width: 1280px;
  }
}

.left_main_container {
  background-color: #f5f8ff;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;

  @media (max-width: 767px) {
    padding: 0;
  }
}

.right_main_container {
  min-height: 200px;
  background-color: #e8f2fc;
  width: 100%;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.banner_img {
  width: 100%;
}

.link {
  color: #598ed2;
}
