@use "/scss/mixins";

.attachments-preview-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  &--grid {
    display: grid;
    max-width: 337px; // smaller card max-width
    grid-template-columns: minmax(0, 1fr);
    grid-auto-rows: 1fr;

    @include mixins.breakpoint(sm) {
      max-width: calc(402px * 2 + 8px); // two cards max-width plus gap
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
  }

  &:not(:first-child) {
    :global(.post-attachment) {
      margin-top: 8px;
    }
  }

  &--single-attachment {
    :global(.post-attachment--link) {
      max-width: 260px;
      text-decoration: none;
      width: 100%;
      background-color: var(--off-white);
      gap: 16px;
      height: 96px;

      :global(.attachment-link__title) {
        font-size: 16px;
        font-weight: 500;
      }

      :global(.attachment-link__link) {
        color: var(--grey-1);
        text-decoration: none;
      }
    }
  }
}
