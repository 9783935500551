@use "/scss/mixins";

.bid-interview-modal {
  --max-w: 1250px;
  --max-h: 650px;
  --padding-xs: 16px;
  --padding-s: 32px;
  --padding-m: 64px;
  width: min(100vw - 2rem, var(--max-w));
  height: min(100vh - 2rem, var(--max-h));

  @include mixins.breakpoint(md) {
    width: min(100vw - 4rem, var(--max-w));
    height: min(100vh - 4rem, var(--max-h));
  }

  .modal-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    height: min(100vh - 1rem, var(--max-h));

    @include mixins.breakpoint(md) {
      flex-direction: row;
    }
  }

  .modal-content-left {
    background-color: var(--off-white);
    padding: var(--padding-s);
    flex: 0.65;
    display: flex;
    flex-direction: column;
  }
  .modal-content-right {
    flex: 0.35;
    display: flex;
    flex-direction: column;
    padding: var(--padding-m) 0 var(--padding-s) var(--padding-s);
    border-left: 1px solid var(--soft-beige);
  }

  .scrollable-content {
    overflow-y: auto;
    $padding-total: calc(var(--padding-s) + var(--padding-m) + 50px);
    max-height: calc(var(--max-h) - $padding-total);
    padding-right: var(--padding-s);
  }

  .button {
    border-radius: 8px;
    margin-top: 2px;
  }

  .video {
    width: 100%;
    $max-height: calc(var(--max-h) - 140px);
    height: min(80vh - 2rem, $max-height);
    border-radius: 16px;
    display: flex;
    align-items: center;
  }
}
