@use "/scss/mixins";

.review-card {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--white);

  @include mixins.breakpoint(sm) {
    flex-direction: row;
    padding: 24px 0px;
  }

  &__review {
    width: 100%;
  }
}
