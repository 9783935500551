.reviews-summary {
  min-width: 220px;
  padding: 5px 0;

  &:first-of-type {
    margin-right: 30px;
  }

  &__group {
    display: flex;
    align-items: center;
  }

  &__value {
    color: var(--dark-orange);
  }

  &__stars {
    margin-bottom: 3px;
    margin-left: 5px;
  }

  &__count {
    margin-left: 5px;
  }

  &__label {
    margin-top: 5px;
  }
}
