span.badge {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: var(--negative-1);
  background-color: var(--medium-red);
  border-radius: 20px;
  padding: 0 8px;
  margin-right: 8px;
}
