.create-job-item {
  border-radius: 16px;
  border: 1px solid var(--grey-4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &:hover {
    background-color: var(--soft-violet);
    border-color: var(--medium-violet);
  }
}

.job-item-icon {
  height: 56px;
  width: 56px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.custom-job {
  background: var(--blue);
}

.ai-job {
  background: linear-gradient(
    133.45deg,
    var(--blue) 16.52%,
    #e5d7fb 55.86%,
    #fff5e3 78.27%,
    #fffdbd 97.72%
  );
}

.upload-job {
  background: var(--medium-green);
}
