.creating-wallet-modal {
  background-color: var(--medium-green);
  max-width: 524px;
  width: 100%;
  padding: 62px 0;

  &__loader-wrapper {
    display: flex;
    justify-content: center;
    background-color: rgba(247, 252, 236, 0.5);
    padding: 16px;
    border-radius: 100%;
    width: fit-content;
    color: #49b989;

    & :global(.loader-container) {
      box-shadow: inset 0 0 0 6px rgba(59, 59, 59, 0.32);
      border-radius: 50%;
    }
  }
}
