.listing-page {
  width: 100%;
  position: relative;
  max-width: 1120px;

  &__content {
    position: relative;
    min-height: 220px;
  }
}
