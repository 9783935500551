.avatars {
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (min-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }

  &__logo {
    @media (max-width: 600px) {
      align-self: center;

      img {
        height: 24px;
        width: fit-content;
      }
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;

  &__user-group {
    align-items: center;
  }
}

.group {
  &--verified {
    display: flex;
    align-items: center;

    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid var(--grey-4);

    @media (max-width: 600px) {
      margin-right: 12px;
      padding-right: 12px;
    }
  }

  &__avatar {
    width: 28px;
    height: 28px;
    font-size: 16px;
    border: 2px solid var(--white);

    @media (max-width: 600px) {
      width: 24px;
      height: 24px;
    }
  }
}

.badge {
  position: relative;
  left: -5px;
  padding: 2px 8px;
  background-color: var(--positive-3);
  color: var(--positive-1);
  border: 2px solid var(--white);
  font-size: 13px;
  line-height: 24px;
  border-radius: 35px;
}
