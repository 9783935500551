@use "/scss/mixins";

.job-listing-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &__top-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;
    margin-bottom: 40px;
    gap: 24px;

    @include mixins.breakpoint(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__buttons {
    display: flex;
    gap: 8px;
    flex-shrink: 0;
  }

  &__search-section {
    display: flex;
    flex-direction: column;
  }
}
