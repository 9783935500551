@use "/scss/mixins";

.breadcrumbs {
  list-style: none;
  padding: 0;
  font-size: 12px;

  &--list {
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint(650px) {
      flex-direction: row;
    }
  }

  &__crumb-inner {
    white-space: nowrap;
    display: inline-block;
  }

  li {
    display: inline;
    cursor: pointer;

    :global(.crumb) {
      text-decoration: none;
      text-transform: uppercase;
      color: var(--white);
      position: relative;
      font-size: inherit;

      &:global(.link)::after {
        content: "";
        width: 0;
        height: 2px;
        background-color: var(--white);
        position: absolute;
        left: 50%;
        bottom: -3px;
        transform: translateX(-50%);
        transition: width 0.2s;
      }

      &:global(.link):hover::after {
        content: "";
        width: 100%;
        transition: width 0.3s;
      }
    }

    .separator {
      font-size: 18px;
      color: var(--white);
      margin: 0 12px;
      vertical-align: -4px;

      &.separator-hidden {
        margin: 0;
      }

      &:global(.disabled) {
        color: var(--white);
      }

      @include mixins.breakpoint(sm) {
        &.separator-hidden {
          margin: 0 12px;
        }
      }
    }

    &:global(.disabled) {
      opacity: 1;
      pointer-events: none;

      :global(.crumb),
      :global(.link) {
        opacity: 0.8;
        color: var(--white);
      }
    }

    &.force-active-pointer-events {
      pointer-events: all;
    }

    :global(.crumb.link.is-active) {
      font-weight: 500;
      opacity: 1;
    }
  }
}
