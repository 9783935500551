.report-body {
  &__desc {
    border-bottom: 1px solid var(--soft-beige);
    padding-bottom: 32px;
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
  }
  &__sub-title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__icon {
    display: flex;
    flex-shrink: 0;
    margin-right: 24px;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    background-color: var(--medium-yellow);
    border-radius: 50%;
  }
}
