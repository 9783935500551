.sidebar {
  --padding: 16px;
  overflow-y: auto;

  padding: var(--padding);
  /* Height is adjusted to changes in visibility of completion nudge top bar */
  transition: height ease 100ms;

  &::-webkit-scrollbar {
    width: 0.5em;
    background: inherit;
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: var(--grey-1);
  }
}
