@use "/scss/mixins";
@use "../placeholders.module.scss";

$oldHeaderHeight: 72px;

.welcome-layout {
  $containerMargin: 80px;

  display: flex;
  flex-direction: column;

  .logo {
    display: block;
    text-align: center;
    margin-bottom: 35px;
  }

  &-header {
    box-shadow:
      0 0 5px 0 rgba(14, 14, 48, 0.05),
      0 0 30px 0 rgba(14, 14, 48, 0.05);

    &-content {
      @extend %wrapper;
      font-size: 0;
      display: flex;
      align-items: start;
      justify-content: space-between;
      min-height: 71px;

      :global(.breadcrumbs) {
        flex-grow: 1;
        margin-left: 15px;
        align-self: center;
      }

      :global(.buttons) {
        margin-left: 16px;
        margin-right: 16px;
        align-self: center;
      }

      @include mixins.breakpoint(xs) {
        :global(.breadcrumbs) {
          margin-left: 20px;
        }
      }
    }
  }

  &-container {
    @extend %horizontal-scroll;
  }

  &-content {
    padding-right: 16px;
    padding-left: 16px;
    width: 100%;
    margin: $containerMargin auto;

    @include mixins.breakpoint(sm) {
      padding-right: 32px;
      padding-left: 32px;
    }

    @include mixins.breakpoint(1250px) {
      width: 1234px;
    }
  }

  &.with-fixed-header {
    .welcome-layout-header {
      @extend %app-header;
      min-height: $oldHeaderHeight;
    }

    .welcome-layout-content {
      margin-top: $containerMargin + 80px;

      @include mixins.breakpoint(sm) {
        margin-top: $containerMargin + 71px;
      }
    }
  }
}
