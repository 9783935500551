.text-field {
  position: relative;

  &__content {
    visibility: hidden;
    min-width: max-content;
    font-size: 16px;
    white-space: nowrap;
    position: absolute;
    white-space: pre-line;
    font-family: var(--primaryFontFamily);
  }
}
