@use "/scss/mixins";

.right-panel-element {
  display: flex;
  flex-direction: column;
  padding: 32px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 16px;
  height: fit-content;
  width: 100%;
  gap: 16px;
}
