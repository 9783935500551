button.save-job-icon-button {
  padding: 8px;

  > svg {
    width: 18px;
    height: 18px;

    fill: none;

    &.save-job-icon-button--active {
      fill: var(--positive-2);
    }
  }
}
