.container {
  width: 100%;
  min-height: 40px;
  flex-wrap: wrap;
  display: flex;
  position: relative;
  padding: 8px 0;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--soft-beige);

  flex-shrink: 0;
  max-height: 160px;

  overflow: auto;
}
