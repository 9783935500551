@use "/scss/mixins";

.emoji-box {
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid var(--soft-beige);
  padding: 0 12px;
  height: 48px;

  &__icon {
    padding: 5px;

    &--selected {
      opacity: 1 !important;
    }

    &--any-selected {
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.bid-filters__actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  row-gap: 20px;

  @include mixins.breakpoint(sm) {
    align-items: flex-start;
  }
}

.bid-filters__form {
  display: flex;
  width: calc(100% - 56px);
}

.bid-filters__content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 16px;
  column-gap: 8px;

  @include mixins.breakpoint(sm) {
    flex-direction: row;

    & > * {
      width: fit-content;
    }
  }
}

.bid-filters__sort-btn {
  margin-left: 0;
  width: 100%;

  @include mixins.breakpoint(sm) {
    margin-left: auto;
    width: fit-content;
  }
}

.bid-filters-bar {
  background-color: var(--off-white);
  padding: 16px;
}

.bid-filters__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
