@use "/scss/mixins";

.add-member {
  background-color: var(--soft-violet);
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &__icon {
    width: 9.75px;
    height: 9.75px;

    @include mixins.breakpoint(sm) {
      width: 22.75px;
      height: 22.75px;
    }
  }
}
