@use "/scss/mixins";

.reply-field {
  flex: 1;
  height: 100%;
  position: relative;

  min-height: 48px;

  @include mixins.breakpoint(sm) {
    min-height: 58px;
  }
}

.input,
.placeholder {
  padding-block: 11px;
  padding-inline: 8px 0;

  @include mixins.breakpoint(sm) {
    padding-block: 16px;
  }
}

.placeholder {
  display: inline-block;
}

.helper-text-wrapper {
  padding-left: 8px;

  :global(.editor-character-indicator) {
    margin-top: -16px;
  }
}

.input {
  height: 100%;
}

.error {
  padding-bottom: 8px;
  margin-top: 0px;

  @include mixins.breakpoint(sm) {
    padding-bottom: 0;
  }
}
