@use "/scss/mixins";

.footer {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-edit {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include mixins.breakpoint(sm) {
    flex-direction: row;
  }
}
