@use "/scss/mixins";

.navigation-bar {
  gap: 8px 16px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;

  @include mixins.breakpoint(800px) {
    grid-template-columns: repeat(3, auto);
    align-items: center;
  }
}
