.interacts-section {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: -4px;

  &__reactions {
    margin-top: 8px;
  }
}
