@use "/scss/mixins";

.review-drafts-modal {
  width: 95%;
  margin-inline: auto;
  max-width: 1000px;
}

.review-drafts-modal-content {
  max-width: 1000px;
  min-height: 733px;
  display: flex;
  flex-direction: column;
  padding: 24px 28px;
  row-gap: 12px;

  @include mixins.breakpoint(sm) {
    padding: 48px 56px;
    row-gap: 24px;
  }
}

.posted-job-successfully-wrapper {
  width: 100%;
  max-width: fit-content;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: var(--soft-green);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.posted-job-successfully-wrapper__message {
  display: flex;
  align-items: center;
  gap: 4px;
}
