@use "/scss/mixins";

@media (max-width: 600px) {
  .bookmarks-page {
    padding-inline: 0px !important;
  }
}

.bookmark-tab {
  padding: 0;
  margin: 0;
}
