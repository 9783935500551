.modal-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-end;
}

.help-offer {
  border-radius: 16px;
  border: 1px solid var(--grey-4);
  padding: 24px;
}
