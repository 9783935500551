@use "/scss/mixins";

@mixin job-details-page-media-query($breakpointMD, $breakpointXL) {
  @include mixins.breakpoint($breakpointMD) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    grid-template-areas:
      "basic-details basic-details"
      "warning-badge warning-badge"
      "top-skills seniority"
      "rate status";

    & > * {
      margin-top: 24px;
    }

    & > .job__basic-details {
      margin-top: 0;
    }

    & > .job__rate {
      margin-top: 24px;
    }
  }

  @include mixins.breakpoint($breakpointXL) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "basic-details basic-details basic-details basic-details"
      "warning-badge warning-badge warning-badge warning-badge"
      "top-skills seniority rate status";
  }
}

.job {
  display: block;

  &:global(.overwrite-layout-content) {
    padding-top: 48px;
  }

  &__details,
  &__details-public {
    display: flex;
    flex-direction: column;

    & > * {
      margin-top: 16px;
    }

    & > .job__status {
      margin-top: 0px;
    }
  }

  &__details {
    @include job-details-page-media-query(md, xl);

    &--without-seniority {
      @include mixins.breakpoint(md) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          "basic-details basic-details"
          "warning-badge warning-badge"
          "top-skills top-skills"
          "rate status";
      }

      @include mixins.breakpoint(xl) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
          "basic-details basic-details basic-details basic-details"
          "warning-badge warning-badge warning-badge warning-badge"
          "top-skills rate status status";
      }
    }
  }

  &__details-byot {
    @include mixins.breakpoint(md) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        "basic-details basic-details"
        "rate status"
        "employer-column employer-column";
    }

    @include mixins.breakpoint(lg) {
      grid-template-rows: repeat(3, min-content);
      grid-template-columns: 1fr minmax(240px, 25%) 1fr;
      grid-template-areas:
        "basic-details basic-details basic-details"
        "rate status employer-column";
    }

    &__employer-column {
      grid-area: employer-column;

      @include mixins.breakpoint(lg) {
        grid-row: 2 / 4;
      }
    }
  }

  &__skill-chip {
    max-width: max-content;
    height: fit-content;
  }

  &__basic-details {
    grid-area: basic-details;
  }

  &__warning-badge {
    grid-area: warning-badge;
    margin-top: 0;

    & > div {
      margin-top: 24px;
    }
  }

  &__basic-details-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @include mixins.breakpoint(1200px) {
      justify-content: space-between;
    }
  }

  &__top-skills {
    grid-area: top-skills;
  }

  &__seniority {
    grid-area: seniority;

    & *:not(:first-child) {
      margin-top: 0;
    }
  }

  &__rate {
    grid-area: rate;
  }

  &__status-box {
    grid-area: status;
    height: auto;

    &--desktop {
      display: none;

      @include mixins.breakpoint(md) {
        display: block;
      }
    }
  }

  &__status {
    display: flex;
  }

  &__mobile-refer {
    display: block;
    margin-top: 32px;

    @include mixins.breakpoint(md) {
      display: none;
    }
  }

  &__content {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;

    @include mixins.breakpoint(lg) {
      margin-top: 32px;
      grid-template-columns: 5fr 3fr;
    }
  }

  &__employer-column {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-radius: 16px;
  }

  &__explainer-wrapper {
    background-color: var(--white);
    border-radius: 16px;
  }

  &__matchers-listing {
    *:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &__refer {
    display: none;

    @include mixins.breakpoint(md) {
      display: block;
    }
  }

  &__bottom-rate {
    display: block;

    @include mixins.breakpoint(md) {
      display: none;
    }
  }

  &__bottom-status-box {
    margin-top: 24px;
    display: none;

    @include mixins.breakpoint(md) {
      display: flex;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @include mixins.breakpoint(460px) {
      flex-direction: row;
    }

    @include mixins.breakpoint(660px) {
      justify-content: flex-end;
      margin-bottom: -60px;
    }
  }

  &__similar-jobs {
    margin-top: 104px;
  }
}
