@use "/scss/mixins";

.feed-header-details {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  width: 100%;
  overflow: hidden;

  @include mixins.breakpoint(sm) {
    align-items: center;
    flex-direction: row;
  }

  &--comment {
    flex-direction: row;
    gap: 4px;

    flex: 1;
  }

  &__role-timestamp {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    max-width: 100%;
    min-width: 0;
    flex-shrink: 0;

    @include mixins.breakpoint(sm) {
      gap: 0;
      flex-shrink: 1;
      min-width: 30%;
    }

    @include mixins.breakpoint(md) {
      min-width: 40%;
    }
  }

  &__dot-separator {
    color: var(--grey-2);
    font-weight: 500;
    margin-inline: 4px;
  }
}

.post-details-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  &__avatar {
    display: block !important;
    margin-right: 16px;
    padding: 0 !important;
    min-width: 0 !important;
  }
}
