@use "/scss/mixins";

.container {
  display: grid;
  grid-template-columns: auto;
  gap: 16px;

  @include mixins.breakpoint(1200px) {
    display: grid;
    grid-template-columns: minmax(10px, 1fr) minmax(10px, 2fr);
    gap: 32px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
