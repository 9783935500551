.bookmarks-listing {
  max-width: 1120px;
  height: 100%;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    min-height: 220px;
  }

  .jobs-listing {
    margin-bottom: 0;

    &-jobs-list {
      margin-bottom: 0;
    }
  }
}
