@use "/scss/mixins";

.interview_question_header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;

  & > * {
    width: fit-content;
  }

  @media (min-width: 685px) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1320px;
    margin: auto;
  }
}
