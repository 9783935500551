@use "/scss/mixins";

.post-attachment__container {
  max-height: 400px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

:global(.lightbox-content__wrapper) {
  .post-attachment__container {
    max-height: calc(100vh - 128px);
    overflow-y: auto;
    border-radius: 4px;
  }
}

.post-attachment {
  max-width: 225px;
  width: 100%;
  border-radius: 16px;
  position: relative;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  height: 96px;
  &--image {
    background-color: var(--off-white);
    & > div:first-child {
      border-radius: 4px;
    }
  }
  &--file {
    background-color: var(--off-white);
  }

  :global(.typography-variant--label) {
    font-weight: 500;
  }
}

.default-attachment-link {
  &__title {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__link {
    font-size: 13px !important;
  }
}

.pretty-link-card {
  &__container {
    width: 100%;
    max-width: 337px;
    height: 100%;
    border: 1px solid var(--soft-grey);
    border-radius: 16px;
    padding: 0 0 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:hover {
      box-shadow: var(--elevation-4);
      text-decoration: none;
    }

    &--with-full-padding {
      padding: 24px;
    }

    @include mixins.breakpoint(sm) {
      max-width: 402px;
    }
  }

  &__avatar-container {
    position: relative;

    & svg,
    & rect {
      width: 100%;
      height: 48px;
      border: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  &__avatar-background {
    position: absolute;
  }

  &__avatar {
    width: 48px;
    height: 48px;
    left: 24px;
    top: 24px;
  }

  &__introduction {
    margin-top: 32px;
    padding: 0 24px;

    & svg,
    & rect {
      width: 16px;
      height: 16px;
    }
  }

  &__location {
    width: 100%;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    gap: 8px;

    &-icon {
      width: 16px;
      height: 16px;
    }
  }

  &__logo-and-job-type {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__boxed-employer-logo {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    padding: 8px;

    :global(.employer-logo-wrapper) {
      width: 100%;
      height: 100%;
    }
  }

  &__title-and-budget {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__title {
    width: 100%;
  }

  &__role {
    overflow: hidden;

    .role-tag {
      justify-content: flex-start;
    }
  }
}

.skill-chip {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
