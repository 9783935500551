@use "/scss/mixins";

.requirement-card-button {
  width: 100%;
  padding: 18px 24px;
  max-width: 495px;
  box-shadow:
    0px 2px 4px rgba(20, 20, 20, 0.04),
    0px 4px 16px rgba(20, 20, 20, 0.04);
  @include mixins.transition();
}
