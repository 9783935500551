.wrapper {
  display: flex;
  grid-area: header;
  margin: 16px 0 0 8px;
  line-height: 1.2;

  a {
    line-height: inherit !important;
  }
}

.title {
  word-break: break-all;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
