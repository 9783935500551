.basic-accordion {
  background-color: transparent;
  box-shadow: none;
  &::before {
    display: none;
  }
  &__icon {
    font-size: 13px;
    font-weight: 500;
  }
  &__title,
  &__body {
    padding: 0;
  }
  &__title {
    margin-top: -5px;
    justify-content: flex-start;
    gap: 5px;
    .MuiAccordionSummary-content {
      flex-grow: unset;
    }
  }
}
