@use "/scss/mixins";

.container {
  display: grid;
  gap: 16px;

  @include mixins.breakpoint(md) {
    grid-template-columns: minmax(10px, 2fr) minmax(10px, 1fr);
    gap: 28px;
  }
}
