.textarea {
  position: relative;

  textarea {
    resize: vertical;
  }

  &__helper-text {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
  }

  &__link {
    margin-left: 6px;
  }

  &__max-length {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    color: var(--grey-2);

    &--static {
      position: static;
      flex: 1;
      text-align: end;
      margin: 0;
    }

    &--error {
      color: var(--negative-1);
    }
  }

  &__helper-text-wrapper {
    display: flex;
  }
}
