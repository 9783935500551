@use "/scss/mixins";

.role-field {
  margin-bottom: 48px;

  :global {
    *:has(> .role-field-button) {
      display: flex;
      gap: 8px;
      align-items: baseline;
      flex-direction: column;

      @include mixins.breakpoint(400px) {
        flex-direction: row;
      }
    }
  }
}

.other-role-field {
  width: 100%;
  max-width: 300px;
}
