@use "/scss/mixins";

.hour-selector {
  width: 100%;
  max-width: 120px;

  @include mixins.breakpoint(sm) {
    max-width: 150px;
  }
}
