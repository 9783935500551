@use "/scss/mixins";

.interview_question_form {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1210px;
  margin: auto;
  padding: 30px;
  gap: 24px;

  &_left_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 360px;
  }

  &_right_container {
    width: 70%;
    @media (min-width: 1200px) {
      width: 770px;
    }
  }

  @media (max-width: 850px) {
    flex-direction: column;
    padding: 20px;

    &_right_container,
    &_left_container {
      width: 100%;
      max-width: 100%;
    }
  }
}
