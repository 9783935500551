@use "/scss/mixins";

.member-list-item {
  width: 100%;

  @include mixins.breakpoint(sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__remove {
    cursor: pointer;
    background: none;
    border: none;
    text-decoration: underline;
    min-width: fit-content;

    @include mixins.breakpoint(sm) {
      padding-inline: 8px;
    }
  }
}

.member-list {
  align-self: start;
  list-style: none;
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  gap: 16px;
  overflow: auto;

  @include mixins.breakpoint(sm) {
    gap: 24px;
  }
}
