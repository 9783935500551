@use "/scss/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--white);
  border: 1px solid var(--grey-4);
  padding: 24px;
  border-radius: 16px;
  overflow: hidden;

  @include mixins.breakpoint(sm) {
    padding: 32px;
  }
}

.btn {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  padding: 12px;
  overflow: hidden;

  svg {
    font-size: 48px;
  }
}

.item {
  border-radius: 16px;
  height: 72px;
  padding: 0;
  background-color: var(--off-white);
}
