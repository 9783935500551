.error-boundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 24px;

  &__logo {
    height: 45px;
  }

  &__title {
    font-size: 28px;
    line-height: 1.4;
  }

  &__button {
    background: var(--white);
    border-radius: 50px;
    font-size: 16px;
    border: 2px solid var(--black);
    padding: 10px 20px;
    cursor: pointer;
  }
}
