@use "/scss/mixins";

.avatar-grid-graphic {
  &_wrapper {
    background-color: var(--medium-teal);
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    align-items: center;
    position: relative;
    height: 217px;
  }

  &_browser-window {
    background-color: var(--white);
    padding: 8px;
    border-radius: 12px;
    position: relative;

    width: 174px;
    height: 168px;
  }

  &_browser-column {
    display: flex;
    flex-direction: column;
    gap: 9px;

    &:first-of-type {
      justify-content: end;
    }
  }

  &_video-outline-icon {
    path {
      stroke: var(--dark-violet);
    }
  }

  &_avatar--medium {
    width: 68px;
    height: 68px;
    border-radius: 8px;
    object-fit: contain;
  }

  &_avatar--big {
    width: 79px;
    height: 79px;
    border-radius: 8px;
    object-fit: contain;
  }

  &_avatar--emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 79px;
    text-align: center;
    background-color: var(--medium-yellow);
  }

  &_decoration--square {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: grid;
    place-content: center;
    font-size: 24px;
  }

  &_decoration-emoji {
    line-height: 2;
  }

  &_decoration--rectangle {
    width: 68px;
    height: 45px;
    border-radius: 8px;
    background-color: var(--medium-green);
    display: grid;
    place-content: center;
  }

  &_talent-name {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 0 4px;

    &--text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 65px;

      @include mixins.breakpoint(450px) {
        max-width: 90px;
      }
      @include mixins.breakpoint(540px) {
        max-width: 130px;
      }
      @include mixins.breakpoint(640px) {
        max-width: 160px;
      }
    }
  }
}
