.tabs {
  & :global(.MuiTabs-scroller) {
    height: 50px;
  }

  & :global(.MuiTabs-flexContainer) {
    height: 100%;
  }
}

.tab {
  font-weight: 400;
  font-size: 20px;
  padding-top: 0;
  padding-bottom: 8px;
  justify-content: flex-end;
}
