.job-interview {
  background-color: #f9f6ff;
  display: flex;
  flex-direction: column;

  & > form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__header {
    padding: 0px;
  }
  &__content {
    display: flex;
    flex: 1 1 auto;
  }
}
