@use "/scss/mixins";

.buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  @include mixins.breakpoint(sm) {
    gap: 16px;
  }

  & :global(.tile-button) {
    padding: 21px 20px;
    overflow: hidden;
    width: fit-content;
  }
}
