@use "/scss/mixins";

.messages-box {
  position: relative;
  overflow-y: auto;
  margin-bottom: 24px;
  height: 100%;
  flex: 1;

  &__no-messages {
    text-align: center;
    color: var(--grey-2);
    position: absolute;
    top: 50%;
    width: 100%;

    transform: translateY(-50%);
  }

  &__header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  &__info-wrapper {
    display: flex;
    column-gap: 0.5rem;
    min-width: 0;
    flex-wrap: wrap;
    width: 100%;

    @include mixins.breakpoint(lg) {
      flex-wrap: nowrap;
    }
  }

  &__location-box {
    display: flex;
    gap: 0.5rem;
    width: fit-content;

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 13px;
      width: max-content;
    }
  }

  &__back-icon {
    margin-right: 8px;
  }

  &__avatar {
    margin-right: 16px;
  }

  &__title {
    margin-right: auto;
    min-width: 150px;

    &:hover {
      text-decoration: none;
    }
  }
}
