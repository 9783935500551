button.button {
  text-decoration: underline;
  position: relative;
  font-size: inherit;
  line-height: inherit;

  &:hover {
    text-decoration: underline;
  }

  &.button--more {
    vertical-align: baseline;
  }

  &.button--less {
    margin-top: 12px;
    display: block;
  }
}
