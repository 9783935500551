@use "/scss/mixins";

.project-item-description {
  position: relative;
  height: auto;
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--grey-1);

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(
      to bottom,
      transparent,
      var(--project-item-gradient)
    );
  }
}

.work-sample-item {
  min-width: 100%;
  max-width: 1200px;
  margin-top: -8px;

  @include mixins.breakpoint(sm) {
    min-width: 500px;
  }

  &-nav {
    margin-right: 32px;
    margin-bottom: 32px;
    justify-content: flex-end;

    &-button {
      margin-right: 10px !important;
    }
  }

  &-completion-date {
    margin-top: 16px;
  }

  &-right {
    text-align: right;
  }

  &-images {
    margin-top: 30px !important;
    text-align: center;

    &-image {
      margin: 0 -30px 30px -30px;
    }
  }
}
