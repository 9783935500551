@use "/scss/mixins";

.public-space-page {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1148px;
}

.header {
  margin-bottom: 16px;

  @include mixins.breakpoint(sm) {
    margin-bottom: 24px;
  }
}

.public-space-page-content {
  display: grid;
  gap: 16px;
  column-gap: 32px;

  &__admins,
  &__about,
  &__members,
  &__trending {
    grid-column: 1 / -1;
    display: block;
  }

  &__members-content {
    border-radius: 16px;
    border: 1px solid var(--grey-4);
    background-color: var(--white);
    padding: 24px;

    @include mixins.breakpoint(sm) {
      padding: 32px;
    }
  }

  @include mixins.breakpoint(sm) {
    grid-template-columns: 361px minmax(10px, 1fr);
    grid-template-areas:
      "admins about"
      "members about"
      "trending about"
      "- about"; // do not stretch left column vertically

    &__admins {
      grid-area: admins;
    }

    &__members {
      grid-area: members;
    }

    &__trending {
      grid-area: trending;
    }

    &__about {
      grid-area: about;
    }
  }
}

.btn-guest {
  padding: 6px 20px !important;
  white-space: nowrap;
}
