@use "/scss/mixins";

.create-post {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: var(--white);
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid var(--grey-4);
  box-shadow:
    0px 4px 8px rgba(20, 20, 20, 0.04),
    0px 8px 24px rgba(20, 20, 20, 0.04);

  @include mixins.breakpoint(sm) {
    padding: 24px;
  }
}

.posts {
  --px: 16px;

  margin-top: -16px;
  margin-left: calc(-1 * var(--px));
  width: calc(100% + 2 * var(--px));

  @include mixins.breakpoint(sm) {
    width: auto;
    margin-left: revert;
  }
}

.fab-container {
  max-width: 1312px;
}
