.interview-message-modal {
  background-color: var(--soft-violet);
  border: 1px solid var(--medium-violet);
  max-width: 680px;
  width: 100%;
}

.make-offer-message-modal {
  background-color: var(--soft-green);
  border: 1px solid var(--medium-green);
}
