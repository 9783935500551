.action-bar {
  @media (max-width: 1360px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  &__buttons-wrapper {
    flex-wrap: wrap;
    gap: 8px;
  }
}
