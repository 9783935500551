@use "/scss/mixins";

.hub-spot-container {
  max-width: 730px;
  max-height: 680px;
  width: 100%;
  height: 100%;
}

.hub-spot-cts {
  display: flex;
  width: 100%;
  margin-top: 24px;

  @include mixins.breakpoint(md) {
    margin-top: 32px;
  }
}

.hub-spot-button {
  margin-left: auto;
  margin-right: 16px;
}
