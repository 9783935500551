.wrapper {
  position: relative;
  display: flex;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 18px;
  justify-content: center;
}

.svg {
  width: 20px;
  margin-right: 8px;
}

.button {
  animation: pulse 3.4s ease-in infinite;
}

@keyframes pulse {
  0% {
    box-shadow:
      0 0 0 0 #f4ebfb,
      0 0 0 0 var(--soft-green),
      0 0 0 0 #e9d7ff;
  }

  13%,
  14% {
    box-shadow:
      0 0 0 9px #f4ebfb,
      0 0 0 17px var(--soft-green),
      0 0 0 18px #e9d7ff;
  }

  22%,
  25% {
    box-shadow:
      0 0 0 8px #f4ebfb,
      0 0 0 16px var(--soft-green),
      0 0 0 17px #e9d7ff;
  }

  33% {
    box-shadow:
      0 0 0 14px transparent,
      0 0 0 23px var(--soft-green),
      0 0 0 24px transparent;
  }

  34%,
  100% {
    box-shadow: 0 0 0 transparent;
  }
}
