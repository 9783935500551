.header--short {
  padding-block: 5px;

  box-shadow: var(--elevation-3);
}

.page-offset {
  height: 80px;
}

.nav-container {
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 1300px;
  height: 54px;
  margin-inline: auto;
}

.nav-links {
  height: 100%;
  padding: 0;
  margin: 0;

  list-style: none;
}

.nav-link-container {
  position: relative;
  cursor: pointer;

  flex-direction: row;
  align-items: center;

  height: 100%;

  padding: 5px 17px 0;
}

a.nav-link {
  position: relative;
  text-decoration: none !important;

  font-size: 14px;

  &::after {
    content: "";
    position: absolute;

    inset: auto 0 -2px 0;

    height: 2px;

    background-color: var(--black);
    opacity: 0;
  }

  &.nav-link--active,
  &:hover,
  &:focus {
    text-decoration: none;
    &::after {
      opacity: 1;
    }
  }
}

a.nav-sub-link {
  white-space: nowrap;
  height: 100%;

  display: flex;
  align-items: center;

  margin-inline: 20px;

  &::after {
    inset: auto 0 0 0;
  }
}

.arrow-icon {
  position: absolute;
  right: 1px;
  top: calc(50% + 2px);

  color: var(--black);
  font-size: 1rem;

  transform: translateY(-50%);
}

.arrow-icon--up {
  transform: translateY(-50%) rotate(180deg);
}

a.button {
  padding: 5px 19px;

  font-size: 14px;
}

a.button--sign-up {
  line-height: unset;
  &:hover,
  &:focus {
    background-color: var(--black);
    color: var(--white);
  }
}

a.button--demo {
  line-height: unset;
  box-shadow:
    0 0 0 1px var(--black),
    0 0 0 1px var(--black) inset;

  &:hover,
  &:focus {
    background-color: transparent;
    color: var(--black);
  }
}

.logo-container {
  margin-bottom: -2px;
}

.menu {
  display: none;

  cursor: default;

  position: absolute;
  top: 100%;
  left: -100px;

  padding: 0 10px;

  background-color: var(--white);
  border-radius: 6px;
}

.menu--open {
  display: revert;
}

.menu-list {
  display: flex;
  flex-direction: row;

  height: 58px;
  padding: 0;
  margin: 0;

  list-style: none;
}

.menu-item {
  height: 58px;
}
