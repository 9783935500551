.header {
  --scrollbar-width: 20px;
  --black: #0e0e11;

  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - var(--scrollbar-width));

  background-color: var(--soft-blue);

  z-index: 1000;

  padding-block: 15px;

  transition: padding 200ms ease;
}
