@use "/scss/mixins";

.payment-modal {
  --p: 16px;
  position: relative;
  width: min(550px, 100vw - 2rem);
  min-height: 200px;

  padding-block: var(--p);

  @include mixins.breakpoint(sm) {
    --p: 24px;
  }
}

.container {
  & > * {
    padding-inline: var(--p);
  }
}

.close-button {
  top: 8px !important;
  right: 8px !important;

  svg {
    margin: 0;
  }
}

.loader-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2;
}
