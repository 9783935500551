.edit-btn {
  display: flex;
  gap: 16px;
  align-items: center;
}

.actions {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
