.title {
  line-height: 2.5rem;
}

.category-select {
  align-items: flex-start;
}

.editor-wrapper {
  background: var(--white);
  position: relative;
}
