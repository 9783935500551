button.delete-btn {
  position: absolute;
  right: 0;
  top: 0;

  padding: 6px;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
}

.item-wrap {
  position: relative;

  border: 1px solid var(--info-2);
  border-radius: 4px;

  height: 110px;
  padding: 8px;

  touch-action: manipulation;
  list-style: none;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;

    object-fit: cover;
  }
}
