.list {
  li {
    counter-increment: list;
    list-style-type: none;
    position: relative;
    margin-bottom: 24px;

    &:before {
      color: var(--dark-green);
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: var(--medium-green);
      content: counter(list);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      top: -4px;
      left: -42px;
      position: absolute;
    }
  }
}
