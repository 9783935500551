.modal {
  margin: auto 16px auto !important;
  max-width: 600px;
  padding: 32px;
}

.close-button {
  top: 20px !important;
}

.auto-block-warning-content {
  max-width: 100%;
  padding-top: 8px;
}
