.add-withdrawal-method {
  &__card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    form {
      width: 100%;
    }
  }

  &__overlay {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.add-withdrawal-method-modal {
  width: min(560px, 100vw - 2rem);
}
