@use "/scss/mixins";

.accept-offer-confirm-modal {
  max-width: 514px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .accept-offer-confirm-modal__top-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .accept-offer-confirm-modal__receipt {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .accept-offer-confirm-modal__receipt__row {
      display: flex;
      justify-content: space-between;
    }
  }

  .accept-offer-confirm-modal__bottom-section {
    display: flex;
    flex-direction: column;
    gap: 24px;

    a {
      text-decoration: none;
      color: var(--info-2);
    }

    button {
      align-self: end;
    }
  }

  &__otp-disclaimer {
    border-radius: 8px;
    padding: 16px;
    background-color: var(--soft-blue);
  }

  &__authentication {
    border: 1px solid var(--soft-beige);
    padding: 16px;
    border-radius: 8px;

    @include mixins.breakpoint(sm) {
      padding: 24px;
    }
  }
}

.offer-payment-modal-width {
  max-width: calc(100% - 32px);
}
