@use "/scss/mixins";

.modal {
  max-width: 750px;
  width: 96%;
  border-radius: 8px !important;
  padding: 24px !important;

  @include mixins.breakpoint(sm) {
    padding: 32px 32px 24px 32px !important;
  }
}

.close-button {
  height: 42px;
  width: 42px;
  top: 23px;
  right: 23px;

  @include mixins.breakpoint(sm) {
    top: 31px;
    right: 31px;
  }
}
