@use "/scss/mixins";

.reply-form {
  display: flex;
  border: 1px solid var(--grey-4);
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  width: 100%;

  align-items: center;
  flex-direction: row;

  padding-inline: 8px;
}

.reply-form--expanded {
  align-items: stretch;
  flex-direction: column;
  padding-bottom: 8px;
}

.avatar {
  margin-top: 9px;
  @include mixins.breakpoint(sm) {
    margin-top: 6px;
  }
}
