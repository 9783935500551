.live-site-notifications {
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  z-index: 1800;
  max-width: 510px;
  margin: auto;

  @media (max-width: 530px) {
    margin-inline: 10px;
  }

  &__buttons {
    padding: 5px 15px 15px 15px;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.site-notification {
  margin: 0 auto 30px auto;
  box-shadow: 0px 5px 15px 0 rgba(0, 0, 0, 0.6);
  max-width: 500px;
  height: fit-content;
  background-color: #fff;

  &:global(.hiding) {
    transition: all 1s ease-out;
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
  }
}
