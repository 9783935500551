.air-ai-popup {
  position: fixed;
  max-width: 744px;
  background: inherit;
  font-family: var(--primaryFontFamily);

  &__content {
    background: linear-gradient(
      309.05deg,
      #d4e4f9 9.33%,
      #e9d7ff 33.49%,
      #f3dbd9 63.44%,
      #ffe0a9 95.62%,
      #fffdbd 120.29%
    );
  }
  &__info {
    background: var(--white);
    width: 86%;
    padding: 5px;
    border-radius: 15px;
  }
  &__notbold {
    font-weight: normal !important;
  }
  &__custom-stack {
    gap: 0 !important;
  }
}

@media (max-width: 660px) {
  .air-ai-popup {
    width: 90%;
  }
}
