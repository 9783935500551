@use "/scss/mixins";

.testimonials-carousel {
  max-width: 372px;
  --swiper-theme-color: #0e3a78;

  :global(.swiper-pagination) {
    position: static;
    text-align: left;
  }
}

.client-slide {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comment-slide {
  margin-bottom: 20px;

  &__user {
    display: flex;
    gap: 14px;
    margin-top: 30px;
  }

  &__name {
    display: flex;
    flex-direction: column;
  }
}
