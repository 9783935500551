@use "/scss/mixins";

.profile-layout {
  background-color: var(--soft-violet);

  :global(.overwrite-layout-content) {
    padding: 0 1.5rem 60px;
    position: relative;
    min-height: 100%;

    @include mixins.breakpoint(md) {
      padding: 0 3rem 60px;
    }
  }
}

.modal {
  max-width: 740px;
  width: 96%;
}
