@use "/scss/mixins";

.wrapper {
  min-height: 200px;
  position: relative;

  container-name: listings-container;
  container-type: inline-size;

  padding: 24px;
  border-radius: 24px;
  border: 1px solid var(--soft-beige);
  background-color: var(--off-white);

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }

  @include mixins.breakpoint(sm) {
    padding: 38px 32px 32px 32px;
  }
}
