.get-help-preview {
  &__loader {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  &__file {
    max-width: 225px;
    height: 96px;
    border-radius: 16px;
    background-color: var(--medium-violet);
    position: relative;
    padding: 26px 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    white-space: nowrap;
    &--image {
      background-color: var(--medium-yellow);
    }
  }
}
