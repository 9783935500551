@use "/scss/variables";

.tax-popover {
  &__button {
    font-size: 14px !important;

    &:hover {
      cursor: pointer;
    }
  }

  &--has-tax {
    color: variables.$inputLabelColor;
  }

  &__content {
    padding: 30px;
  }

  &__actions {
    margin-top: 40px;
    text-align: right;
  }
}
