@use "/scss/mixins";

.layout {
  display: grid;
  row-gap: 24px;

  @include mixins.breakpoint(sm) {
    grid-template-columns: minmax(10px, 50%) minmax(10px, 50%);
    column-gap: 8px;
  }
}

.table {
  :global(.MuiDataGrid-columnHeader) {
    background-color: var(--off-white);
  }
}
