.avatar-link {
  width: 80px;
  height: 80px;

  flex-shrink: 0;

  &:hover {
    text-decoration: none;
  }
}
