.comment-text {
  white-space: pre-line;
  overflow-wrap: anywhere;

  a {
    color: var(--link);
  }

  p {
    display: inline;

    margin: 0;
  }
}
