@use "/scss/mixins";

.onramp-pending-modal {
  max-width: 514px;
  width: 100%;
  padding: 32px;
  &__main-section {
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: min-content;
    position: relative;
    background-color: var(--soft-green);
    @include mixins.breakpoint(md) {
      padding: 48px;
    }
  }
  &__main-content {
    z-index: 2;
  }
  &__info {
    background-color: var(--soft-grey);
    border-radius: 24px;
    padding: 8px 16px 8px 8px;
    display: flex;
    gap: 10px;
    color: var(--dark-grey);
  }
}
