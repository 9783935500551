.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey-4);
}

.actions {
  display: flex;
  gap: 8px;
}

.role-remove-wrapper {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  &__footer {
    margin-top: 16px;
    float: right;
  }
}

.modal {
  max-width: 740px;
  width: 96%;
}
