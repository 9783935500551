@use "/scss/mixins";

.bg {
  position: absolute;
  top: -64px;
  left: -36px;
}

.new-offer-tile {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 3px solid var(--positive-2);
}

.boosted-container {
  margin-bottom: 32px;

  @include mixins.breakpoint(sm) {
    margin-bottom: 16px;
  }
}
