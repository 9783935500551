@use "/scss/mixins";

.modal-content {
  padding-block: 32px 24px;

  & > * {
    padding-inline: 18px;
  }

  @include mixins.breakpoint(sm) {
    padding-block: 32px 24px;

    & > * {
      padding-inline: 32px;
    }
  }
}

.modal-header {
  --direction: column;
  --alignment: flex-start;
  --gap: 24px;
  display: flex;
  gap: var(--gap);
  flex-direction: var(--direction);
  align-items: var(--alignment);

  @include mixins.breakpoint(685px) {
    --direction: row;
    --alignment: center;
    --gap: 16px;
  }
}

.modal-cta-container {
  align-self: flex-end;
}
