@use "/scss/mixins";

.questions-container {
  position: relative;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 75%;
  justify-content: center;

  margin: 0;
  padding-inline: 0;

  list-style: none;

  @include mixins.breakpoint(sm) {
    grid-auto-columns: max(20rem, 50%);
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: min(20%, 109px);
    height: 100%;

    z-index: 20;
  }

  &::after {
    right: -3px;
    background: linear-gradient(
      to left,
      var(--soft-green) 0%,
      transparent 100%
    );
  }

  &::before {
    left: -3px;
    background: linear-gradient(
      to right,
      var(--soft-green) 0%,
      transparent 100%
    );
  }
}

.question {
  padding: 10px;

  box-shadow: var(--elevation-5);

  border-radius: 16px;

  @include mixins.breakpoint(sm) {
    padding: 16px;
  }
}
