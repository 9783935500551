@use "/scss/mixins";

.job-visibility-setting {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  min-width: 310px;
  @include mixins.transition();
}
