.public-post-signup-banner {
  background-color: var(--medium-green);
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 20;

  &__copy {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__hello {
    border-radius: 100%;
    background-color: white;
    width: 24px;
    height: 24px;
    text-align: center;
  }

  &__cta {
    padding-left: 4px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.btn-guest {
  padding: 6px 20px !important;
  white-space: nowrap;
}
