@use "/scss/mixins";

.comment-offer {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--medium-green);
  border-radius: 8px;
  padding: 8px 8px 8px 16px;

  @container post-wrapper (inline-size <= 620px) {
    flex-wrap: wrap;
    padding: 16px;
    max-width: 214px;
  }

  &__is-comment-author {
    flex-wrap: nowrap;
    max-width: none;
  }

  &__accepted-badge {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: var(--soft-green);
    color: var(--dark-green);

    @include mixins.breakpoint(730px) {
      margin-right: 8px;
    }

    @include mixins.rangeBreakpoint(960px, 1000px) {
      margin-right: 0;
    }

    &__icon {
      width: 16px;
      height: 16px;
    }
  }

  &--declined {
    background-color: var(--soft-beige);
    color: var(--dark-beige);

    .comment-offer__budget {
      color: var(--dark-beige);
    }

    .disabled-button.Mui-disabled {
      background-color: var(--medium-beige);
      color: var(--dark-beige);
    }
  }
}

.revoke-offer-modal {
  width: 96%;
  max-width: 734px;
}

button.delete-button {
  padding: 6px;
}

button.offer-button {
  flex-grow: 0;
  margin-left: auto;
  width: 100%;

  @include mixins.breakpoint(730px) {
    width: initial;
  }

  @include mixins.rangeBreakpoint(960px, 1000px) {
    width: 100%;
  }
}
