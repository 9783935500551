@use "/scss/mixins";

.item-wrapper {
  display: block;
  background-color: var(--white);
  border-radius: 20px;
  border: 1px solid var(--soft-grey);
  padding: 15px;
  position: relative;
  cursor: pointer;
}

.item-wrapper:hover {
  border-color: var(--grey-4);
}

.black-border {
  border-color: var(--black);
}

.green-bg {
  background-color: var(--soft-green);
}

.red-bg {
  background-color: var(--soft-red);
}

.selected.green-bg {
  border-color: var(--black);
  background-color: var(--soft-green);
}

.selected.red-bg {
  border-color: var(--black);
  background-color: var(--soft-red);
}

.selected {
  border-color: var(--black);
  background-color: var(--soft-blue);
}

.top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 12px;
  gap: 8px 32px;

  @include mixins.breakpoint("lg") {
    flex-wrap: nowrap;
  }

  @media only screen and (max-width: 677px) {
    display: flex;
    flex-direction: column;
  }
}

.bid-rating {
  order: 1;

  @include mixins.breakpoint(lg) {
    order: initial;
  }
}
