.payment-method-listing {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.payment-method-info {
  margin-top: 24px;
  padding: 8px 16px;
  background-color: var(--soft-blue);
  border-radius: 8px;
}
