@use "/scss/mixins";

.jobs-listing {
  margin-bottom: 32px;

  &__list {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 15px;
  }

  &__empty-state {
    color: var(--black);
  }
}
