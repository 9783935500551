@font-face {
  font-family: "LocalEmojis";
  src: local("EmojiMart"), local("Apple Color Emoji"), local("Segoe UI Emoji"),
    local("Segoe UI Symbol"), local("Segoe UI"), local("Twemoji Mozilla"),
    local("Noto Color Emoji"), local("Android Emoji");
}

:root {
  --primaryFontFamily: "Graphik", "Helvetica", "Arial", "sans-serif",
    "LocalEmojis";
}

* {
  font-family: var(--primaryFontFamily);
}
