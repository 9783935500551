@use "/scss/mixins";

.modal-wrapper {
  max-width: 1138px;
  width: 96%;
}

.content {
  padding: 16px;

  @include mixins.breakpoint(sm) {
    padding: 48px 56px;
  }
}

.info {
  margin-bottom: 24px;

  @include mixins.breakpoint(sm) {
    margin-bottom: 40px;
  }
}

.summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: start;
  width: 100%;

  @include mixins.breakpoint(md) {
    flex-direction: row;
    gap: 48px;
  }

  &__talent-details {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    max-width: 100%;

    @include mixins.breakpoint(md) {
      max-width: 35%;
    }
  }
}

.ach-info {
  padding: 8px 16px;
  border-radius: 8px;
  max-width: 618px;
  background: var(--soft-blue);
  margin-bottom: 32px;
  width: fit-content;
}
