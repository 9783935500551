.message {
  margin-bottom: 35px;
}

.info {
  max-width: 300px;
  margin: auto;

  a {
    font-size: inherit;
    text-decoration: underline;
  }
}

.image {
  width: 250px;
  margin: 0 auto;
  margin-bottom: 60px;
}
