@use "/scss/mixins";

.stripe-credit-card {
  display: flex;
  justify-content: center;
  gap: 4px;

  @include mixins.breakpoint(sm) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
  }
}
