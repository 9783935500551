@use "/scss/mixins";

.preview-offer {
  display: grid;
  grid-template-columns: minmax(10px, 801px);
  gap: 16px;
  justify-content: center;

  @include mixins.breakpoint(md) {
    grid-template-columns: minmax(10px, 434px) minmax(10px, 801px);
  }

  &__left-col,
  &__right-col {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
