@use "/scss/mixins";

.modal {
  --max-w: 1250px;
  --max-h: 780px;
  width: min(100vw - 2rem, var(--max-w));
  height: min(100vh - 2rem, var(--max-h));

  @include mixins.breakpoint(md) {
    width: min(100vw - 4rem, var(--max-w));
    height: min(100vh - 4rem, var(--max-h));
  }
}
