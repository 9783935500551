@use "/scss/mixins";

.sign-in {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include mixins.breakpoint(md) {
    flex-direction: row;
  }

  &__logo {
    margin-top: 78px;
  }

  &__left {
    display: flex;
    align-items: center;
    flex-direction: column;

    flex-grow: 3;
  }

  &__right {
    display: flex;
    background-color: var(--soft-blue);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 500px;
    min-width: 0;
    flex-grow: 1;

    @include mixins.breakpoint(md) {
      flex-grow: 0;
    }
  }

  &__right-content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
