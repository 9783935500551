.availability-entry-point-button {
  &__avatar {
    width: 34px;
    height: 34px;
    margin-top: -7px;
    margin-left: -6px;
  }
  &__username {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
  }
}
