.container {
  position: relative;
  display: grid;

  padding: 32px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23E9D7FF' stroke-width='3' stroke-dasharray='20 20' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");

  border-radius: 16px;

  outline: 0px solid var(--black);
  outline-offset: -1px;

  background-color: var(--soft-violet);

  cursor: pointer;

  &.focus {
    outline-width: 3px;
    transition: outline 100ms ease-out;
  }

  &.loading .placeholder::after {
    opacity: 0.6;
  }

  &:hover,
  &.active,
  &.focus {
    .placeholder::after {
      opacity: 0;
    }
  }

  & > *,
  &::after {
    // stack content on each other
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
}

.icon path {
  stroke-width: 2;
}

.placeholder {
  position: relative;

  justify-content: center;
  align-items: center;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    inset: 0;

    background-color: var(--soft-violet);
    opacity: 0;

    z-index: 1;
  }
}

.icon-container {
  --size: 3.5rem;
  position: relative;
  color: var(--dark-violet);

  height: var(--size);
  font-size: var(--size);

  margin-bottom: 16px;

  z-index: 2;
}
