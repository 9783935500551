@use "/scss/mixins";

.talent-listing {
  display: grid;
  gap: 16px;
  margin: 32px 0;

  &--empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include mixins.breakpoint(lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__empty-state {
    display: block;
    text-align: center;
    margin-top: 90px;
    padding: 50px;
  }

  &-card {
    position: relative;
    background-color: var(--white);
    border-radius: 20px;
    text-decoration: none;
    padding: 15px;
    max-width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--border {
      border: 1px solid var(--soft-grey);
    }

    &__link:hover {
      text-decoration: none;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 16px;
      margin-bottom: 0px;
      flex: auto;

      @include mixins.breakpoint(400px) {
        &--with-rate {
          min-height: 192px;
        }
      }

      @include mixins.breakpoint(sm) {
        min-height: 170px;
      }

      @include mixins.breakpoint(lg) {
        min-height: 210px;
      }

      &:hover {
        text-decoration: none;
      }

      @include mixins.breakpoint(sm) {
        flex-direction: row;
        align-items: stretch;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      background-color: var(--off-white);
      border-radius: 11px;
      padding: 12px;
      width: 100%;
      min-width: 205px;

      @include mixins.breakpoint(xs) {
        width: fit-content;
      }
    }

    &__avatar {
      margin: 0 auto;
    }

    &__badge {
      padding: 4px 12px;
      background-color: var(--medium-green);
      border-radius: 5px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      overflow: hidden;

      @include mixins.breakpoint(xs) {
        align-items: flex-start;
      }
    }

    &__name {
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: center;
      margin-top: 18px;
      width: 100%;

      @include mixins.breakpoint(xs) {
        justify-content: start;
      }

      a {
        text-decoration: none;

        &:focus-visible {
          text-decoration: underline;
          outline: none;
        }
      }
    }

    &__info {
      display: flex;
      margin-top: 4px;
      align-items: center;
      flex-direction: column;
      max-width: 100%;

      @include mixins.breakpoint(xs) {
        flex-direction: row;
        gap: 6px;
        align-items: flex-start;
        flex-wrap: wrap;
      }

      @include mixins.breakpoint(sm) {
        margin-bottom: 18px;
        gap: 12px;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
      }
    }

    &__roles-tag {
      max-width: 100%;

      flex-shrink: 0;
    }

    &__timezone {
      display: flex;
      align-items: center;
      gap: 6px;

      overflow: hidden;

      svg {
        width: 18px;
        height: 18px;
      }

      p {
        text-decoration: underline;
        text-decoration-style: dashed;
        text-underline-offset: 5px;
        text-decoration-thickness: 1px;
      }
    }

    &__skills {
      margin-top: 18px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        gap: 8px;
        flex-wrap: wrap;
      }

      @include mixins.breakpoint(sm) {
        margin-top: auto;
      }
    }

    &__cta {
      * {
        white-space: nowrap;
      }
    }
  }
}

.availability-status-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  z-index: 10;
  text-decoration: underline dashed var(--dark-violet);
  text-underline-offset: 4px;
}

.invite-to-bid-button {
  padding: 4px 16px !important;
}

.talent-rating {
  width: auto;

  @include mixins.breakpoint(sm) {
    width: max-content;
  }

  @include mixins.breakpoint(lg) {
    width: auto;
    max-width: unset;
  }

  @include mixins.breakpoint(xl) {
    width: max-content;
  }
}
