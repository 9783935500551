@use "/scss/mixins";

.close-button {
  position: absolute;
  width: 42px;
  height: 42px;
  top: 23px;
  right: 23px;

  @include mixins.breakpoint(sm) {
    top: 25px;
    right: 31px;
  }
}
