@use "/scss/mixins";

.invite-talent-card {
  display: block;
  background-color: var(--white);
  border-radius: 20px;
  border: 1px solid var(--medium-beige);
  padding: 16px;
  margin-bottom: 12px;
  position: relative;

  &--invited {
    background-color: var(--soft-violet);
    border: 1px solid var(--medium-violet);
  }

  &__top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }

  &__name {
    margin-left: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 16px;
    min-width: 0;
  }

  &__links-status {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;

    @include mixins.breakpoint(1170px) {
      flex-wrap: nowrap;
    }
  }

  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
