.internal-rating-form {
  padding: 10px 20px;
  display: grid;
  max-height: 33vh;
  overflow-y: auto;

  &__header {
    display: flex;
    align-items: center;
  }

  &__note {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__note-field {
    & :global(.MuiOutlinedInput-root) {
      background-color: var(--soft-grey) !important;
      border-radius: 10px;
      padding-left: 0;

      & fieldset {
        box-shadow: none !important;
        border: none !important;
      }

      & textarea::placeholder {
        color: var(--dark-grey);
        opacity: 1;
      }
    }

    & :global(.MuiOutlinedInput-root:active),
    & :global(.Mui-focused) {
      & fieldset {
        border: none !important;
        box-shadow: 0 0 0 0 var(--dark-grey) !important;
      }
    }

    & fieldset {
      border-radius: 10px;
    }

    & :global(.Mui-focused) {
      box-shadow: 0 0 0 1px var(--dark-grey) !important;
    }

    &__avatar {
      margin-top: 2px;
    }
  }

  &__rating-field {
    &__container {
      line-height: 0;
      height: min-content;
    }
  }

  &__submit-btn {
    font-size: 11px;
  }
}
