@use "/scss/mixins";

.container {
  display: grid;
  grid-template-columns: repeat(6, minmax(max-content, 1fr));
  margin-top: 32px;
  align-items: center;
  gap: 16px;
}

.option {
  display: flex;
  flex-direction: row;
  grid-column: span 6;
  border-radius: 8px;
  background-color: var(--soft-violet);
  transition: background-color 0.2s ease-in;
  padding: 16px;
  height: 56px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  &--active {
    background-color: var(--medium-violet);
  }

  @include mixins.breakpoint(sm) {
    flex-direction: column;
    height: 114px;
    gap: 32px;
    grid-column: span 2;
  }
}
