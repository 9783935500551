.remove-member-snackbar {
  &__container {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    color: var(--white);
    background-color: var(--positive-1);
    height: 48px;
    padding: 0 16px;
  }

  &__check-icon {
    color: var(--white);
    width: 24px;
  }

  &__close-icon {
    color: var(--white);
  }
}
