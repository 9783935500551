.wrapper {
  font-family: var(--primaryFontFamily);
  font-weight: 500;
  font-size: 13px;
  border-radius: 5px;
  padding: 6px 10px 5px;
  border: none;
  background: transparent;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--off-white);
  }

  &:active {
    background: var(--grey-5);
    box-shadow: none;
  }

  &:focus-visible {
    outline: 1px solid var(--black);
  }
}
