.opted-state {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 12px;
  background-color: var(--medium-violet);
  padding: 16px;
  border-radius: 16px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-color: var(--soft-violet);
}
