@use "/scss/mixins";

.modal-content {
  max-width: 700px;
  min-width: 300px;

  @include mixins.breakpoint(sm) {
    min-width: 400px;
  }
}

.form-element {
  margin: 30px 0;
  text-align: center;
}
