.loading-modal {
  background: linear-gradient(
    119.92deg,
    #d4e4f9 1.7%,
    #e5d7fb 44.69%,
    #fff5e3 80.85%,
    #fffdbd 102.16%
  );
  width: 525px;
  padding: 50px !important;

  @media (max-width: 599px) {
    width: 90%;
  }
}

.loader {
  position: absolute;
  height: 86px;
  width: 86px;
  object-fit: cover;
  animation: rotate-animation 1s linear infinite;
}

@keyframes rotate-animation {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
