@use "/scss/mixins";

.modal {
  width: min(100vw - 2rem, 48rem);
  padding: 24px 24px 48px 24px;
  position: relative;

  @include mixins.breakpoint(sm) {
    padding: 24px 64px 64px 64px;
  }

  &::before {
    border-radius: 100%;
    position: absolute;
    background: var(--soft-blue);
    width: 659px;
    height: 396px;
    left: 50%;
    top: -209px;
    transform: translateX(-50%);
    content: "";

    @include mixins.breakpoint(sm) {
      height: 900px;
      width: 1500px;
      top: -589px;
    }
  }
}

.close-button {
  padding: 0 !important;
  top: 20px !important;
  right: 20px !important;
}

.top-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  position: relative;

  @include mixins.breakpoint(sm) {
    gap: 36px;
  }
}

.bottom-content {
  display: flex;
  flex-direction: column;
  place-items: center;
}

.hand-emoji {
  --size: 2rem;
  display: grid;
  place-items: center;

  width: var(--size);
  height: var(--size);

  background-color: var(--medium-yellow);

  border-radius: 50%;
}
