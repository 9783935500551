.phone-verification-code-modal {
  max-width: 500px;
  width: 94%;
}

.phone-verification-form {
  .phone-verification-form-text-field {
    width: 100%;
    margin-top: 15px;
  }
}
