.modal {
  max-width: 520px;
  padding: 32px;
}

.modal-alert {
  :global(.alert-content) {
    width: 100%;
    padding: 16px;

    :global(.custom-alert-icon) {
      margin-right: 1.5rem;
    }
  }
}
