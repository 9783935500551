@use "/scss/mixins";

.complete-profile-bar {
  background-color: var(--black);
  color: var(--white);
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  text-align: center;
  padding-block: 5px;
  padding-inline: 16px;

  &__copy {
    @include mixins.breakpoint("sm") {
      text-align: center;
      flex: 1;
      padding: 0;
    }
  }

  &__dismiss-btn {
    height: 24px;
    padding: 0;
  }
}
