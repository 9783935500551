.interview-message-modal {
  background-color: var(--soft-violet);
  border: 1px solid var(--medium-violet);

  width: min(680px, 100vw - 2rem);
}

.ai-interview-message-modal {
  background-color: var(--soft-green);
  border: 1px solid var(--medium-green);

  width: min(680px, 100vw - 2rem);
}

.make-offer-message-modal {
  background-color: var(--soft-green);
  border: 1px solid var(--medium-green);
}
