@use "/scss/mixins";

.job-carousel-item {
  height: 100%;
  border-radius: 20px;

  display: flex;
  flex-direction: column;

  &__header {
    border-radius: 20px 20px 0px 0px;
    padding: 17px 22px;
  }

  &__content {
    padding: 16px 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
    border-radius: 0 0 20px 20px;
    text-align: start;
    min-height: 342px;
    flex: 1;
  }

  &__budget {
    margin: 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 16px;
  }

  &__cta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.job-for-you-carousel-item {
  display: flex;
  flex-direction: column;
  height: 344px;
  width: 300px;
  padding: 24px;
  background-color: var(--white);
  position: relative;

  @include mixins.breakpoint(sm) {
    width: 362px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  &__actions {
    z-index: 2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-top: 24px;
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__share {
    padding: 0 !important;
    margin-left: 8px;
    margin-right: 12px;
  }

  &__save-job {
    bottom: 1.5px;
  }
  &__logo {
    min-width: 72px;
    width: 72px;
    height: 72px;
    border-radius: 12px;
    border: 1px solid var(--soft-violet);
    overflow: hidden;
    padding: 19px;
  }
}

.home-carousel-extended-width {
  @include mixins.breakpoint(md) {
    width: calc(100% + 40px);
  }
}
