@use "/scss/mixins";

.product-checkout-modal {
  margin: 0.875rem 0 -18px !important;
  border-radius: 8px;

  @include mixins.breakpoint(md) {
    max-width: 1020px;
    min-width: 693px;
    margin: unset !important;
    margin-left: auto !important;
    margin-top: -30px !important;
    margin-bottom: -30px !important;
    border-radius: 0px !important;
  }
}
