@use "/scss/variables";

$headerHeight: 88px;

%wrapper {
  max-width: variables.$wrapperWidth;
  min-width: variables.$wrapperMinWidth;
  width: 94%;
  margin: 0 auto;
}

%app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  min-height: $headerHeight;
  background-color: var(--black);
  z-index: 1001;
  min-width: 360px;
}

%horizontal-scroll {
  overflow-x: visible;
  width: 100%;
  margin-bottom: auto;
  min-height: min-content;
}
