@use "/scss/mixins";

.public-jobs-page {
  padding-top: 24px !important;

  @include mixins.breakpoint(sm) {
    padding-top: 40px !important;
  }

  @include mixins.breakpoint(md) {
    padding-top: 95px !important;
  }
}
