@use "/scss/mixins";

.create-post-section {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
  background-color: var(--white);
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid var(--grey-4);
  box-shadow:
    0px 4px 8px rgba(20, 20, 20, 0.04),
    0px 8px 24px rgba(20, 20, 20, 0.04);

  @include mixins.breakpoint(sm) {
    padding: 24px;
  }

  &__top-section {
    display: flex;
    align-items: center;

    &--disabled {
      gap: 16px;
    }
  }

  &__mobile-icon-box {
    border-radius: 8px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 32px;
    height: 32px;
    border: 1px solid #141417;
  }
}

button.text_field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 8px;
  white-space: nowrap;

  @include mixins.breakpoint(sm) {
    padding: 8px 16px;
  }
}
