.sticker-wrapper {
  display: inline-block;
}

button.sticker-button {
  padding: 4px;

  svg {
    filter: drop-shadow(0px 0px 0px rgb(0 0 0 / 0));
    transition: filter 150ms ease-out;
  }
  &--animated {
    svg {
      filter: drop-shadow(0px 4px 3px rgb(0 0 0 / 0.15));
    }
  }
}
