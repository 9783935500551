.invoice-field {
  &__tooltip {
    position: absolute !important;
    right: -15px;
    top: 35px;
  }

  &__number {
    margin-top: 15px;
    padding: 7px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
