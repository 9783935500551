.resume {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.resume-added {
  padding: 4px;
  background-color: var(--yellow);

  &--with-store-resume {
    padding: 4px 4px 12px 4px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
