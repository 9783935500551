.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  grid-column: span 2;
  gap: 8px;

  &__actions {
    flex-wrap: nowrap;
  }
}
