.checklist {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  hr:last-child {
    display: none;
  }
}

.checklist-accordion {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content {
    display: inline-flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__button {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: rotate(-90deg);

    &--expanded {
      transform: rotate(0deg);
    }
  }
}
