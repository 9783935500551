.application-question-answer-list {
  margin: 15px 0;
  word-break: break-word;
  max-width: 1194px;

  &--clickable {
    &:hover {
      cursor: pointer;
    }
  }
}

.answer-content {
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 1.35;
}

.answer-content--overlay-text-shadow {
  position: relative;
  text-overflow: ellipsis;
  overflow-y: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(to bottom, transparent, var(--white));
  }
}
