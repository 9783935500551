.applied-recently-field {
  label {
    padding: 0 0 0 4px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid var(--soft-beige);
  border-radius: 6px;
  box-shadow: var(--elevation-4);
  overflow: visible;
}

.checkbox {
  border: none !important;
  padding-left: 0 !important;

  & > :global(.MuiFormControlLabel-root) {
    gap: 7px;
  }
}
