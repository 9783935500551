.payment-success-modal {
  text-align: center;
  max-width: 400px;

  &-icon {
    font-size: 80px !important;
    color: #2cbf2c;
    margin-bottom: 10px;
  }
}
