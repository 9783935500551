.attachment-field {
  & :global(.MuiOutlinedInput-root) {
    background-color: var(--yellow) !important;
    border-radius: 8px;
    height: 42px;

    & fieldset {
      border-color: transparent !important;
    }
  }

  & :global(.MuiOutlinedInput-input) {
    padding: 6px 14px !important;
    -webkit-text-fill-color: var(--black) !important;
    cursor: inherit !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & :global(.Mui-focused) {
    background-color: var(--white) !important;

    fieldset {
      border-color: var(--medium-orange) !important;
      border-width: 1px !important;
      box-shadow: 0px 0px 0px 4px var(--medium-yellow) !important;
    }
  }

  &:not(.attachment-field--no-rename) :global(.Mui-disabled):hover {
    background: var(--medium-yellow) !important;

    fieldset {
      border-color: var(--medium-orange) !important;
    }
  }
}

.extension {
  transform: translateX(-12px);
  color: var(--grey-3);
}
