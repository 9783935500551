button.button {
  font-size: 1.5rem;
  border-radius: 8px;
  background-color: var(--white);

  &:hover {
    box-shadow: inset 0 0 0 1px var(--colorVariant);
    background-color: var(--backgroundColor);
  }

  &[class*="selected"] {
    box-shadow: inset 0 0 0 1px var(--darkColorVariant);
    background-color: var(--backgroundColor);
  }

  & > svg {
    // override defaults
    max-width: 1em;
    max-height: 1em;
  }
}
