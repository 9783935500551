.resume-viewer {
  &__download {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 26px;

    &:hover {
      text-decoration: none;
    }
  }
}
