.modal-wrapper {
  max-width: 674px;
  padding: 32px;
}

.header {
  position: relative;
  height: 42px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}
