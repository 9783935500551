@use "/scss/mixins";

.onboarding-form-wrapper {
  display: flex;
  flex-direction: column;
  margin: initial !important;
  max-width: initial !important;

  @include mixins.breakpoint(md) {
    flex-direction: row;
  }
}
