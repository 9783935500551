.air-ai-bar {
  background-color: var(--soft-violet);
  display: flex;
  justify-content: space-between;
  border-radius: 10px;

  &__custom-link {
    text-decoration: underline;
    cursor: pointer;
  }
}
