.contracts-history-table {
  --header-fs: 16px;
  --cell-fs: var(--header-fs);
  --cell-pr: 9px;
  --cell-pl: 9px;
  border: 0;

  @media (max-width: 600px) {
    margin-right: -18px;
  }

  :global {
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-cell {
      padding-left: var(--cell-pl);
      padding-right: var(--cell-pr);

      &[aria-colindex="1"] {
        padding-left: calc(2 * var(--cell-pl));
      }
    }

    .MuiDataGrid-columnHeader {
      background-color: var(--soft-violet);
      border: 0 !important;

      .MuiDataGrid-columnHeaderTitle {
        font-size: var(--header-fs);
        line-height: 1;
        font-weight: 400;
        color: var(--grey-1);
      }

      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: none;
      }

      button svg {
        outline-offset: -3px;
      }

      &:focus-visible {
        text-decoration: underline;
        button svg {
          outline-offset: -1px;
          outline: 1px solid var(--dark-violet);
          transition: outline-offset 200ms ease-in-out;
        }
      }
    }

    .MuiDataGrid-cell {
      font-size: var(--cell-fs);

      &:focus-visible,
      &:focus-within,
      &:focus {
        outline: none;
      }
    }

    .MuiDataGrid-columnHeaders {
      [role="row"] {
        background: transparent !important;
      }

      .MuiDataGrid-filler {
        border: unset;
      }
    }

    .MuiDataGrid-filler {
      border: unset;
    }
  }
}

.table--empty {
  --DataGrid-overlayHeight: 0;
}
