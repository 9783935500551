.badge {
  position: relative;

  &-dot {
    position: absolute;
    top: 13px;
    right: 11px;
    display: block;
    width: 8px;
    height: 8px;
    background-color: var(--negative-2);
    border-radius: 50%;
    z-index: 2;
    box-shadow: 0 0 0 1px var(--white);
  }
}
