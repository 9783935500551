@use "/scss/mixins";

.section-panel {
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid var(--soft-beige);
  border-radius: 16px;
  background-color: var(--white);
  position: relative;

  @include mixins.breakpoint(sm) {
    padding: 24px;
    margin-bottom: 24px;
  }

  &__head {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      "title actions"
      "description description";
    gap: 8px;
    margin-bottom: 16px;

    @include mixins.breakpoint(sm) {
      grid-template-areas: "title description actions";
    }
  }

  &__title {
    grid-area: title;
    margin-top: 3px;
  }

  &__actions {
    grid-area: actions;
    text-align: right;
  }

  &__description {
    grid-area: description;
    word-break: break-word;

    @include mixins.breakpoint(sm) {
      margin-top: 3.5px;
    }
  }

  &__importance {
    grid-area: importance;
    margin-top: 4px;
    margin-left: 8px;
    text-align: left;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image img {
    height: auto;
  }

  &--error {
    border-color: var(--negative-2);
  }
}

.panel-with-importance {
  & .section-panel__head {
    grid-gap: 0;
    grid-template-areas:
      "title actions"
      "importance importance"
      "description description";

    @include mixins.breakpoint(xs) {
      grid-template-areas:
        "title importance actions"
        "description description description";
    }
  }

  & .section-panel__description {
    margin-top: 0;
  }
}
