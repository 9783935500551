.overlay {
  position: relative;
  text-overflow: ellipsis;
  overflow-y: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(to bottom, transparent, var(--white));
  }
}

.employer-overview {
  word-wrap: break-word;
  white-space: pre-wrap;
}
