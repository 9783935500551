@use "/scss/mixins";

.content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 200px;
  max-width: 200px;
  margin: 0 auto;
  padding: 10px;

  @include mixins.job-list-breakpoint(sm) {
    max-width: none;
    max-height: none;

    padding-bottom: 20px;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  align-self: stretch;

  @include mixins.job-list-breakpoint(sm) {
    width: 148px;
    height: initial;
    min-width: 148px;
    max-height: initial;
  }
}

.fallback {
  border-radius: 12px;
  border: 1px solid var(--soft-violet);
  overflow: hidden;
}
