.header-content {
  margin: auto;
  gap: 8px;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: 32px;
  background-color: var(--white);
}
