.profile-completion-feedback {
  color: var(--white);

  &__item {
    margin: 0 !important;

    & p,
    & button {
      font-size: 11px !important;
      line-height: 26px !important;
    }
  }

  &__item:not(:first-child) {
    margin-top: 16px;
  }
}
