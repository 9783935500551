@use "/scss/mixins";

.my-job-list {
  margin-bottom: 80px;
  gap: 16px;
  display: flex;
  flex-direction: column;

  &__tooltip {
    color: inherit;
    font-size: 14px;
    font-weight: 500;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  gap: 4px;

  &__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mixins.breakpoint(800px) {
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
    }
  }

  @include mixins.breakpoint(800px) {
    align-items: center;
  }

  &__title {
    font-size: 28px !important;
    display: block;
    word-break: break-word;
    width: fit-content;

    &:hover {
      text-decoration: underline !important;
    }
  }

  &__active-hires {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  &__info {
    margin-bottom: 8px;

    @include mixins.breakpoint(800px) {
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__count {
    width: fit-content;
  }

  &__head {
    @include mixins.breakpoint(800px) {
      margin-right: 30px;
    }
  }

  &__profiles {
    gap: 15px;
  }

  &__menu-wrapper {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-direction: column-reverse;

    svg,
    button {
      margin-bottom: auto;
    }

    @include mixins.breakpoint(800px) {
      flex-direction: row;

      svg,
      button {
        margin-bottom: 0;
      }
    }
  }
}
