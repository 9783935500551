.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sub-nav {
  margin-top: 8px;
  margin-left: 15px !important;
  width: initial;

  &--last {
    margin-left: 0;
    max-height: 360px;
    overflow-y: auto;
  }
}

/* increase specificity */
.item.item {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-inline: 16px;

  & .icon--end {
    margin-left: auto;
  }
}
