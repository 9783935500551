@use "/scss/mixins";

.invoice-form {
  width: 100%;
  word-break: break-word;

  &__wrapper {
    margin-bottom: 28px;
    padding: 0 !important;
    overflow: hidden;
  }

  &__content {
    background-color: var(--white);
    padding: 40px 4px;

    @include mixins.breakpoint(md) {
      padding: 80px 6%;
    }
  }

  &__total {
    align-self: flex-end;
  }

  &__actions {
    margin: 50px 0 100px;
    text-align: right;

    :global(.general-form-error) {
      max-width: 850px;
      margin: 0 auto 20px;
    }
  }
}
