.title {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  margin: 0 6px 2px 10px;
}

.separator {
  height: 20px;
  width: 1px;
  background-color: var(--medium-beige);
}
