.offer-status {
  &.green {
    background-color: var(--medium-green);
  }

  &.blue {
    background-color: var(--medium-blue);
  }

  &.red {
    background-color: var(--medium-red);
  }

  &.orange {
    background-color: var(--medium-orange);
  }
}
