.wallet-alert {
  &:global(.alert--success) {
    background-color: var(--medium-green);
    justify-content: flex-start;
    width: 100%;
  }

  & > :global(.alert-content) {
    width: 100%;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
