@use "/scss/variables";

.modal {
  width: 96%;
  max-width: 900px;
}

.content {
  position: relative;

  &__nav {
    margin-bottom: 30px;
    padding-bottom: 15px;
    padding-top: 10px;
    border-bottom: 1px solid variables.$grayColor;
  }
}
