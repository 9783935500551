.container {
  width: 100%;
  aspect-ratio: auto 1400 / 910;
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
}
