@use "/scss/mixins";

.product-description-modal {
  width: 100%;
  margin: 0.875rem 0.75rem !important;
  border-radius: 8px;

  @include mixins.breakpoint(md) {
    width: 693px;
    margin: unset !important;
    margin-left: auto !important;
    margin-top: -30px !important;
    margin-bottom: -30px !important;
    border-radius: 0px !important;
  }
}
