.label {
  display: flex;
  gap: 4px;
  align-items: center;

  &--emoji {
    font-size: inherit !important;
    line-height: inherit !important;
  }
}
