@use "/scss/mixins";

.filters-carousel {
  width: 100%;
  display: flex;
  align-items: center;

  &__swiper {
    position: relative;
    max-width: 100%;
    margin-left: 0;
    padding: 0;

    &--with-previous-fades {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 8px;
        width: 27px;
        height: 105%;
        background: linear-gradient(
          90deg,
          #{rgba(var(--off-white-rgb), 0.95)} 53.37%,
          rgba(245, 248, 255, 0) 100%
        );
        z-index: 20;

        @include mixins.breakpoint(md) {
          width: 54px;
          left: 0px;
        }
      }
    }

    &--with-next-fades {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 8px;
        width: 27px;
        height: 105%;
        background: linear-gradient(
          270deg,
          #{rgba(var(--off-white-rgb), 0.95)} 53.37%,
          rgba(245, 248, 255, 0) 100%
        );
        z-index: 20;

        @include mixins.breakpoint(md) {
          width: 54px;
          right: 0px;
        }
      }
    }

    .swiper-slide {
      width: auto !important;
      max-width: 550px;
      margin-top: auto;
      margin-bottom: auto;

      &:first-child {
        z-index: 90;
      }
    }
  }
}

.button {
  position: absolute;
  z-index: 100;
}

.button--left {
  left: 0;
}

.button--right {
  right: 0;
}

.button--hidden {
  display: none;
}
