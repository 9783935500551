@use "/scss/mixins";

.auth-form {
  background-color: var(--white);
  padding: 35px 25px;
  margin: 12px;

  @include mixins.breakpoint(sm) {
    padding: 15px;
    margin: 0;
  }
}

.auth-title-emoji-wrapper {
  position: relative;
  width: fit-content;

  & > svg {
    color: var(--medium-yellow);
    font-size: 64px;
  }

  & > span {
    position: absolute;
    transform: translate(-45%, -65%);
    left: 50%;
    top: 50%;
  }
}
