.form-control-label {
  width: 100%;
  padding: 0 10px;

  &__border {
    border: 1px solid var(--grey-4);
    border-radius: 8px;
    padding: 5px 0;
    padding-left: 20px;
    width: 100%;
    margin: 0;
  }

  &--error {
    color: var(--negative-1);
  }

  &--yellow-checked {
    background-color: var(--yellow);
  }

  &__chosen-field,
  &__chosen-field-no-border {
    position: relative;

    & label {
      z-index: 100;
    }

    &::before {
      content: "";
      z-index: 1;
      position: absolute;
      display: block;
      top: -4px;
      left: -4px;
      bottom: -4px;
      right: -4px;
    }

    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      display: block;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
    }
  }

  &__chosen-field {
    &::before {
      border-radius: 8px;
      border: 8px solid var(--yellow);
    }

    &::after {
      border-radius: 8px;
      border: 1px solid var(--grey-1);
    }
  }
}

.custom-radio-button {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--grey-4);
  color: var(--white);
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &--active {
    background-color: var(--grey-1);
  }

  &--error {
    background-color: var(--negative-1);
  }

  &__circle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;

    &--active {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: currentColor;
    }
  }
}
