@use "/scss/mixins";

.freelancer-overview {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px 32px;
  flex-wrap: wrap;
}

.freelancer-details {
  display: flex;
  column-gap: 32px;
  row-gap: 8px;
  flex-wrap: wrap;
  width: 100%;

  @include mixins.breakpoint(500px) {
    width: auto;
  }
}

.freelancer-links {
  display: flex;
  column-gap: 8px;

  & :global(.social-profiles-list-item) {
    margin: 0;
  }
}

.clickable-container {
  cursor: pointer;
  > div {
    pointer-events: none;
  }
}
