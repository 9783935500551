@use "/scss/mixins";

.feed-comments-section {
  display: grid;
  width: 100%;
  gap: 8px;
  margin-top: 16px;
  border-top: 1px solid var(--grey-4);
  position: relative;

  @include mixins.breakpoint(sm) {
    margin-top: 24px;
  }

  &__avatar-wrapper {
    display: grid;
    grid-template-rows: max-content 1fr;
    position: relative;
    grid-row: 1 / 3;
  }

  &__list {
    display: grid;
    gap: 16px;
    margin-top: 16px;
    position: relative;

    &--public:first-of-type::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 10%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 90%
      );
      pointer-events: none;
      z-index: 10;
    }
  }

  &__thread-visualizer-top-comment {
    margin-top: 16px;
    width: 1px;
    position: relative;
    left: 50%;
    height: calc(100% - 48px);
    background-color: var(--grey-4);
  }

  &__reply-form-wrapper {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 48px;
      height: calc(100% + 48px);
      bottom: 0;
      right: calc(100% + 16px);
      background-color: var(--white);

      @include mixins.breakpoint(sm) {
        height: calc(100% + 24px);
      }
    }
  }

  &__thread-visualizer__last-comment {
    --avatar-size: 24px;

    position: absolute;
    width: 100%;
    height: 100%;
    right: calc(100% + 8px);
    background-color: var(--white);

    @include mixins.breakpoint(sm) {
      right: calc(100% + 16px);
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 50%;
      height: 12px;
      right: 0;
      background-color: var(--grey-4);
      border-radius: 0 0 0 25px;

      @include mixins.breakpoint(sm) {
        height: var(--avatar-size);
      }
    }

    &::after {
      right: -1px;
      top: -1px;
      background-color: var(--white);
    }
  }

  .feed-comment {
    position: relative;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    width: 100%;
    min-width: 0;

    &__public-last {
      min-height: 371px;
      overflow: hidden;

      @include mixins.breakpoint(700px) {
        min-height: 344px;
      }
    }

    &__internal-list {
      grid-column: 2 / 4;
      grid-row: 2;
      margin-left: 8px;

      @include mixins.breakpoint(sm) {
        margin-left: 16px;
      }
    }

    &__avatar {
      display: block;
      padding: 0;
      min-width: 0;
      flex-shrink: 0;
    }
  }
}
