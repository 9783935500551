@use "/scss/mixins";

.refer-cards-section {
  display: grid;
  gap: 32px;
  min-width: 0;
  margin-top: 32px;

  @include mixins.breakpoint(840px) {
    grid-template-columns: 1fr 1fr;
  }

  @include mixins.rangeBreakpoint(960px, 1090px) {
    grid-template-columns: 1fr;
  }
}

.refer-card {
  border-radius: 8px;
  display: grid;
  padding: 16px;

  @include mixins.breakpoint(sm) {
    padding: 32px 40px;
  }

  // We need to hardcode sections height as below to
  // make sure the sections on to different cards
  // are same height. (change only if content change)
  // TODO: this can be adjusted with subgrid when it
  // will be widely supported.
  @include mixins.rangeBreakpoint(840px, 959px) {
    grid-template-rows: 1fr 224px 80px;
  }

  @include mixins.rangeBreakpoint(1089px, 1200px) {
    grid-template-rows: 1fr 224px 80px;
  }

  @include mixins.breakpoint(1200px) {
    grid-template-rows: 1fr 177px 80px;
  }

  @include mixins.breakpoint(1600px) {
    grid-template-rows: 1fr max-content max-content;
  }
}
