@use "/scss/mixins";

.close-job-form-body {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  padding: 15px;

  @include mixins.breakpoint(sm) {
    width: 450px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 32px;

    & > button:first-child {
      margin-right: 5px;
    }
  }
}
