@use "/scss/mixins";

.help-offer-status-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.action-bar {
  border: 1px solid var(--beige);
  border-radius: 16px;
  margin-bottom: 26px;
  flex-shrink: 0;
  max-height: 16vh;
  overflow: auto;
}

.help-offer {
  display: flex;
  justify-content: space-between;
  min-height: 32px;
  margin: 16px;
  margin-right: 4px;

  align-items: center;
  gap: 8px;

  :global(.category-and-budget) {
    justify-content: flex-start;
  }

  @include mixins.breakpoint(sm) {
    min-height: 42px;
    margin-right: 8px;
  }

  @media (max-width: 599px) {
    :global(.category-and-budget) {
      padding: 4px 8px;
      font-size: 13px;
      line-height: 24px;

      flex-direction: column;
      align-items: start;
      gap: 0;
    }
  }
}

.help-offer-buttons {
  display: flex;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  gap: 8px;

  padding-right: 12px;

  @include mixins.breakpoint(sm) {
    padding-right: 8px;
  }
}

.help-offer__status {
  font-style: italic;
}

.divider {
  margin-left: 0;
  width: auto;

  &:last-child {
    display: none;
  }

  @include mixins.breakpoint(sm) {
    margin-left: 16px;
    width: calc(100% - 2 * 16px);
  }
}

.offer-badge {
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.offer-badge--active {
  background-color: var(--medium-green);
  color: var(--dark-green);
}

.offer-badge--pending {
  background-color: var(--medium-blue);
  color: var(--dark-blue);
}

.offer-category-name {
  display: inline-block;
  max-width: calc(100% - 20px);
}

.offer-category-wrapper {
  max-width: 100%;
}
