@use "/scss/mixins";

.internal-talent-reviews {
  height: 100vh;
  display: grid;
  width: 100%;
  grid-template-rows: min-content 1fr min-content;

  @include mixins.breakpoint(sm) {
    width: 454px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    padding: 16px 24px;
    overflow: hidden;
    min-width: 0;
  }

  &__comments {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 10px;
    overflow-y: auto;
    border-block: 1px solid var(--grey-4);

    @include mixins.breakpoint(sm) {
      padding-inline: 0;
    }
  }

  &__item {
    max-width: 370px;
    width: 100%;
    margin-inline: auto;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 8px;

    &-header {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &-rating {
      &__container {
        line-height: 0;
        height: min-content;
      }
    }

    &-footer {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 337px;
    text-align: center;
    align-self: center;
    margin-block: auto;

    &__icon {
      margin-bottom: 24px;
    }

    &__core {
      margin-top: 32px;
      color: var(--dark-violet);
    }
  }
}
