.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin-top: 36px;
}

.emoji-wrapper {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 36px;
}

.emoji {
  display: block;
  font-size: 36px;
  line-height: 1em;
}

.clear-button {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.emoji-node {
  font-family: EmojiMart, "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI",
    "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji",
    serif, emoji;
}
