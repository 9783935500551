.modal {
  width: 96%;
  max-width: 700px;
  padding: 32px;
}

.content {
  padding-top: 4px;

  .title {
    margin-bottom: 28px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }

  .info {
    margin-bottom: 4px;
    font-size: 36px;
    line-height: 48px;
    font-weight: 400;
  }

  .info--secondary {
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }

  .button {
    margin-left: auto;
  }
}
