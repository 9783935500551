@use "/scss/mixins";

.offer-visual {
  position: relative;
  display: grid;

  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;
    justify-content: center;
    align-items: center;
    gap: 22px;
  }
}

.visual-pill {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background-color: var(--white);
  min-width: 200px;
  min-height: 32px;
  border-radius: 16px;
  font-weight: 500;

  @include mixins.breakpoint(xs) {
    padding: 16px;
  }

  &--large {
    width: 168px;
  }

  &:last-child {
    margin-top: -36px;
  }
}

.visual-icon {
  margin-top: -36px;
  z-index: 1;

  &__agreement {
    border: 4px solid var(--medium-blue);
    display: flex;
    width: 56px;
    height: 56px;
    align-items: center;
    justify-content: center;

    font-size: 22px;
    border-radius: 50%;
    background-color: var(--black);

    align-self: center;
  }
  &--green-variant {
    .visual-icon__agreement {
      background-color: var(--positive-2);
      border: 4px solid var(--medium-green);
    }
  }
  &--inverse {
    .visual-icon__agreement {
      background-color: var(--white);
      border: 4px solid var(--soft-blue);
    }
  }
}
