@use "/scss/mixins";

.side-panel {
  padding: 24px;
  background-color: var(--soft-green);

  @include mixins.breakpoint(sm) {
    max-width: 321px;
  }

  &__link {
    text-decoration: underline;
  }
  &__close-button {
    color: var(--positive-1) !important;
  }
  &__icon-wrapper {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    background-color: var(--medium-green);
    justify-content: center;
    align-items: center;
    color: #000;
    position: relative;
    &__content {
      position: absolute;
      top: 6px;
      left: 9px;
    }
  }
}
