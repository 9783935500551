.location-mismatch-alert.medium-red {
  background-color: var(--medium-red);
  padding: 20px;
  border-radius: 11px;
  width: unset;
  justify-content: flex-start;

  & > div {
    padding: 0;
  }
}
