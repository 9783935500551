@use "/scss/mixins";

$rules-max-width: 755px;
$rules-max-container-height: 658px;
/* account for:
   1. container padding
   2. title's height with flex gap
   3. read more's height with flex gap
 */
$rules-preview-only-height: $rules-max-container-height - 64px - 48px - 42px;

.read-more-modal {
  max-width: min($rules-max-width, 100% - 2rem);
}

.fade {
  position: absolute;
  content: "";
  top: calc(100% - 100px - 26px);
  height: 100px;
  background: linear-gradient(360deg, #ffffff 20%, rgba(255, 255, 255, 0) 150%);
}

.read-more-wrapper {
  display: flex;
  width: auto;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
}

.container {
  max-width: 100%;
  padding: 24px;
  border-radius: 16px;

  &--limited-height {
    max-height: $rules-max-container-height;
    overflow: hidden;
  }

  @include mixins.breakpoint(xs) {
    padding: 32px;
  }
}

.rule-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0;

  &--limited-height {
    max-height: $rules-preview-only-height;
    overflow: hidden;
  }

  &--large-gap {
    gap: 32px;
  }

  & > div {
    width: 100%;
  }

  &__button {
    margin-top: 32px;
  }
}

.rule-list-modal {
  width: $rules-max-width;
}

.rule-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.rule {
  gap: 16px;
  padding-right: 16px;

  &__counter {
    display: grid;
    place-items: center;
    flex-shrink: 0;
    align-self: baseline;
    height: 32px;
    width: 32px;
    border-radius: 24px;
    background-color: var(--medium-teal);
  }

  &__text {
    margin-block: 0;
  }

  &__description {
    word-break: break-word;
  }
}

.divider {
  margin-top: 16px;
}

.sortable-rule {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-left: 8px;

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.delete-rule-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__delete-button {
    align-self: flex-end;
  }
}
