@use "/scss/mixins";

.wrapper {
  margin-bottom: 58px;
}

div.carousel {
  width: 100%;
  height: 58px;

  --swiper-wrapper-transition-timing-function: linear;
}

.trusted-by {
  height: 100%;

  display: flex;
  align-items: center;

  padding-right: 10px;
}

.logo {
  height: 100%;

  display: flex;
  align-items: center;
  line-height: 0;

  padding-inline: 26px;
}
