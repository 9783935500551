@use "/scss/mixins";

.review-before-sending-summary {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid var(--soft-beige);
  padding: 24px 8px;
  width: 100%;

  @include mixins.breakpoint(sm) {
    padding: 24px 32px;
  }

  .review-before-sending-summary-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.review-before-sending-summary-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--soft-beige);
  padding-bottom: 20px;
}
