@use "/scss/mixins";

.messenger-content {
  padding: 24px 16px;
  position: relative;
  background-color: var(--white);
  border-radius: 16px;
  flex-grow: 1;
  min-width: 0;

  @include mixins.breakpoint(md) {
    height: 100%;
  }

  @include mixins.breakpoint(lg) {
    padding-inline: 32px;
  }
}
