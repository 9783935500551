.not-found-page {
  text-align: center;
  max-width: 800px !important;
  padding: 200px 0 !important;

  &-message {
    margin-bottom: 35px;
  }

  &-info {
    max-width: 300px;
    margin: auto;

    a {
      font-size: inherit;
      text-decoration: underline;
    }
  }
}
