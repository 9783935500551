@use "/scss/mixins";

.referral-sort-button {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & .sort-icon-button {
    padding: 2px;
  }

  @include mixins.breakpoint(md) {
    margin-bottom: 0;
  }
}
