@use "/scss/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 20px;
  text-decoration: none;
  padding: 16px;
  border: 1px solid var(--soft-grey);
  transition: all 0.1s;
}

.top-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @include mixins.job-list-breakpoint(sm) {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.header {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  text-decoration: none;
  grid-template-areas:
    "applied actions-or-status"
    "header header"
    "budget budget"
    "view-btn view-btn"
    "main main"
    "divider divider"
    "match match";

  margin-top: 16px;

  @include mixins.job-list-breakpoint(sm) {
    grid-template-areas:
      "applied actions-or-status"
      "header budget"
      "main view-btn"
      "divider divider"
      "match match";

    margin: 0 0 0 16px;
  }

  @include mixins.job-list-breakpoint(md) {
    margin-left: 24px;
  }
}

.footer {
  grid-area: match;
  grid-column-end: span2;

  @include mixins.job-list-breakpoint(400px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.divider {
  grid-area: divider;
  margin: 8px 0;
}
