@use "/scss/mixins";

.wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: max-content max-content;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;

  @include mixins.breakpoint(sm) {
    padding: 32px;
    grid-template-rows: 1fr;
    grid-template-columns: max-content auto max-content;
  }
}

.back-btn {
  width: fit-content;

  grid-column: 1 / 2;
  grid-row: 2 / 3;

  @include mixins.breakpoint(sm) {
    grid-column: initial;
    grid-row: initial;
  }
}

.offer-status {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  min-width: 0;
  display: flex;
  justify-content: center;

  @include mixins.breakpoint(sm) {
    grid-column: initial;
    grid-row: initial;
  }
}

.submit-btn {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  justify-self: flex-end;

  @include mixins.breakpoint(sm) {
    grid-column: initial;
    grid-row: initial;
  }
}
