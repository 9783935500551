@use "/scss/mixins";

.request-refund-modal {
  max-width: 654px;
  width: 96%;

  &__footer {
    display: flex;
    margin-top: 28px;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-end;
  }
}

.request-refund-modal .refund-reason-field textarea {
  padding: 0px;
}

.error-message {
  margin-left: auto;
  order: 1;

  @include mixins.breakpoint(649px) {
    order: 0;
  }
}
