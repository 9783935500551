@use "/scss/mixins";

.celebratory-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.horizontal-gradient {
  background: linear-gradient(0deg, #ffffff 56.3%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 200px;
  position: absolute;
  top: 150px;
  left: 0;

  @include mixins.breakpoint("sm") {
    top: 200px;
  }
}

.vertical-gradient {
  width: 321px;
  height: 323px;
  position: absolute;
  top: 0;
  z-index: -1;
}
