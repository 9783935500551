@use "/scss/mixins";

.confirmation-client-invite-modal {
  text-align: center;
  border-radius: 20px !important;
  max-width: 1166px;
  position: relative;
}

.confirmation-client-invite-modal__top-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--medium-green);
  padding: 24px;

  @include mixins.breakpoint(md) {
    padding: 40px;
  }
}

.confirmation-client-invite-modal__cta-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.confirmation-client-invite-modal__bottom-section {
  background-color: var(--off-white);
  padding: 24px;

  @include mixins.breakpoint(md) {
    padding: 36px;
  }
}
