.wrapper {
  position: relative;

  &:hover > .close-icon {
    display: block;
  }
}

.wrapper-border {
  padding: 24px;
  border: 1px solid var(--grey-4);

  & .close-icon {
    top: -16px;
    right: -16px;
  }
}

.rounded {
  border-radius: 16px;
}

.edit-mode {
  cursor: default;

  &:hover .edit-icon {
    display: block;
    margin: auto;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    opacity: 0.8;
    z-index: 2;
  }
}

.close-icon {
  cursor: pointer;
  display: none;
  position: absolute;
  top: -8px;
  right: -8px;
  border: 2px solid var(--white);
  background-color: var(--white);
  border-radius: 16px;
  z-index: 2;

  @media (max-width: 600px) {
    display: block;
  }
}

.edit-icon {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  color: var(--white);
  border-radius: 16px;
  z-index: 2;

  @media (max-width: 600px) {
    display: block;
  }
}

.close-visible {
  display: block;
}
