.input {
  font-weight: 500;
  max-width: 80px;
  height: 100%;
  padding: 0px !important;

  &__wrapper {
    & > * {
      color: inherit;
    }
  }
}

.button {
  & * {
    cursor: initial;
  }

  &--selected {
    & * {
      cursor: pointer;
    }
  }
}
