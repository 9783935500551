.message {
  margin-bottom: 35px;
}

.info {
  max-width: 300px;
  margin: auto;

  a {
    font-size: inherit;
    text-decoration: underline;
  }
}

.no-max-width {
  max-width: none;
}
