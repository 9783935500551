@use "/scss/mixins";

.reactions-feedback-container {
  max-width: 120px;
  max-height: 42px;
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 6px;
  background-color: var(--white);
  border: 1px solid var(--soft-beige);

  &--is-in-filter-box {
    max-height: 48px;
  }

  &.isActive {
    border: 1px solid var(--black);
  }

  @media (hover: hover) {
    @include mixins.breakpoint(md) {
      &:hover {
        border: 1px solid var(--black);
      }
    }
  }
}

.reactions-feedback-container__reaction-button {
  padding: 7px !important;

  & > svg {
    max-width: 18px !important;
    max-height: 18px !important;
  }
}

.reactions-feedback-container__reaction-button--like {
  &.selected {
    background-color: var(--medium-green);
  }
  @media (hover: hover) {
    @include mixins.breakpoint(md) {
      &:hover {
        background-color: var(--medium-green);
      }
    }
  }
}

.reactions-feedback-container__reaction-button--thinking {
  &.selected {
    background-color: var(--medium-orange);
  }
  @media (hover: hover) {
    @include mixins.breakpoint(md) {
      &:hover {
        background-color: var(--medium-orange);
      }
    }
  }
}

.reactions-feedback-container__reaction-button--dislike {
  &.selected {
    background-color: var(--medium-red);
  }
  @media (hover: hover) {
    @include mixins.breakpoint(md) {
      &:hover {
        background-color: var(--medium-red);
      }
    }
  }
}
