@use "/scss/mixins";

.field {
  max-width: 100%;

  @media (min-width: 700px) {
    max-width: 60%;
  }

  @include mixins.breakpoint(md) {
    max-width: 455px;
  }

  & fieldset {
    border-radius: 20px;
  }

  & textarea,
  *::placeholder {
    font-weight: 500;
    padding: 0 !important;
  }

  & :global(.MuiOutlinedInput-root) {
    & fieldset {
      box-shadow: 0 0 0 1px var(--grey-5) !important;
      border: none !important;
    }
  }

  & :global(.MuiOutlinedInput-root:active),
  & :global(.Mui-focused) {
    & fieldset {
      border: none !important;
      box-shadow: 0 0 0 1px var(--black) !important;
    }
  }
}

.form {
  width: 100%;

  &__save-button {
    padding: 0px 16px !important;
  }
}
