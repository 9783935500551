@use "/scss/mixins";

.onramp-first-step {
  width: 100%;
  @include mixins.breakpoint(md) {
    width: 360px;
  }
}

.link {
  color: var(--info-2);
  font-weight: 500;
}
