@use "/scss/mixins";

.container {
  align-items: center;
  background-color: var(--white);
}

.modal {
  display: flex;
  border-radius: 30px;
  border: 1px solid var(--grey-2);
  max-width: 400px;
  margin-bottom: 16px;
  padding: 0 0 0 14px;

  @include mixins.breakpoint(xs) {
    &--smallText {
      padding: 8px 0 8px 16px;
    }
  }

  @include mixins.breakpoint(sm) {
    padding: 0 6px 0 20px;

    &--smallText {
      padding: 8px 0 8px 18px;
    }
  }
}

.card {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  border: 1px solid var(--soft-grey);
  border-radius: 17px;
  padding: 16px;
  gap: 16px;
  margin: auto 0;

  box-shadow: var(--elevation-4);

  &__icon {
    padding: 12px;
    border-radius: 48px;
    line-height: 0;
  }
}

.link-display-input {
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  font: inherit;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}
