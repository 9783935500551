@use "/scss/mixins";

.admin-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid var(--grey-4);
  align-items: flex-start;

  @include mixins.breakpoint(sm) {
    padding: 32px;
  }
}

.fade {
  width: 100%;
  position: absolute;
  content: "";
  top: calc(100% - 100px - 26px);
  height: 100px;
  background: linear-gradient(360deg, #ffffff 20%, rgba(255, 255, 255, 0) 150%);
  z-index: 1;
}

.read-more-wrapper {
  display: flex;
  width: auto;
  gap: 8px;
  z-index: 2 !important;

  &:hover {
    cursor: pointer;
  }
}
