@use "/scss/mixins";

.job-bids-page {
  &__main {
    display: grid;
    gap: 18px;
    min-height: 200px;
  }
}

.job_bids__list-section-header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-end;

  @include mixins.breakpoint(1280px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.job-bids__search-field {
  max-height: 48px;
  margin-bottom: 22px;

  input {
    box-sizing: border-box;
    height: 48px;
  }
}

.ai-interview-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px;
  border-radius: 20px;
  background-color: #faf9f8;
  justify-content: center;
  align-items: center;

  &-icon {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    font-size: 36px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
