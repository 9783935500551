@use "/scss/mixins";
@use "/scss/variables";

.app-footer {
  background: var(--off-white);
  color: var(--grey-1);
  padding: 120px 0 80px;
  z-index: 0;
  display: flex;
  justify-content: center;

  &__wrapper {
    max-width: variables.$wrapperWidth;
    min-width: calc(#{variables.$wrapperMinWidth} - 2rem);
    width: 100%;
    padding: 0 2rem;

    @include mixins.breakpoint(sm) {
      padding: 0 4rem;
    }
  }

  a {
    color: inherit;
  }
}
