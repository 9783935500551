@use "/scss/mixins";

.button-tile-duration {
  @include mixins.transition();
  width: 100%;
  min-width: 100px;
  min-height: 122px;

  &--shadow {
    box-shadow:
      0px 2px 4px rgba(199, 180, 180, 0.04),
      0px 4px 16px rgba(20, 20, 20, 0.04);
  }
}
