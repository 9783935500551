@use "/scss/mixins";

.modal {
  width: min(750px, 100vw - 1rem);
}

button.close-btn {
  top: 32px;
  right: 18px;

  padding: 12px;

  svg {
    margin: 0;
  }

  @include mixins.breakpoint(sm) {
    right: 32px;
  }
}
