.seniority {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }

  &__title {
    margin-bottom: 0 !important;
  }
}
