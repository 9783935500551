.posting-job-as-badge {
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  right: 24px;
  bottom: 24px;
  padding: 16px;

  align-items: center;
  gap: 12px;

  background: var(--soft-blue);

  box-shadow: var(--elevation-6);
  border-radius: 10px;
}
