.wrapper {
  width: 80vw;
  max-width: 500px;
}

.list {
  padding: 0;
  display: grid;
  row-gap: 2rem;
}

.list-item {
  list-style: none;
}

.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
