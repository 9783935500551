.bulk-checkbox {
  width: 48px !important;
  z-index: 0;

  &--with-label {
    width: auto !important;
    margin-right: 16px;
  }

  & :global(.MuiCheckbox-root) {
    &::after {
      content: "";
      position: absolute;
      border: 1px solid var(--soft-grey);
      border-radius: 12px;
      height: 48px;
      aspect-ratio: 1/1;
      background-color: white;
      z-index: -1;
    }

    &:global(.Mui-checked),
    &:global(.MuiCheckbox-indeterminate) {
      background-color: var(--yellow);
    }
  }

  & :global(.MuiFormControlLabel-root) {
    margin: 0;
    padding-right: 12px;
    padding-block: 3px;
  }

  & :global(.MuiFormControlLabel-label) {
    margin-left: 24px;
  }
}
