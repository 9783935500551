@use "/scss/mixins";

.job-type-field {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, 162px);

  &__option {
    padding: 32px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    height: 86px;
  }

  @include mixins.breakpoint(780px) {
    gap: 24px;
  }
}
