.card {
  --grid-area-gap: 8px;
  background-color: var(--white);
  border: 1px solid var(--soft-grey);
  padding: 16px;
  border-radius: 20px;
  margin-bottom: 16px;

  display: grid;
  grid-gap: var(--grid-area-gap) 0px;

  grid-template-areas:
    "employer  job-type-and-role  job-type-and-role  .........   status      status"
    "employer  job_title  job_title  job_title  job_title   job_rate"
    "employer  info       info       info       info            cta";

  grid-template-columns: 176px auto auto 1fr 2fr minmax(150px, auto);
  grid-template-rows: minmax(32px, auto) auto;

  &__employer {
    grid-area: employer;
    margin-bottom: 8px;
    margin-right: 24px;
  }

  &__job-type-and-role {
    grid-area: job-type-and-role;
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-area-gap);
  }

  &__job-type {
    grid-area: job_type;
    margin-right: var(--grid-area-gap);
  }

  &__job-role {
    grid-area: job_role;
  }

  &__status {
    grid-area: status;
    justify-self: end;
  }

  &__job-title {
    grid-area: job_title;
    word-break: break-word;
    margin-right: var(--grid-area-gap);
    &:global(.text-underline) {
      text-decoration: none;
    }
  }

  &__job-rate {
    grid-area: job_rate;
    justify-self: end;
  }

  &__cta {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 8px;
    grid-area: cta;
    max-width: 100%;
    justify-self: end;
    align-self: end;
    width: max-content;
  }

  &__info {
    grid-area: info;
    margin-right: var(--grid-area-gap);
  }

  @container listings-container (inline-size <= 650px) {
    display: grid;
    grid-gap: var(--grid-area-gap) 0px;

    grid-template-areas:
      "employer  employer   employer"
      "job-type-and-role job-type-and-role job-type-and-role"
      "status    status       status"
      "job_title job_title job_title"
      "job_rate  job_rate   job_rate"
      "cta       cta             cta"
      "info      info           info";

    grid-template-columns: min-content min-content auto;
    grid-template-rows: auto;

    &__status {
      justify-self: start;
    }

    &__job-rate {
      justify-self: start;
    }

    &__cta {
      grid-area: cta;
      width: 100%;

      > * {
        flex-basis: 100%;
      }
    }

    &__employer {
      margin: 0;
    }
  }
}
