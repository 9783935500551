@use "/scss/mixins";

.post-wrapper {
  container-name: post-wrapper;
  container-type: inline-size;

  background-color: var(--white);
  border-color: var(--grey-5);
  border-style: solid;
  border-width: 1px 0;
  padding: 16px;
  overflow: hidden;

  &__header {
    display: flex;
    margin-bottom: 16px;
    flex-wrap: wrap;

    &__details-badge {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }
  }

  &__social-proof-header {
    display: flex;
    margin-bottom: 16px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &__avatar {
    display: block;
    padding: 0;
    min-width: 0;
    flex-shrink: 0;
  }

  &__actions {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
  }

  &__content {
    margin-bottom: 16px;
  }

  &__footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;

    @include mixins.breakpoint(1050px) {
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }

  &--with-banner {
    @include mixins.breakpoint(sm) {
      border-radius: 0 0 16px 16px;
    }
  }

  @include mixins.breakpoint(sm) {
    border-radius: 16px;
    border-width: 1px;
  }
}

.offer-banner {
  background-color: var(--off-white);
  border: 1px solid var(--grey-5);
  border-bottom: none;
  padding: 12px 16px;

  @include mixins.breakpoint(sm) {
    border-radius: 16px 16px 0 0;
  }
}
