@use "/scss/mixins";

.my-posts-header {
  display: flex;

  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  padding-bottom: 10px;
  padding-inline: 16px;

  @include mixins.breakpoint(sm) {
    flex-direction: row;
    align-items: center;

    padding-inline: 0;
    padding-bottom: 8px;
  }
}

.counter {
  color: var(--grey-3);
}
