.accept-offer-success-modal {
  max-width: 514px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__middle-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
  }

  &__check-policy-pill {
    background: var(--soft-grey);
    border-radius: 24px;
    display: flex;
    padding: 7px 16px 7px 10px;
    gap: 12px;
    color: var(--dark-grey);
    width: fit-content;
  }

  &__link {
    color: inherit;
    text-decoration: underline;
  }
}

.row-with-emoji {
  display: flex;
  gap: 24px;
  align-items: flex-start;

  &__circle {
    background-color: var(--medium-yellow);
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }

  &__emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
