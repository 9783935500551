.container {
  display: flex;
  flex-direction: column;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--white);
  padding: 20px 32px;
  min-height: 88px;
  border-bottom: 1px solid var(--grey-4);
}

.content-container {
  display: flex;
  flex-grow: 1;
  background-color: var(--soft-violet);
  padding: 24px 32px;
}
