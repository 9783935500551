@use "/scss/mixins";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}

.skill-chip {
  max-width: 200px;

  @include mixins.breakpoint(sm) {
    max-width: 250px;
  }
}

.small-size-top-match {
  @include mixins.job-list-breakpoint(sm) {
    padding: 16px 24px;
    border-radius: 25px 25px 5px 25px;
    background-color: var(--soft-green);
    max-width: 100%;
  }

  @include mixins.job-list-breakpoint(md) {
    padding: 0;
    border-radius: 0;
    background-color: inherit;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}
